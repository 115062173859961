export function ItemDetailReducer(
  state: any = {},
  action: any
) {
  //console.log(action);
  switch (action.type) {
    case "ITEM_DETAILS_NFT":
      return { ...action.payload.data };
      case "PROCESS_ORDER":
        return { ...action.payload.data };   
      case "REQUEST_MESSAGE":
        return { ...action.payload.data };   
    default:
      return state;
  }
};

