export function InboxReducer(
  state: any = {},
  action: any
) {
  //console.log(action);
  switch (action.type) {
    case "INBOX_LIST":
      return action.payload ;
    default:
      return state;
  }
};

