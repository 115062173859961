import Utils from "../../utils";


/*************************************** Get NFTs Data*********************************************/
export function GetNFTs(
  callback: Function = function () { }
) {
  return (dispatch: Function, getState: Function) => {
    let hasConnection = Utils.constant.hasConnection();
    if (!hasConnection) {
      return false;
    }

    console.log('GetNFTs')
    Utils.constant.handleOverlayLoader(true);
    let endPoint = Utils.endpoints.GetPopNFTs;

    let payload = {}

    Utils.api.getApiCall(
      endPoint,
      '',
      '',
      function (response: any) {
        if (response.status) {
          let data: any = response.data.data;
          dispatch({
            type: Utils.actionName.Get_PopNFTs,
            payload: data,
          });
          callback();
        }
      },
      function (error: any) {
        if (error.status === 501) {
          Utils.constant.apiErrorMessage(error.data.message);
        }
      }
    );
  };
}

