import Utils from "../../utils";
import { SelectCategoryModel, NFTModal } from "../../modal";


export function GetReviews(
  payload: any,
  callback: Function = function () { }
) {
  return (dispatch: Function, getState: Function) => {
    let hasConnection = Utils.constant.hasConnection();
    if (!hasConnection) {
      return false;
    }

    Utils.constant.handleOverlayLoader(true);

    let endPoint = Utils.endpoints.GetReviews + "/" + payload.user_id;

    Utils.api.getApiCall(
      endPoint,
      "",
      "",
      function (response: any) {
        if (response.status) {
          let data: any = response.data.data;
          dispatch({
            type: Utils.actionName.GET_REVIEWS,
            payload: { data },
          });

          callback();
        }
      },
      function (error: any) {
        if (error.status === 501) {
          Utils.constant.apiErrorMessage(error.data.message);
        }
      }
    );
  };
}

export function GetArtist(
  payload: any,
  authToken: string,
  callback: Function = function () { }
) {
  return (dispatch: Function, getState: Function) => {
    let hasConnection = Utils.constant.hasConnection();
    if (!hasConnection) {
      return false;
    }

    Utils.constant.handleOverlayLoader(true);

    let endPoint = Utils.endpoints.GetArtist+"/"+payload.user_id;
    
    console.log(endPoint);

    Utils.api.getApiCall(
      endPoint,
      "",
      "",
      function (response: any) {
        if (response.status) {
          let data: any = response.data.data;

          dispatch({
            type: Utils.actionName.USER_PROFILE,
            payload: { data },
          });

          callback();
        }
      },
      function (error: any) {
        if (error.status === 501) {
          Utils.constant.apiErrorMessage(error.data.message);
        }
      }
    );
  };
}

export function GetNFTsByUser(
  payload: any,
  authToken: string,
  callback: Function = function () { }
) {
  return (dispatch: Function, getState: Function) => {
    let hasConnection = Utils.constant.hasConnection();
    if (!hasConnection) {
      return false;
    }
    Utils.constant.handleOverlayLoader(true);
    let endPoint = Utils.endpoints.get_user_products +'/'+ payload.user_id;

    Utils.api.getApiCall(
      endPoint,
      "",
      "",
      function (response: any) {
        if (response.status) {
          let data: any = response.data.data;
      
          dispatch({
            type: Utils.actionName.GET_NFTs_BY_USER,
            payload: data,
          });
          callback();
        }
      },
      function (error: any) {
        if (error.status === 501) {
          Utils.constant.apiErrorMessage(error.data.message);
        }
      }
    );
  };
}