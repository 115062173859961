import React from "react";
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'

export function ContestBuyer ({ HandleContest }) {
  const options = [
    { value: '', label: "Please choose the contest reason" },
    { value: "Didn't receive the item", label: "Didn't receive the item" },
    { value: 'Item is damaged', label: "Item is damaged" },
    { value: 'Item is not as described', label: "Item is not as described" },
    { value: 'other', label: "other" }
  ]
  const formSchema = Yup.object().shape({
    contestreason: Yup.string().required('Please Select reason you are contesting.'),
    otherreason: Yup.string().when('contestreason', {
      is: 'other',
      then: Yup.string().required('Please enter the reason')
        .min(5, 'Reason should be at least 10 characters')
        .max(150, 'Reason should be at most 100 characters')
    })
  })
  const formOptions = { resolver: yupResolver(formSchema) }
  const { register, handleSubmit, formState, watch } = useForm(formOptions
  )
  const { errors } = formState
 

  let watchOther = watch("contestreason");
  async function onSubmit(data) {

    HandleContest(data)
  
    //HandleContest(data)
    // get product data from contract
  }
  return (
    <div>
      <div className="vstack gap-3 text-center">
        <div>
          <div className="alert alert-warning" role="alert">
            Seller did not ship the item yet or you received damaged item ?. You can contest in order to cancel the order and get your money back. <br/>
            The customer service will check the order details and contact you back.
          </div>
        </div>
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div>
          <label className="form-label">Please choose the contest reason</label>
          <select
            name="contestreason"
            type="name"
            options={options}
            //onChange={(e) => setValue('select', e.target.value, { shouldValidate: true })} // Using set
            className={`form-control ${errors.contestreason ? 'is-invalid' : ''}`}
            {...register('contestreason')}
          >
            {options.map((option) =>
              <option value={option.value}>{option.label}</option>
            )}
          </select>
          <div className="invalid-feedback">{errors.contestreason?.message}</div>
        </div>
        {watchOther === "other" && (
          <><div className="mt-3"></div><div>
            <label className="form-label">Please describe the reason</label>
            <textarea
              name="otherreason"
              type="name"
              multiple={true}
              {...register('otherreason')}
              className={`form-control ${errors.otherreason ? 'is-invalid' : ''}`} />
            <div className="invalid-feedback">{errors.otherreason?.message}</div>
          </div></>
        )
        }
        <div className="mt-3">
          <button type="submit" className="btn btn-dark">
            Submit Consent
          </button>
        </div>
      </form>
    </div>
  )
}
export default ContestBuyer;