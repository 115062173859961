import React, { PureComponent } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { GetArtist, GetNFTsByUser, GetReviews } from "..";
import AppLayout from "../../../applayout/applayout";
import NftCard from "../../../component/NftCard";
import { NFTModal, UserDataModal } from "../../../modal";
interface Props {
  t?: any;
  history?: any;
  GetArtist: Function;
  GetNFTsByUser?: any;
  getUserProfileData: Function;
  userProfileData: UserDataModal;
  getReviews: Function;
  reviewslist: any;
  nftsList: any;
}
interface State {
  userId: string;
  username: string;
  fullName: string;
  email: string;
  country: string;
  userProfileImage: string;
  reviewlists: any;
  accountAddress: string;
  imageClass: string;
  isLoading: boolean;
  popupMessage: string;
  popupStatus: string;
  popupTitle: string;
  createNFTResult: any;
  phone: string;
  newImageSrc: string;
  nftsList: any;
  bio: string;
  extraDisplay: string;
  public_visibility: boolean;
  opacity?: string;
  site: string;
  CollectedNFTlist: any;
  artist: string;
}
export class SellerScreen extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      userId: "",
      username: "",
      imageClass: "",
      reviewlists: [],
      fullName: "",
      country: "",
      email: "",
      newImageSrc: "",
      public_visibility: true,
      isLoading: false,
      popupMessage: "",
      popupStatus: "loading",
      popupTitle: "Status",
      extraDisplay: "",
      createNFTResult: {},
      opacity: "0",
      phone: "",
      userProfileImage: "",
      accountAddress: "",
      nftsList: null,
      bio: "",
      site: "",
      CollectedNFTlist: null,
      //@ts-ignore
      artist: String(props.match.params.id),
    };

  }
  RenderNFTs(nft: NFTModal) {
    return <NftCard nft={nft} key={nft._id} />;
  }

  async getNftForUser(): Promise<void> {
    let user_id = this.state.artist;

    let payload = {
      user_id,
    };

    this.props.GetNFTsByUser(payload, "", () => {
      this.setState({ nftsList: this.props.nftsList });
    });
  }
  async GetArtist(): Promise<void> {
    let user_id = this.state.artist;
    let payload = {
      user_id,
    };

    this.props.GetArtist(payload, "", () => {
      let userData = this.props.userProfileData;
      this.setState({
        username: userData.username,
        email: userData.email,
        bio: userData.bio,
        site: userData.site,
        phone: userData.phone,
        country: userData.country,
        newImageSrc: userData.user_image,
      });


    });
  }

  async GetReviews(): Promise<void> {

    let user_id = this.state.artist;
    
    let payload = {
      user_id,
    };

     this.props.getReviews(payload, () => {
      

      this.setState({
        reviewlists: this.props.reviewslist.data,
      });
  })
}

renderReviews(data: any) {
  let rating = data.review.rating;

;
   let content :  string[]= [];
    for (let i = 0; i < 5; i++) {
      //@ts-ignore
      content.push(<span className={rating >= 1 ? "selected" : ""}>★</span>);
    }
    return content;



}

  async componentDidMount() {
    
    await this.GetArtist();
    await this.getNftForUser();
    await this.GetReviews();
  }
  ShowErrorMessage(
    isShowLoading: boolean,
    message: string,
    status: string,
    title: string
  ) {
    try {
      this.setState({
        isLoading: isShowLoading,
        popupTitle: title,
        popupStatus: status,
        popupMessage: message,
      });
    } catch (ex) {
      console.log(ex);
    }
  }
  render() {
    return (
      <>
        <div className="container-fluid">
          <div className="row">
            <div className="mt-4"></div>
            <div className="col-0 col-lg-1"></div>
            <div className="col-12 col-lg-6">
              <div className="container">
                <div className="row">
                  <div className="col-4 col-sm-3">
                    <div
                      className="bg-secondary rounded-circle"
                      style={{ height: "100px", width: "100px" }}
                    >
                      <img
                        src={
                          this.state.newImageSrc && this.state.newImageSrc != ""
                            ? this.state.newImageSrc
                            : require("../../../assets/images/author_single/profile-placeholder.png")
                        }
                        className="rounded-circle"
                        style={{ height: "100px", width: "100px" }}
                      />
                    </div>
                  </div>
                  <div className="col-8 col-sm-9">
                    <div className="mt-1"></div>
                    <h1>{this.state.username}</h1>
                    <p>{this.state.country}</p>
                  </div>
                  <div className="mt-2"></div>
                  <div className="col-sm-12">
                    <p className="text-secondary">{this.state.bio}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container-fluid">
            <div className="row">
              <div className="col-0 col-lg-1"></div>
              <div className="col-12 col-lg-8">
                <div className="mt-2"></div>
                <ul className="nav nav-tabs" id="myTab" role="tablist">
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link active"
                      id="home-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#home-tab-pane"
                      type="button"
                      role="tab"
                      aria-controls="home-tab-pane"
                      aria-selected="true"
                    >
                      Selling
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link"
                      id="profile-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#profile-tab-pane"
                      type="button"
                      role="tab"
                      aria-controls="profile-tab-pane"
                      aria-selected="false"
                    >
                      Reviews
                    </button>
                  </li>
                </ul>
                <div className="tab-content" id="myTabContent">
                  {/* <!--Selling section begins here--> */}
                  <div
                    className="tab-pane fade show active"
                    id="home-tab-pane"
                    role="tabpanel"
                    aria-labelledby="home-tab"
                    tabIndex={0}
                  >
                    <div className="mt-1"></div>
                    {/* <!--Selling items begins here--> */}
                    <div className="row">
                      {this.state.nftsList &&
                        this.state.nftsList.length > 0 &&
                        this.state.nftsList.map((nft: NFTModal) => {
                          return this.RenderNFTs(nft);
                        })}
                    </div>
                    {/* <!--Selling items ends here--> */}
                  </div>
                  {/* <!--Selling section ends here-->
                        <!--Reviews section beings here--> */}
                  <div
                    className="tab-pane fade"
                    id="profile-tab-pane"
                    role="tabpanel"
                    aria-labelledby="profile-tab"
                    tabIndex={0}
                  >
                    <div className="col-12">
                      {this.state.reviewlists?.length > 0 ? (
                        <ul className="list-group list-group-flush">
                          {this.state.reviewlists.map((review: any) => {
                            return (
                              <li className="list-group-item">
                                <div className="mt-1"></div>
                                <div className="col-12">
                                  @
                                  {review.userData.length > 0
                                    ? review.userData[0].username
                                    : review.userData[0].eth_wallet_address}
                                  <br />
                                  {this.renderReviews(review)}
                                </div>
                                <div className="mt-1"></div>
                                <div className="row">
                                  <div className="col-12 text-secondary">
                                    {review.review.comment + " "}
                                  </div>
                                </div>
                                <div className="mt-1"></div>
                              </li>
                            );
                          })}
                        </ul>
                      ) : (
                        <div className="mt-3">No Reviews yet</div>
                      )}
                    </div>
                  </div>
                  {/* <!--Review section ends here--> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
function mapStateToProps(state: any) {
  return {
    nftsList: state.UserReducer || {},
    userProfileData: state.userProfileReducer || [],
    reviewslist: state.UserReducer || {},
  };
}
function mapDispatchToProps(dispatch: Function) {
  return {
    GetNFTsByUser: (payload: any, token: any, callback: Function) =>
      dispatch(GetNFTsByUser(payload, token, callback)),
    GetArtist: (payload: any, token: any, callback: Function) =>
      dispatch(GetArtist(payload, token, callback)),
    getReviews: (payload: any, callback: Function) =>
      dispatch(GetReviews(payload, callback)),
  };
}
export const SellerModule = withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(SellerScreen)
);
export const Seller = AppLayout(SellerModule);
