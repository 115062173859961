import React, { Component } from "react";
import Modal from "react-bootstrap/Modal";
import { Translation } from "react-i18next";
interface Props {
  onHide?: any;
  handlelogin: Function;
  modalShow?: boolean;
  walletinstalled?: boolean;
}
interface State { }
export class Walletmodal extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <React.Fragment>
        <Translation>
          {(t: Function) => (
            <Modal
              show={this.props.modalShow}
              animation={false}
              onHide={this.props.onHide}
              size="sm"
              dialogClassName="wallet-modal"
              aria-labelledby="contained-modal-title-vcenter"
              centered
            >
              <Modal.Header
                closeButton={false}
                className="border-bottom-0 padding-bottom-0"
              >
                <Modal.Title className="modal_header">
                  Wallet Connection
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <figure className="postjob_modal_icon">
                  {/* <img
                    src={require("../../assets/images/ic_postnew_job.svg")}
                    alt=""
                    className="src"
                  /> */}
                </figure>
                <button className="box-url-wallet col-lg-12" onClick={ e=> {(!this.props.walletinstalled) ? window.location.href="https://metamask.io/download/": this.props.handlelogin() }}>
                  <div className="row">
                    <div className="col-lg-12">
                      <img src="../images/wallet/1.png" alt="" className="metamask" height={'45px'} />
                      <p> {(!this.props.walletinstalled) && "Install"} Metamask</p>
                    </div>
                  </div></button>
              </Modal.Body>
            </Modal>
          )}
        </Translation>
      </React.Fragment>
    );
  }
}
