import React, { useState } from "react";
import DOMPurify from "dompurify";


const ReviewForm = ({ SubmitReview }) => {
  const [rating, setRating] = useState(0);
  const [comment, setComment] = useState("");
  const [reviewerror, setreviewerror] = useState("");
  const [reviewtexterror, setreviewtexterror] = useState("");

  const handleSubmit = (event) => {
    event.preventDefault();

    if (rating == 0) {
      setreviewerror("Please select a rating");
    } else setreviewerror("");
    if (comment == "") {
      setreviewtexterror("Please select a rating");
    } else setreviewtexterror("");
    // Send rating and comment data to server or store in database
    console.log({ rating, comment });

    if (rating == 0 || comment == "") return;

    const sanitizedComment = DOMPurify.sanitize(comment);
    const trimmedComment = sanitizedComment.trim();

    let payload = {
      rating: rating,
      comment: trimmedComment,
    };
    // Send rating and comment data to server or store in database
    SubmitReview(payload);
  };

  return (
    <>
      <div className="review">
        <span
          className={rating >= 1 ? "selected" : ""}
          onClick={() => setRating(1)}
        >
          ★
        </span>
        <span
          className={rating >= 2 ? "selected" : ""}
          onClick={() => setRating(2)}
        >
          ★
        </span>
        <span
          className={rating >= 3 ? "selected" : ""}
          onClick={() => setRating(3)}
        >
          ★
        </span>
        <span
          className={rating >= 4 ? "selected" : ""}
          onClick={() => setRating(4)}
        >
          ★
        </span>
        <span
          className={rating >= 5 ? "selected" : ""}
          onClick={() => setRating(5)}
        >
          ★
        </span>
        <div className="invalid-feedback" style={{ display: "block" }}>
          {reviewerror}
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <div className="mb-3">
            <label for="formGroupExampleInput" className="form-label">
              Write a review
            </label>
            <div className="form-floating">
              <form onSubmit={handleSubmit}>
                <textarea
                  className="form-control"
                  id="floatingTextarea"
                  value={comment}
                  maxLength={255}
                  onChange={(event) => setComment(event.target.value)}
                ></textarea>
                <p>{255 - comment.length}/255 characters remaining</p>
                <div className="invalid-feedback" style={{ display: "block" }}>
                  {reviewtexterror}
                </div>
                <div className="mt-3">
                  <button type="submit" class="btn btn-dark w-100">
                    Validate
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ReviewForm;
