import ContestBuyer from "../../../component/ContestBuyer.js";
import React, { Component } from "react";
import Clock from "./clock.js";
import ReviewForm from "../../../component/reviewform.js";
const moment = require('moment');


// get date difference in days
class BuyerLayout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      shipped: false,
      expired: false,
    };
  }
  componentDidMount() {
    if (this.props.product.status == "SHIPPED") {
      this.setState({ shipped: true });
    }
    this.daysleft();
  }
  daysleft = () => {
    // return days left between 2 dates
    var days_to_add = 5;
    var deadline = this.props.product.created_at;

    if (this.props.product.status != null) {
      switch (this.props.product.status) {
        case "SHIPPED":
          days_to_add = 5;
          deadline = this.props.product.updated_at;
          break;
        case "PENDING":
          days_to_add = 5;
          deadline = this.props.product.updated_at;
          break;
        default:
          days_to_add = 14;
          deadline = this.props.product.created_at;
          break;
      }
    }

    var createdDate = new Date(this.props.product.created_at);
    var expirationDate = moment(createdDate).add(days_to_add, "d");
    // return days left between expiration date and today
    var today = new Date();
    var diff = expirationDate - today;
    // console.log('days_to_add: ' + days_to_add)
    // console.log('deadline: ' + deadline)
    // console.log("time left: " + diff);
    if (diff < 0) {
      this.setState((prevState, props) => ({
        expired: !prevState.expired,
      }));
    }
  };

  addProtocol = (link) => {
    if (!link) return '#';

    if (!link.startsWith("http://") && !link.startsWith("https://")) {
        return "https://" + link;
      }
    return link;
  };

  SubmitReview = async function (data) {
    console.log(JSON.stringify(data, null, 4))

  let saveData = {
    product_id: this.props.product.productData[0].product_id,
    product: this.props.product.productData[0]._id,
    buyer: this.props.product.buyer,
    seller: this.props.product.seller,
    rating: data.rating,
    comment: data.comment,
    query_type: "REVIEW",
  };

   this.props.UpdateOrderShippingStatus(saveData);

    //let TrackDate = await web3Utills.setTracking(this.props.product.productData[0].product_id);
  };

  ContestOrder = async function (data) {
    console.log(JSON.stringify(data, null, 4));

    let saveData = {
      product_id: this.props.product.productData[0].product_id,
      product: this.props.product.productData[0]._id,
      buyer: this.props.product.buyer,
      seller: this.props.product.seller,
      reason: data.reason,
      query_type: "CONTESTING",
    };

    if (data.contestreason == "other") {
      saveData.reason = data.otherreason;
    } else {
      saveData.reason = data.contestreason;
    }

    this.props.UpdateOrderShippingStatus(saveData);
  };

  MarkOrderProcessed(event) {
    //console.log(JSON.stringify(data, null, 4))

    event.currentTarget.disabled = true;
    event.currentTarget.innerHTML = "Processing...";

    let saveData = {
      product_id: this.props.product.productData[0].product_id,
      product: this.props.product.productData[0]._id,
      buyer: this.props.product.buyer,
      seller: this.props.product.seller,
      query_type: "PROCESSING",
    };

    this.props.UpdateOrderShippingStatus(saveData);

    //let TrackDate = await web3Utills.setTracking(this.props.product.productData[0].product_id);
  }

 RenderReview (rating) {
    let content = [];
    for (let i = 0; i < 5; i++) {
      content.push(<span className={rating >= 1 ? "selected" : ""}>★</span>);
    }
    return content;
  };

  render() {
    return (
      <>
        <span>
          Order created on:{" "}
          {new Date(this.props.product.created_at).toDateString()},{" "}
          {new Date(this.props.product.created_at).toLocaleTimeString()}
        </span>
        <span>Order NUMBER: {this.props.product.order_number}</span>
        <div>
         
        </div>
        <div className="d-flex justify-content-center">
          <a
            href={
              "/item-details/" + this.props.product.productData[0].product_id
            }
          >
            <img
              src={this.props.product.productData[0].thumbnailImageBuffer[0]}
              className="rounded"
              style={{ height: "120px", width: "100px" }}
            />
          </a>
        </div>
        {this.props.product.status == "SHIPPED" && (
          <>
            <p>
              <strong>
                <a href=""> @{this.props.product.sellerData[0].username}</a>{" "}
                shipped your order
              </strong>
              <br />
              You have 15 days to mark the transaction as received. <br/>
              1. If you received the order within 15 days, and don't mark it completed, by default, the order will be marked complete and Money in escrow will released to buyer.
              <br/>
              2. If you did't receive, please contest contest in 15 days and customer service will help you.

              <br />{" "}
              <Clock deadline={this.props.product.updated_at} expires={15} />
            </p>
            <ul className="list-group">
              <li className="list-group-item">
                Tracking number: {this.props.product.tracking}
              </li>
              <li className="list-group-item">
                For more detailed information, visit{" "}
                <a
                  className="link-primary"
                  href={this.addProtocol(this.props.product.tracking_url)}
                  target={"_blank"}
                >
                  {" "}
                  shipping company's tracking page
                </a>
                {}
              </li>
              <div className="mt-2"></div>

              <div className="col-12">
                <button
                  type="button"
                  onClick={(e) => {
                    e.preventDefault();
                    this.MarkOrderProcessed(e);
                  }}
                  className="btn btn-dark col-12"
                >
                  Mark order as completed
                </button>
              </div>
              <div className="mt-2"></div>
              {!this.state.false && (
              <>
                {" "}
                <ContestBuyer HandleContest={this.ContestOrder.bind(this)} />
              </>
            )}
            </ul>
          </>
        )}
        {this.props.product.status == "CONTESTED" && (
          <>
            <p>
              <strong>
                <a href={"/user/" + this.props.product.sellerData[0]._id}>
                  {" "}
                  @{this.props.product.sellerData[0].username}
                </a>{" "}
                shipped your order
              </strong>
              <br />
              You have contested the order with following reason.<br/>
              {this.props.product.reason}
              <br />{" "}
              <div className="alert alert-warning mt-2" role="alert">
           The customer service will check the order details and contact you back.
          </div>
            </p>
          </>
        )}
        {this.props.product.status == "PROCESSED" && (
          <>
            <p>
              <strong>
                <a href={"/user/" + this.props.product.sellerData[0]._id}>
                  {" "}
                  @{this.props.product.sellerData[0].username}
                </a>{" "}
                shipped your order
              </strong>
              <br />
              Order is completed
            </p>
            {!this.props.product.review ? (
              <ReviewForm SubmitReview={this.SubmitReview.bind(this)} />
            ) : (
              <div className="review-completed">
                <p>you left review</p>
                {this.RenderReview(this.props.product.review.rating)}
                <p className="text-muted">
                  " {this.props.product.review.comment} "
                </p>
              </div>
            )}
          </>
        )}
        {(this.props.product.status == "" ||
          this.props.product.status == null ||
          this.props.product.status == "PENDING") && (
          <>
            <p>
              <strong>
                Waiting for
                <a href={"/user/" + this.props.product.sellerData[0]._id}>
                  {" "}
                  @{this.props.product.sellerData[0].username}
                </a>{" "}
                to ship your order...
              </strong>
              <br />
              The seller has 5 days to ship the item
              <br />{" "}
              <Clock deadline={this.props.product.created_at} expires={5} />
            </p>
            {this.state.expired && (
              <>
                {" "}
                <ContestBuyer HandleContest={this.ContestOrder.bind(this)} />
              </>
            )}
            
          </>
        )}
      </>
    );
  }
};
export default BuyerLayout;