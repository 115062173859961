export default {
  USER_LOGOUT: "user_logout",
  HANDLE_USER_DATA: "handle_user_data",
  OTP_MODAL: "otp_modal",
  HANDEL_JOB_LIST: "handle_JOB_LIST",
  JOB_DETAILS: "job_details",
  CANDIDATE_STATUS: "candidate_status",
  CANDIADATE_PROFILE: "candidate_profile",
  RECRUITER_PROFILE: "recruiter_profile",
  UPDATE_PROFILE_RECRUITER: "updateProfileRecruiter",
  OTP_SENT: "otpsent",
  OTP_VERIFICATION: "otpVerification",
  DASHBOARD_RECRUITER_PROFILE: "dashboardRecruiterProfile",
  UPDATE_STAR_COUNT: "updatestarcount",
  CANDIADATE_LIST: "candidate_list",
  CLEAR_CANDIADATE_LIST: "clear_candidate_list",
  SHORT_lISTING: "short_listing",
  REJECT_CANDIDATE: "reject_candidate",
  SELECT_CATEGORY: "Select_category",
  SELECT_COLLECTION: "Select_collection",
  UPDATE_COLLECTION: "Update_collection",
  GET_NFTs: "Get_NFTs",
  Get_PopNFTs: "get-Popular-NFTs",
  Get_PopMultiNFTs: "get-Popular-Multi-NFTs",
  GET_POP_COLLECTIONS: "get-popular-collections",
  GET_NFTs_BY_USER: "Get_NFTs_By_User",
  GET_Transaction_BY_USER: "Get_Transactions_By_User",
  GET_LIKED_NFTs_BY_USER: "Get_Liked_NFTs_By_User",
  GET_COLLECTION_BY_ID: "Get_Collection_By_Id",
  GET_AUTHOR_COLLECTION_BY_ID: "Get_Author_Collection_By_Id",
  COLLECTION_CHECK: "Collection_Check",
  GET_NFTs_BY_CATEGORY: "Get_NFTs_By_Category",
  USER_PROFILE: "user_profile",
  CREATE_NFT: "Create_Item",
  UPDATE_ITEM: "UPDATE-ITEM",
  DELETE_ITEM: "DELETE-ITEM",
  GET_NFT: "Get_NFT",
  GET_NFT_HISTORY: "Get_NFT_History",
  GET_NFT_BID_HISTORY: "Get_NFT_Bid_History",
  PROCESS_ORDER: "PROCESS_ORDER",
  UPDATE_ORDER: "UPDATE_ORDER",
  UPDATE_NFT: "Update_NFT",
  ADD_JOB: "add_job",
  REMOVE_EXISTING_JOB_DATA: "remove_existing_job_data",
  UPDATE_JOB: "update_job",
  CHANGE_PASSWORD: "change_password",
  REVIEW: "review",
  REFERRAL: "referral",
  DELETE_ACCOUNT: "delete_account",
  STAR_PACKAGE: "star_package",
  PURCHASED_STAR_PACKAGE: "star_packages_purchased",
  START_SPENT: "check_star_spent",
  BRAIN_TREE_INITIALIZE: "brain_tree_initialize",
  BRAIN_TREE_CONFIRM: "brain_tree_confirm",
  LANGUAGE_LIST: "language_list",
  SKILLS_LIST: "skills_list",
  DELETE_JOB: "delete_job",
  REOPEN_JOB: "reopen_job",
  CARDS_LIST: "get_cards_list",
  BRAIN_TREE_ADD_CARD: "add_card",
  CONTACT_US: "contact_us",
  SEND_ROOM_NOTIFICATION: "send_room_notification",
  SAVE_TEMPLATE_MESSAGE: "save_template_message",
  UNSUBSCRIBE_EMAIL: "unsubscribe_email",
  GET_USER_STORIES: "get_user_stories",
  SEND_USER_STORY: "send_user_story",
  REMOVE_USER_STORY: "remove_user_story",
  SEND_QUERY_MAIL: "send_query_mail",
  MAKE_PAYMENT: "make_payment",
  GET_PAYMENT_DETAILS: "GET_PAYMENT_DETAILS",
  CANCIL_SUBSCRIPTION: "CANCIL_SUBSCRIPTION",
  GET_SUBSCRIPTION_DETAILS: "GET_SUBSCRIPTION_DETAILS",
  TOUR_ENABLE_UPDATE: "TOUR_ENABLE_UPDATE",
  GET_RECRUITER_STATUS: "get_recruiter_status",
  GET_NFTs_BY_ARTIST: "Get_Products_By_Artist",
  GET_ARTIST: "Get_Artist",
  GET_REVIEWS: "get_reviews",
};
