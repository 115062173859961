import { combineReducers } from "redux";
import { authReducer } from "../modules/auth/auth.reducer";
import { homeReducer } from "../modules/home/home.reducer";
import { authorReducer } from "../modules/author/author.reducer";
import { TransactionReducer } from "../modules/transaction/transaction.reducer";
import { SelectCategoryReducer, CreateNFTReducer, SelectCollectionReducer } from "../modules/create-single/create-single.reducer";
import { userProfileReducer } from "../modules/profile/profile.reducer";
import { ItemDetailReducer } from "../modules/item-detail/item-detail.reducer";
import { ItemEditReducer } from "../modules/item-edit/item-edit.reducer";
import { InboxReducer } from "../modules/inbox/inbox.reducer";
import { UserReducer } from "../modules/seller/seller.reducer";


/**
 * combine all reducer into single root reducer
 */
const rootReducer = combineReducers({
  authReducer,
  homeReducer,
  authorReducer,
  UserReducer,
  SelectCategoryReducer,
  SelectCollectionReducer,
  CreateNFTReducer,
  userProfileReducer,
  ItemDetailReducer,
  TransactionReducer,
  ItemEditReducer,
  InboxReducer
});

const appReducer = (state: any, action: any) => {
  return rootReducer(state, action);
};

export default appReducer;
