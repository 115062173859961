import React, { PureComponent } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import AppLayout from "../../../applayout/applayout";
import web3Utills from '../../../utils/web3Utills';
import utils from '../../../utils/';
import { Walletmodal } from "../../../component/metamask/walletmodel";

interface Props {
    t?: any;
    history?: any;
    returnUrl?: any;
}
interface State {
    InstallWalletPopup: boolean;
    returnUrl: any;
}
export class WalletScreen extends PureComponent<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            InstallWalletPopup: false,
            //@ts-ignore
            returnUrl: String(this.props.location.search).split("=")[1],
        };
    }
    async componentDidMount() {

        let isEtheriumConnected = await web3Utills.isEtheriumConnected();
        let isAuthenticated = utils.constant.getAccessToken()
        console.error("isEtheriumConnected " + isEtheriumConnected)
        console.error("isAuthenticated " + isAuthenticated)

        if (isAuthenticated != false &&  isEtheriumConnected  != false) {
            this.state.returnUrl == undefined ? window.location.href = "/home" : window.location.href = this.state.returnUrl;
        }
    }

    handleclick = async () => {
        //check if metamask is installed and then sign in with metamask signature
        let metamask = web3Utills.isMetaMaskInstalled()
        let isEtheriumConnected = await web3Utills.isEtheriumConnected();
        let isAuthenticated = utils.constant.getAccessToken()
        

        if (metamask == false) {
            sessionStorage.clear();
            this.setState({ InstallWalletPopup: true }); return;
        }
        
        
        console.log("isEtheriumConnected " + isEtheriumConnected)
        console.log("isAuthenticated " + isAuthenticated)
        if (isAuthenticated == false ||  isEtheriumConnected  == false) {
            await web3Utills.SignwithMetamask();

        }
        else {

            this.state.returnUrl == undefined ? window.location.href = "/home" : window.location.href = this.state.returnUrl;
        }
    }
    //await web3Utills.SignwithMetamask()

    render() {
        return (
            <>
                <Walletmodal
                    handlelogin={() => { }}
                    walletinstalled={false}
                    modalShow={this.state.InstallWalletPopup}
                    onHide={() => {
                        this.setState({ InstallWalletPopup: false });
                    }}
                />
                <div className="mt-5"></div><div className="container-fluid">
                    <div className="row">
                        <div className="d-flex justify-content-sm-center">
                            <h1>Install Metamask</h1>
                        </div>
                        <div className="d-flex justify-content-sm-center">
                            <p>If you don't have a wallet yet, you can create one now.</p>
                        </div>
                        <div style={{ height: "10px" }}></div>
                        <div className="d-flex justify-content-sm-center">
                            <div className="list-group col-12 col-sm-5">
                                <button onClick={() => { this.handleclick() }} className="list-group-item list-group-item-action" aria-current="true">
                                    <img src="images/metamask-logo.svg" width="24" height="24" />
                                    Metamask
                                </button>
                                <a href="#" className="list-group-item list-group-item-action" aria-current="true">
                                    Coming soon...
                                </a>
                                <a href="#" className="list-group-item list-group-item-action" aria-current="true">
                                    Coming soon...
                                </a>
                            </div>
                        </div>
                        <div className="container text-sm-center">
                        </div>
                    </div>
                </div></>
        );
    }
}
function mapStateToProps(state: any) { return {}; }
function mapDispatchToProps(dispatch: Function) { return {} }
export const WalletModule = withTranslation()(
    connect(mapStateToProps, mapDispatchToProps)(WalletScreen)
);
export const Wallet = AppLayout(WalletModule);
