import React, { Component } from "react";
const moment = require ('moment');

class Clock extends Component {
  constructor(props) {
    super(props);
    this.state = {
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0,
      ended: false
    };
  }
  
  componentDidMount() {
    
    const time = Date.parse(new Date(this.props.deadline * 1000)) - Date.parse(new Date());

    var createdDate = new Date(this.props.deadline);
    var expirationDate = moment(createdDate).add(this.props.expires, 'd');
    
    this.getTimeUntil(expirationDate.unix());


    if (time > 0) { setInterval(() => this.getTimeUntil(expirationDate.unix()), 1000);  }
    else this.setState({  ended:true });
    
  }

  leading0(num) {
    return num < 10 ? "0" + num : num;
  }
  getTimeUntil(deadline) {
    const time = Date.parse(new Date(deadline * 1000)) - Date.parse(new Date());
  
    if (time < 0) {
        
      this.setState({ days: 0, hours: 0, minutes: 0,  seconds: 0, ended:true });
      
    } else {
      const seconds = Math.floor((time / 1000) % 60);
      const minutes = Math.floor((time / 1000 / 60) % 60);
      const hours = Math.floor((time / (1000 * 60 * 60)) % 24);
      const days = Math.floor(time / (1000 * 60 * 60 * 24));
      this.setState({ days, hours, minutes, seconds });
    }
  }
  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = (state,callback)=>{
        return;
    };
  }
  render() {
    
    return (
      
        
        (this.state.ended) ? (<span className="text-danger"> Deadline expired.  </span>) 
        : (<span>  ({this.leading0(this.state.days)+'d ' +this.leading0(this.state.hours)+'h '+this.leading0(this.state.minutes)+'m '+this.leading0(this.state.seconds)+'s'}) </span>)
     
    );
  }
}
export default Clock;
