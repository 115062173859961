import React, { Component } from "react";
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'


export function TrackingForm  ( {SetTrackInfo})  {



    const URL = /^((https?|ftp):\/\/)?(www.)?(((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:)*@)?(((\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5]))|((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?)(:\d*)?)(\/((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)+(\/(([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)*)*)?)?(\?((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|[\uE000-\uF8FF]|\/|\?)*)?(\#((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|\/|\?)*)?$/i

    
  const formSchema = Yup.object().shape({
    tracking: Yup.string()
      .required('Tracking code is mandatory')
      .min(1, 'Code must be at 1 char long'),
    confrirmTrack: Yup.string()
      .required('Tracking code confirmation is mandatory')
      .oneOf([Yup.ref('tracking')], 'Tracking code do not match'),
      
    trackingUrl: Yup.string()
      .required('Url is mandatory')
      .min(1, 'Url must be at 3 char long')
      .matches(URL, 'Url is not valid'),
    confrirmTrackingUrl: Yup.string()
      .required('Tracking url confirmation is mandatory')
      .oneOf([Yup.ref('trackingUrl')], 'Tracking url do not match'),
  })

  const formOptions = { resolver: yupResolver(formSchema) }
  const { register, handleSubmit, reset, formState } = useForm(formOptions)
  const { errors } = formState

  async function onSubmit(data) {
   
    SetTrackInfo(data)

    // get product data from contract




  }
  return (
    <div>
      
      <form onSubmit={handleSubmit(onSubmit)}>
        <div>
          <label className="form-label">Tracking number </label> 
          <i className="fa fa-info-circle fa-lg shipping-alert" 
          data-toggle="tooltip" style={{color:'#C80909'}} 
          data-bs-placement="top" title="Please note that it is mandatory to ship the item(s) ordered by the buyer within 5 days and provide a tracking number. Failure to do so will result in the buyer having the right to contest the deal and the money transfer being suspended. In case of a contestation, Sabuy will investigate and settle the issue">
</i>
 
          <input
            name="tracking"
            type="name"
            {...register('tracking')}
            className={`form-control ${errors.tracking ? 'is-invalid' : ''}`}
          />
          <div className="invalid-feedback">{errors.tracking?.message}</div>
        </div>
        <div>
          <label className="form-label">Confirm tracking number</label>
          <input
            name="confrirmTrack"
            type="name"
            {...register('confrirmTrack')}
            className={`form-control ${errors.confrirmTrack ? 'is-invalid' : ''}`}
          />
          <div className="invalid-feedback">{errors.confrirmTrack?.message}</div>
        </div>

        <div>
          <label className="form-label">Shipping tracking page URL</label>
          <input
            name="trackingUrl"
            type="name"
            {...register('trackingUrl')}
            className={`form-control ${errors.trackingUrl ? 'is-invalid' : ''}`}
          />
          <div className="invalid-feedback">{errors.trackingUrl?.message}</div>
        </div>
        <div>
          <label className="form-label">Confirm URL</label>
          <input
            name="confrirmTrackingUrl"
            type="name"
            {...register('confrirmTrackingUrl')}
            className={`form-control ${errors.confrirmTrackingUrl ? 'is-invalid' : ''}`}
          />
          <div className="invalid-feedback">{errors.confrirmTrackingUrl?.message}</div>
        </div>

        <div className="mt-3">
          <button type="submit" className="btn btn-dark">
          Validate
          </button>
        </div>
      </form>
    </div>
  )
}
export default TrackingForm;