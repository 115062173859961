import Utils from "../../utils";
export function authorReducer(
  state: any = {},
  action: any
) {
  //console.log(action);
  switch (action.type) {
    case Utils.actionName.GET_NFTs_BY_USER:
      return action.payload;
    case Utils.actionName.GET_LIKED_NFTs_BY_USER:
      return action.payload;
    case Utils.actionName.GET_REVIEWS:
      return action.payload;
    case Utils.actionName.GET_Transaction_BY_USER:
      return action.payload;
    default:
      return state;
  }
}