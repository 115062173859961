import Utils from "../../utils";

export function GetAllNFTForExplore(
  payload: any,
  callback: Function = function () {}
) {
  return (dispatch: Function, getState: Function) => {
    let hasConnection = Utils.constant.hasConnection();
    if (!hasConnection) {
      return false;
    }
    Utils.constant.handleOverlayLoader(true);
    let endPoint = Utils.endpoints.GetAllNFTsForExplore;
    Utils.api.postApiCall(
      endPoint,
      payload,
      function (response: any) {
        if (response.status) {
          let data: any = response.data.data;
          //  console.log(data);
          dispatch({
            type: Utils.actionName.GET_NFTs_BY_USER,
            payload: data,
          });
          callback();
        }
      },
      function (error: any) {
        if (error.status === 501) {
          Utils.constant.apiErrorMessage(error.data.message);
        }
      }
    );
  };
}

export function GetNFTbyCategory(
  payload: any,
  Category_NFTs: String,
  callback: Function = function () {}
) {
  return (dispatch: Function, getState: Function) => {
    let hasConnection = Utils.constant.hasConnection();
    if (!hasConnection) {
      return false;
    }
    Utils.constant.handleOverlayLoader(true);
    let endPoint = Utils.endpoints.GetNFTsCategory + Category_NFTs;
    Utils.api.postApiCall(
      endPoint,
      payload,
      function (response: any) {
        if (response.status) {
          let data: any = response.data.data;
          console.log(data);
          dispatch({
            type: Utils.actionName.GET_NFTs_BY_USER,
            payload: data,
          });
          callback();
        }
      },
      function (error: any) {
        if (error.status === 501) {
          Utils.constant.apiErrorMessage(error.data.message);
        }
      }
    );
  };
}

export function getUserProfileData(
  payload: any,
  authToken: string,
  callback: Function = function () {}
) {
  return (dispatch: Function, getState: Function) => {
    let hasConnection = Utils.constant.hasConnection();
    if (!hasConnection) {
      return false;
    }

    Utils.constant.handleOverlayLoader(true);

    let endPoint = Utils.endpoints.getUserProfileData;

    console.log(endPoint);

    Utils.api.getApiCall(
      endPoint,
      payload,
      authToken,
      function (response: any) {
        if (response.status) {
          let data: any = response.data.data;

          dispatch({
            type: Utils.actionName.USER_PROFILE,
            payload: { data },
          });

          callback();
        }
      },
      function (error: any) {
        if (error.status === 501) {
          Utils.constant.apiErrorMessage(error.data.message);
        }
      }
    );
  };
}
