import { useWeb3React } from "@web3-react/core";
import React, { useEffect, useMemo, useState } from "react";

export const MetaMaskContext = React.createContext(null);
//@ts-ignore
const ethereum = window.ethereum;

export const MetaMaskProvider = ({ children }) => {
  var {
    chainId,
    account,
    activate,
    deactivate,
    setError,
    active,
    library,
    connector,
  } = useWeb3React();

  const [currentaccount, setcurrentaccount] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  // Init Loading
  useEffect(() => {
    // connect().then(val => {
    //     console.error(chainId, currentaccount, active,library,connector)
    //   setIsLoading(false)
    //  })

    if (ethereum) {
      // getcurrentaccount();

      //@ts-ignore
      ethereum.on("accountsChanged", function (accounts) {
        localStorage.clear();
        window.localStorage.clear();
        console.log("accountsChanged");
        //window.location.href = '/home'
        setcurrentaccount(accounts[0]);
        window.location.href = "/home";
      });
      //@ts-ignore
      ethereum.on("chainChanged", function (accounts) {
        localStorage.clear();
        window.location.href = "/home";
        window.location.reload();
      });
      //@ts-ignore
      ethereum.on("connect", function (accounts) {
        window.localStorage.setItem("connectedAccount", accounts[0]);
        setcurrentaccount(accounts[0]);
        //console.log('connectedAccount ')
      });
    }
  }, []);

  // // Connect to MetaMask wallet
  // const connect = async () => {
  //     console.log('Connecting to MetaMask Wallet')
  //     try {
  //         await activate(injected)
  //     } catch(error) {
  //         console.log('Error on connecting: ', error)
  //     }
  // }

  // Disconnect from Metamask wallet
  const disconnect = async () => {
    console.log("Deactivating...");
    try {
      await deactivate();
    } catch (error) {
      console.log("Error on disconnecting: ", error);
    }
  };

  const getcurrentaccount = async () => {
    try {
      const accounts = await ethereum.request({
        method: "eth_requestAccounts",
      });
      const account = accounts[0];
      setcurrentaccount(account);
      // setismetamaskActive(true)
    } catch (error) {
      console.log("Error on disconnecting: ", error);
    }
  };
  const values = useMemo(
    () => ({
      //isActive,
      currentaccount,
      isLoading,
      //connect,
      disconnect,
    }),
    [isLoading, currentaccount]
  );

  return (
    <MetaMaskContext.Provider value={values}>
      {children}
    </MetaMaskContext.Provider>
  );
};

export default function useMetaMask() {
  const context = React.useContext(MetaMaskContext);

  if (context === undefined) {
    throw new Error(
      "useMetaMask hook must be used with a MetaMaskProvider component"
    );
  }

  return context;
}
