import React, { PureComponent } from "react";
import AppLayout from "../../../applayout/applayout";

interface Props {
   
}

interface State {
   
}



export class NotFoundScreen extends PureComponent<Props, State> {
    constructor(props: Props) {
        super(props);
        
       
    }
    

    async componentDidMount() {
        try {

           console.log("not found");
                 
        } catch (ex) {
            console.log(ex);
        }
    }



    render() {
        
        return (
           
                <div className="no-bottom no-top" id="content" style={{ backgroundSize: "cover" }}>
                    <div id="top" style={{ backgroundSize: "cover" }}></div>
                    <section id="subheader" className="text-light" data-bgimage="url(/images/background/subheader.jpg) top">
                        <div className="center-y relative text-center">
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-12 text-center">
                                        {/* <h1>{this.state.nft.title}</h1> */}
                                    </div>
                                    <div className="clearfix"></div>
                                </div>
                            </div>
                        </div>
                    </section>
                   
                     <section aria-label="section" className="mt30 sm-mt-0" style={{ backgroundSize: "cover" }}>
                        <div className="container nft-details" style={{ backgroundSize: "cover" }}>
                            <div className="row" style={{ backgroundSize: "cover" }}>
                               
                                {/*  Details right  */}
                                <div className="col-md-12 px-12 text-center" style={{ backgroundSize: "cover" }}>

                            
                                <h2> Error 404</h2>
                                <h4> Woops. Looks like this page doesn't exist.</h4>                                   
                                <a href="#" onClick={() => window.history.go(-1)}>
      Go Back
    </a>    
                                </div>

                                    
                                    
                                </div> 
                            </div>
                        
                    </section>
                    </div>
            
        );
    }
}



export const NOTFOUND = AppLayout(NotFoundScreen);