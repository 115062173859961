import React, { Component } from "react";
import Modal from "react-bootstrap/Modal";
import { Translation } from "react-i18next";

interface Props {
    onHide?: any;
    confirm?: Function;
    modalShow?: boolean;
    product_id?: number;
    balance: number;
    product?: any;
    wallet: string;
    payOrder: Function;
    buyMaticNow: Function;
}

interface State { }

export class BuyNowModal extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {};
    }
    LogoutFunction = () => {
        localStorage.clear();
    };

    BuyNowClick = ()=> {
        this.props.payOrder();
    }

    buyMaticNow = ()=> {

      this.props.buyMaticNow()
    }
    render() {
        return (
          <React.Fragment>
            <Translation>
              {() => (
                <Modal
                  show={this.props.modalShow}
                  onHide={this.props.onHide}
                  aria-labelledby="contained-modal-title-vcenter"
                  dialogClassName="de-modal"
                  centered
                  animation={false}
                  backdrop="static"
                >
                  <Modal.Body className="modal-body">
                    <button
                      type="button"
                      className="btn-close"
                      onClick={this.props.onHide}
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                    <div className="row">
                      <div className="col-lg-12 col-md-12">
                        <h3>Checkout</h3>
                        You are about to purchase a{" "}
                        <b>{this.props.product.title}</b> from{" "}
                        <b>{this.props.product.authorData[0]?.username}</b>
                        <div className="mt-2"></div>
                        <div className="col-lg-12">
                          <div>
                            Your balance: <strong>{this.props.balance} MATIC</strong>
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <div>
                            You will pay:{" "}
                            <strong>{this.props.product.price} MATIC</strong>
                          </div>
                          
                        </div>
                        <div className="mt-2"></div>
                        {Number(this.props.balance) < Number(this.props.product.price) ? (
                       
                          <button className="btn btn-dark col-12" style={{backgroundColor:'rgb(20, 97, 219)', border:"none"}}   onClick={() => {
                            this.buyMaticNow();
                          }}>
Add Fund with Card

                          </button>
                        ) : (
                          <button
                            className="btn btn-dark col-12"
                            onClick={() => {
                              this.BuyNowClick();
                            }}
                          >
                            {" "}
                            Buy Now{" "}
                          </button>
                        )}
                      </div>
                    </div>
                  </Modal.Body>
                </Modal>
              )}
            </Translation>
          </React.Fragment>
        );
    }
}