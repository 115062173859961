import Utils from "../../utils";
import { NFTModal } from "../../modal";



export function GetReviews(
  payload: any,
  callback: Function = function () { }
) {
  return (dispatch: Function, getState: Function) => {
    let hasConnection = Utils.constant.hasConnection();
    if (!hasConnection) {
      return false;
    }

    Utils.constant.handleOverlayLoader(true);

    let endPoint = Utils.endpoints.GetReviews + "/" + payload.user_id;

    Utils.api.getApiCall(
      endPoint,
      "",
      "",
      function (response: any) {
        if (response.status) {
          let data: any = response.data.data;
          dispatch({
            type: Utils.actionName.GET_REVIEWS,
            payload: { data },
          });

          callback();
        }
      },
      function (error: any) {
        if (error.status === 501) {
          Utils.constant.apiErrorMessage(error.data.message);
        }
      }
    );
  };
}


export function HideUserItem(payload: any, token:any, callback: Function = function () {}) {
  return (dispatch: Function, getState: Function) => {
    let hasConnection = Utils.constant.hasConnection();
    if (!hasConnection) {
      return false;
    }

    Utils.constant.handleOverlayLoader(true);

    let endPoint = Utils.endpoints.HideUserItem;

    Utils.api.postApiCall(
      endPoint,
      payload,
      function (response: any) {
        if (response.status) {
          let data: any = response.data.data;
          dispatch({
            type: Utils.actionName.GET_REVIEWS,
            payload: { data },
          });

          callback();
        }
      },
      function (error: any) {
        if (error.status === 501) {
          Utils.constant.apiErrorMessage(error.data.message);
        }
      }
    );
  };
}


export function DeleteUserItem(payload: any, callback: Function = function () {}) {
  return (dispatch: Function, getState: Function) => {
    let hasConnection = Utils.constant.hasConnection();
    if (!hasConnection) {
      return false;
    }

    Utils.constant.handleOverlayLoader(true);

    let endPoint = Utils.endpoints.DeleteUserItem

    Utils.api.postApiCall(
      endPoint,
      payload,
      function (response: any) {
        if (response.status) {
          let data: any = response.data.data;
          dispatch({
            type: Utils.actionName.GET_REVIEWS,
            payload: { data },
          });

          callback();
        }
      },
      function (error: any) {
        if (error.status === 501) {
          Utils.constant.apiErrorMessage(error.data.message);
        }
      }
    );
  };
}

export function GetLikedNFTsByUser(
  payload: any,
  callback: Function = function () { }
) {
  return (dispatch: Function, getState: Function) => {
    let hasConnection = Utils.constant.hasConnection();
    if (!hasConnection) {
      return false;
    }
    Utils.constant.handleOverlayLoader(true);
    let endPoint = Utils.endpoints.GetLikedNFTsByUser;
    Utils.api.postApiCall(
      endPoint,
      payload,
      function (response: any) {
        if (response.status) {
          let data: any = response.data.data;
         
          dispatch({
            type: Utils.actionName.GET_LIKED_NFTs_BY_USER,
            payload: data,
          });
          callback();
        }
      },
      function (error: any) {
        if (error.status === 501) {
          Utils.constant.apiErrorMessage(error.data.message);
        }
      }
    );
  };
}

export function GetNFTsByUser(
  payload: any,
  callback: Function = function () { }
) {
  return (dispatch: Function, getState: Function) => {
    let hasConnection = Utils.constant.hasConnection();
    if (!hasConnection) {
      return false;
    }
    Utils.constant.handleOverlayLoader(true);
    let endPoint = Utils.endpoints.GetNFTsByUser;
    Utils.api.postApiCall(
      endPoint,
      payload,
      function (response: any) {
        if (response.status) {
          let data: any = response.data.data;
      
          dispatch({
            type: Utils.actionName.GET_NFTs_BY_USER,
            payload: data,
          });
          callback();
        }
      },
      function (error: any) {
        if (error.status === 501) {
          Utils.constant.apiErrorMessage(error.data.message);
        }
      }
    );
  };
}
export function getUserProfileData(
  payload: any,
  authToken: string,
  callback: Function = function () { }
) {
  return (dispatch: Function, getState: Function) => {
    let hasConnection = Utils.constant.hasConnection();
    if (!hasConnection) {
      return false;
    }

    Utils.constant.handleOverlayLoader(true);

    let endPoint = Utils.endpoints.getUserProfileData;

    console.log(endPoint);

    Utils.api.getApiCall(
      endPoint,
      payload,
      authToken,
      function (response: any) {
        if (response.status) {
          let data: any = response.data.data;

          dispatch({
            type: Utils.actionName.USER_PROFILE,
            payload: { data },
          });

          callback();
        }
      },
      function (error: any) {
        if (error.status === 501) {
          Utils.constant.apiErrorMessage(error.data.message);
        }
      }
    );
  };
}
export function UpdatePublicVisibility(payload: NFTModal, token: string, callback: Function) {

  return (dispatch: Function, getState: Function) => {

    let hasConnection = Utils.constant.hasConnection();

    if (!hasConnection) {
      return false;
    }

    Utils.constant.handleOverlayLoader(true);

    let endPoint = Utils.endpoints.UpdatePublicVisibility;

    Utils.api.postApiCallWithoutWebInUrl(
      endPoint,
      payload,
      token,
      function (response: any) {
        if (response.status) {
          let data: NFTModal = response.data.data;
          console.log(data);
          // dispatch({
          //   type: Utils.actionName.UPDATE_NFT,
          //   payload: { data },
          // });
          callback(data);
        }
      },
      function (error: any) {
        if (error.status === 501) {
          Utils.constant.apiErrorMessage(error.data.message);
        }
      }
    );
  };
}


export function getTransactionData(
  payload: any,
  authToken: string,
  callback: Function = function () { }
) {
  return (dispatch: Function, getState: Function) => {
    let hasConnection = Utils.constant.hasConnection();
    if (!hasConnection) {
      return false;
    }

    Utils.constant.handleOverlayLoader(true);

    let endPoint = Utils.endpoints.getUserTransactionData;

    console.log(endPoint);

    Utils.api.getApiCall(
      endPoint,
      payload,
      authToken,
      function (response: any) {
        if (response.status) {
          let data: any = response.data.data;
          //console.log(data)
          dispatch({
            type: Utils.actionName.GET_Transaction_BY_USER,
            payload: { data },
          }); 

          callback(data);
        }
      },
      function (error: any) {
        if (error.status === 501) {
          Utils.constant.apiErrorMessage(error.data.message);
        }
      }
    );
  };
}