import React, { PureComponent } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { CreateNFT } from "../";
import AppLayout from "../../../applayout/applayout";
import ImageUploader from "../../../component/ImageUploader";
import { MessageLoadingModal } from "../../../component/modal-popup/modal-popup";
import web3Utills from '../../../utils/web3Utills';
//import {Datetimepicker} from "../screen/datetimepicker"

interface Props {
    t?: any;
    history?: any;
    SelectCategory?: any;
    CreateNFT?: any;
    categoryList?: any;
    CollectionList?: any;
    createNFTResult?: any;
    SelectCollection?: any
}
interface State {
    newImageSrc: string;
    imageFile: any;
    fileName: string;
    newPreviewImageSrc: string;
    previewImageFile: any;
    previewFileName: string;
    price: number;
    popupButton: string;
    category: string;
    collection: string;
    categoryList?: any;
    conditionList?: any;
    CollectionList?: any;
    description: string;
    title: string;
    pic: string;
    condition: string;
    nftMediaType: string;
    createNFTResult: any;
    isLoading: boolean;
    popupMessage: string;
    popupStatus: string;
    popupTitle: string;
    tokenId: number;
    userProfileImage?: string;
    partnerCode: string;
    subCategory: string;
    accountAddress: string;
    availableForSale: boolean;
    artCreationDate: number;
    tags: string;
    navigateTo: string;
    uploadFileName: string;
    fileType: string;
    fileExtensions: string;
    uploadText: string;
    isFirstTime: boolean;
    error: {
        conditionError?: string;
        fileError?: string;
        titleError?: string;
        categoryError?: string;
        subCategoryError?: string;
        descriptionError?: string;
        priceError?: string;
        startBidError?: string;
        nftTypeError?: string;
        startingDateError?: string;
        expirationDateError?: string;
        creationDateError?: string;
        previewFileError?: string;
    };
}
export class CreateSingleScreen extends PureComponent<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            imageFile: null,
            createNFTResult: {},
            newImageSrc: "",
            fileName: "",
            popupButton: "",
            newPreviewImageSrc: "",
            previewImageFile: null,
            previewFileName: "",
            price: 0,
            category: "",
            collection: "",
            description: "",
            title: "",
            pic: "",
            fileType: "image",
            fileExtensions: "jpg, gif, png, jpeg",
            condition: "",
            nftMediaType: "image",
            accountAddress: "",
            isLoading: false,
            popupMessage: "",
            popupStatus: "loading",
            popupTitle: "Status",
            tokenId: -1,
            userProfileImage: "",
            availableForSale: true,
            partnerCode: "",
            subCategory: "",
            artCreationDate: 0,
            tags: "",
            navigateTo: "",
            uploadFileName: 'Image file',
            uploadText: '',
            isFirstTime: true,
            error: {
                conditionError: "",
                fileError: "",
                titleError: "",
                categoryError: "",
                subCategoryError: "",
                descriptionError: "",
                priceError: "",
                startBidError: "",
                nftTypeError: "",
                startingDateError: "",
                expirationDateError: "",
                creationDateError: "",
                previewFileError: "",
            },
        };
    }
    async componentDidMount() {
        try {

            let connected = await web3Utills.isEtheriumConnected();
            if (!connected) { window.location.href = "/connect-wallet"; return; }

            let profileImage = await localStorage.getItem("profile_image");

            this.setState({
                userProfileImage: String(profileImage),
                categoryList: ['Cosmetics',"Clothing & Accessories", 'Collectible & Art','Electronics', 'Home & Garden', 'Health & Sport'],
                conditionList: ['New with tags', 'New without tags', 'Very good', 'Good', 'Satisfactory'],
            });
        } catch (ex) {
            console.log(ex);
        }
    }
    async multiiImageUpload(images: any) {
        this.setState({ imageFile: images });
        console.log(images);
    }
    handleChange(e: any) {
        try {
            let selectedCategory = e.currentTarget.value;
            this.setState({ category: selectedCategory });
        } catch (ex) {
            console.log(ex);
        }
    }
    handleConditionChange(e: any) {
        try {
            let selected = e.currentTarget.value;
            this.setState({ condition: selected });
        } catch (ex) {
            console.log(ex);
        }
    }
    handleSubChange(e: any) {
        try {
            this.setState({ subCategory: e.currentTarget.value });
        } catch (ex) {
            console.log(ex);
        }
    }
    handleFiledUpdate = (key: string, e: any) => {
        try {
            //@ts-ignore
            this.setState({ [key]: e });
        } catch (ex) {
            console.log(ex);
        }
    };
    handleSubmit = async (event: any) => {
        try {
            // event.currentTarget.disabled = true;
            // event.currentTarget.innerHTML = 'Processing...';
            event.preventDefault();
            console.log('going in')
            //console.log(this.state.description);
            console.log('setting states')
            console.log(this.state.price)
            await this.setState({
                error: {
                    fileError: !this.state.imageFile ? "Please select the file." : "",
                    //previewFileError: (this.state.nftMediaType == "audio" || this.state.nftMediaType == "video" || this.state.nftMediaType == "pdf") && !this.state.previewFileName ? "Please select the preview image." : "",
                    titleError: !this.state.title ? "Please enter the title." : "",
                    categoryError: !this.state.category ? "Please select the category." : "",
                    conditionError: !this.state.category ? "Please select the product condition." : "",
                    priceError: Number(this.state.price) <= 0 ? "Please enter the price." : "",
                    descriptionError: !this.state.description ? "Please add the description." : "",
                },
            }, async () => {
                if (
                    this.state.error?.fileError == "" &&
                    //this.state.error?.previewFileError == "" &&
                    this.state.error?.categoryError == "" &&
                    this.state.error?.conditionError == "" &&
                    this.state.error?.titleError == "" &&
                    this.state.error?.priceError == "" &&
                    this.state.error?.descriptionError == "") {
                    console.log('inside if')
                    this.ShowErrorMessage(true, "Please wait while your item is being created.", "pending", "", "Creating Item");
                    //Call Create NFT Function.
                    let userId = await localStorage.getItem("userId");
                    let token = await localStorage.getItem("accessToken");
                    console.log('making form data')
                    let price = parseFloat(this.state.price.toString()).toFixed(2);
                    price = price.toString();
                    let formData = new FormData();
                    //formData.append('nftFile', this.state.imageFile);
                  
                    for (let i = 0; i < this.state.imageFile.length; i++) {
                        formData.append(
                          "uploaded_images",
                          this.state.imageFile[i]
                        );
                  
                    }
                    formData.append('type', 'SELL');
                    formData.append('previewImage', this.state.previewImageFile);
                    formData.append('category', this.state.category);
                    formData.append('description', this.state.description);
                    formData.append('condition', this.state.condition);
                    formData.append('title', this.state.title);
                    formData.append('creatorId', String(userId));
                    // handling the decimal places on submit
                    formData.append('price', String(this.state.price));
                    // let startBid = parseFloat(this.state.startBid.toString()).toFixed(2);
                    // startBid = startBid.toString();
                    // console.log(startBid);
                    // console.log(formData);
                    // debugger;
                    //payload: FormData, token: string, callback: Function
                    this.props.CreateNFT(formData, token,
                        async () => {
                            window.location.href = "/explore";
                        });
                }
                else if (this.state.error?.fileError != "") {
                    this.setState({ navigateTo: "#fileError" });
                }
                else if (this.state.error?.nftTypeError != "") {
                    this.setState({ navigateTo: "#nftTypeError" });
                }
                else if (this.state.error?.priceError != "") {
                    this.setState({ navigateTo: "#priceError" });
                }
                else if (this.state.error?.categoryError != "") {
                    this.setState({ navigateTo: "#categoryError" });
                }
                else if (this.state.error?.subCategoryError != "") {
                    this.setState({ navigateTo: "#subCategoryError" });
                }
                // else if (this.state.error?.collectionNameError != "") {
                //     this.setState({ navigateTo: "#collectionNameError" });
                // }
                else if (this.state.error?.titleError != "") {
                    this.setState({ navigateTo: "#titleError" });
                }
                else if (this.state.error?.descriptionError != "") {
                    this.setState({ navigateTo: "#descriptionError" });
                }
            });
        } catch (ex) {
            console.log(ex);
        }
    }
    ShowErrorMessage(isShowLoading: boolean, message: string, status: string, transactionHash: any, title: string) {
        try {
            this.setState({
                isLoading: isShowLoading,
                popupTitle: title,
                popupStatus: status,
                popupMessage: message,
            });
        } catch (ex) {
            console.log(ex);
        }
    }
    handlePriceChange = (key: string, e: any) => {
        try {
            let value = e.target.value;
            if (value != "") {
                if (value && Number(value) >= 0) {
                    this.setState({ price: value });
                    console.log('price ' + value);
                }
            }
        } catch (ex) {
            console.log(ex);
        }
    }
    render() {
        let categoryOptions: any = null;
        let conditionOptions: any = null;
        if (
            this.state.categoryList &&
            Array.isArray(this.state.categoryList) &&
            this.state.categoryList.length > 0
        ) {
            categoryOptions = this.state.categoryList.map((category: any) => {
                return <option className="textBlack" style={{ color: "black" }} value={category} key={category}>{category}</option>;
            });
        }
        if (
            this.state.conditionList &&
            Array.isArray(this.state.conditionList) &&
            this.state.conditionList.length > 0
        ) {
            conditionOptions = this.state.conditionList.map((category: any) => {
                return <option className="textBlack" style={{ color: "black" }} value={category} key={category}>{category}</option>;
            });
        }
        return (
            <>
                <div className="container">
                    <MessageLoadingModal onHide={() => { this.setState({ isLoading: false }); return true; }} title={this.state.popupTitle} modalShow={this.state.isLoading} button={this.state.popupButton} message={this.state.popupMessage} status={this.state.popupStatus}></MessageLoadingModal>
                    <div className="row">
                        <div className="col-12 col-lg-8">
                            <h1>Sell now</h1>
                            <div className="col-12 border d-none d-sm-block"></div>
                        </div>
                        <div className="mt-1"></div>
                        <div className="col-12 col-lg-8">
                            <div className="alert alert-primary text-center" role="alert">
                                Your transaction is secured by the <strong>Web3</strong> technology. <a href="/help" className="alert-link">Learn more</a>
                            </div>
                            <div className="mt-1"></div>
                            <ImageUploader multiiImageUpload={this.multiiImageUpload.bind(this)} />
                            <span className="text-danger" id="fileError">{this.state.error.fileError}</span>
                        </div>
                        <div className="mt-3"></div>
                        <div className="col-12 col-lg-8">
                            <div className="mb-3">
                                <label htmlFor="exampleFormControlInput1" className="form-label">Title* <span className="text-danger" id="titleError">{this.state.error.titleError}</span></label>
                                <input
                                    value={this.state.title}
                                    onChange={(e: any) => {
                                        this.handleFiledUpdate("title", e.currentTarget.value);
                                    }}
                                    maxLength={50}
                                    type="text" name="item_title" id="item_title" className="form-control" placeholder="e.g. White COS Jumper" />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="exampleFormControlTextarea1" className="form-label">Description* <span className="text-danger" id="descriptionError">{this.state.error.descriptionError}</span></label>
                                <textarea className="form-control"
                                    value={this.state.description}
                                    onChange={(e: any) => {
                                        this.handleFiledUpdate("description", e.currentTarget.value);
                                    }}
                                    data-autoresize
                                    name="item_desc"
                                    id="item_desc"
                                    placeholder="e.g. only worn a few times, true to size"
                                ></textarea>
                            </div>
                            <div className="mb-3">
                                <label htmlFor="exampleFormControlInput1" className="form-label">Category <span className="text-danger" id="categoryError">{this.state.error.categoryError}</span></label>
                                <div className="dropdown">
                                    <select className="form-control col-md-12 dropdown textBlack" onChange={(e: any) => {
                                        this.handleFiledUpdate("category", e.currentTarget.value);
                                    }}>
                                        <option style={{ color: "black" }} value="">Choose your category</option>
                                        {categoryOptions}
                                    </select>
                                </div>
                            </div>
                            <div className="mb-3">
                                <label htmlFor="exampleFormControlInput1" className="form-label">Condition <span className="text-danger" id="categoryError">{this.state.error.conditionError}</span></label>
                                <div className="dropdown">
                                    <select className="form-control col-md-12 dropdown textBlack" onChange={(e: any) => {
                                        this.handleFiledUpdate("condition", e.currentTarget.value);
                                    }}>
                                        <option style={{ color: "black" }} value=""> Condition of your item</option>
                                        {conditionOptions}
                                    </select>
                                </div>
                            </div>
                            <div className="mb-3">
                                <label htmlFor="exampleFormControlTextarea1" className="form-label">Price in MATIC <span className="text-danger" id="categoryError">{this.state.error.priceError}</span></label>
                                <input
                                    step="1"
                                    min={0}
                                    onChange={(e: any) => {
                                        this.handlePriceChange("price", e);
                                    }}
                                    type="number" name="item_price" id="item_price" className="form-control" placeholder="100" />
                            
                            
                            <span className="text-muted product-info fs-6 lh-1">As a seller, it is your responsibility to add the shipping cost to the selling price before listing your items on our platform.</span>
                            </div>
                            <div className="mt-1"></div>
                            <div className="d-flex justify-content-center">
                                <div className="col-12 col-lg-4">
                                    <button type="button" onClick={(evt) => { this.handleSubmit(evt) }} className="btn btn-dark w-100" style={{ height: "60px" }}>Sell now</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}
function mapStateToProps(state: any) {
    return {
        createNFTResult: state.CreateNFTReducer || {},
    };
}
function mapDispatchToProps(dispatch: Function) {
    return {
        CreateNFT: (payload: FormData, token: string, callback: Function) => dispatch(CreateNFT(payload, token, callback))
    }
}
export const CreateSingleModule = withTranslation()(
    connect(mapStateToProps, mapDispatchToProps)(CreateSingleScreen)
);
export const CreateSingle = AppLayout(CreateSingleModule);