import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { Web3ReactProvider } from '@web3-react/core'
import Web3 from 'web3'
import { MetaMaskProvider } from './hooks/metamask'


  if (process.env.REACT_APP_MODE == "production")
  {console.log = function no_console() {};}

  console.log(process.env.NODE_ENV)

  function getLibrary(provider:any, connector:any) {
    return new Web3(provider)
  }
  
  const rootNode = document.getElementById('root');
ReactDOM.render(
  <React.StrictMode>
    <Web3ReactProvider getLibrary={getLibrary}>
      <MetaMaskProvider> 
        <App />
      </MetaMaskProvider>
      </Web3ReactProvider>
    
  </React.StrictMode>,
  rootNode
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
