import React, { useEffect, useState } from "react";
import { NFTModal, UserDataModal } from "../../../modal";
import NftCard from '../../../component/NftCard';
import SkeletonCard from '../../../component/SkeletonCard';
import '../../../component/style.scss'
import Utils from "../../../utils";
import AppLayout from "../../../applayout/applayout";

let endPoint = Utils.endpoints.GetLikedNFTsByUser;
let api_url =  Utils.constant.apiUrl;

const userId = localStorage.getItem('userId');

export const Liked_itemsScreen = () => {


const [data, setData] = useState([]);
const [error, setError] = useState(null);
const [loading, setLoading] = useState(false);
const [filtercategory, setfiltercategory] = useState('');
const [category, setcategory] = useState('');


   function RenderNFTs(nft: NFTModal) {
        return <NftCard nft={nft} key={nft._id} />
    }

    const payload = { userId: userId };
    
  useEffect(() => {
    setLoading(true);
    
    Utils.api.postApiCall(
        endPoint,
        payload,
        function (response: any) {
          if (response.status) {
            let data: any = response.data.data;
            setData(data);
            setLoading(false);
        }},
        function (error: any) {
          if (error.status === 501) {
            Utils.constant.apiErrorMessage(error.data.message);
          }
        }
      );



  }, []);

  function filterCategory (selection: any) {

         
         switch (selection) {
             case 'clothing-accessories': setcategory('Clothing & Accessories');    selection = "clothing-accessories";  setfiltercategory(selection);   return;
             case 'electronics': selection = 'electronics';  setcategory('Electronics');   setfiltercategory(selection);   return;
             case 'home-garden': selection = 'home-garden'; setcategory('Home & Garden');   setfiltercategory(selection);   return;
             case 'collectible-art': selection = 'collectible-art'; setcategory('Collectible Art');   setfiltercategory(selection);   return;
             case 'Others': selection = 'Others'; setcategory('Others');   setfiltercategory(selection);   return;
             default:  setcategory('All');   setfiltercategory('All');   return;
                      
 }
  }
  
    
     return (
        
        <div className="container">
             <div className="row">
               

                 <div className="col-12 col-lg-8">
                        <h1>Liked Items</h1>
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><a href="/">Home</a></li>
                                 <li className="breadcrumb-item">Liked Items</li>
                               
                                {/* <li className="breadcrumb-item"><a href="#">Sub-category1</a></li>
                                <li className="breadcrumb-item active" aria-current="page">Sub-sub-category1</li> */}
                            </ol>
                        </nav>
                        <div className="col-12 border d-none d-sm-block"></div>
                    </div>


                 <div className="mt-1"></div>

               

                 <div className="col-12 col-lg-8">
                     <div className="row">
                         {loading ? (
                             <SkeletonCard />
                         ) : (
                             <div className="row wow fadeIn animated">
                                 {(data && data.length > 0) ? (
                                     data.map((nft: NFTModal, index: any) => {

                                         return (
                                             RenderNFTs(nft)
                                         );

                                     })
                                 ) : (<>
                                     <span>No items in the category! <span style={{ cursor: 'pointer' }} onClick={() => { window.location.href = '/explore'; } }><u><b>Explore the marketplace</b></u></span></span>
                                 </>)}
                             </div>
                         )}
                     </div>
                 </div>
             </div>
         </div>
        );
                        
}



  export const Liked_items = AppLayout(Liked_itemsScreen);
  