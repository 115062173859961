import React, { PureComponent } from "react";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { GetNFT, ProcessBuyNow, RequestMessage } from "..";
import AppLayout from "../../../applayout/applayout";
import { Walletmodal } from "../../../component/metamask/walletmodel";
import { BuyNowModal } from "../../../component/modal-popup/modal-buynow";
import { MessageLoadingModal } from "../../../component/modal-popup/modal-popup";
import { NFTModal } from "../../../modal";
import utils from '../../../utils';
import { notification_handler } from "../../../utils/common";
import { sabuyContract, AlchemyProvider } from "../../../utils/contractWeb3";
import web3Utills from '../../../utils/web3Utills';
import transakSDK from '@transak/transak-sdk';
const axios = require('axios');

const multiplicationFactor = 0.25;

const gasLimit = 1500000;
const extraGas = 1000000;

const options = { method: 'GET', headers: { accept: 'application/json' } };

interface Props {
  t?: any;
  history?: any;
  GetNFT?: any;
  tokenId?: number;
  UpdateNFT?: any;
  wallet: string;
  ProcessBuyNow?: any;
  getNFTResult?: any;
  OrderResult?: any;
  MessageResult?: any;
  RequestMessage: any;
  getTransactionHistory: any;
  getTransactionResult: any;
  getBidHistory: any;
  getBidHistoryResult: any;
}
interface State {
  nft: NFTModal;
  nft_transaction: any;
  nft_bidtransaction: any;
  tokenId: number;
  playingTokenId: number,
  userId: string;
  wallet: string,
  OrderResult: any;
  createNFTResult: any;
  transactionHashBuyNow: string;
  isLoading: boolean;
  isWallet: boolean;
  isBuyNow: boolean;
  popupMessage: string;
  popupStatus: string;
  popupTitle: string;
  popupButton: string;
  descToggle: string;
  showImage: string;
  priceMatic: number;
  liked: boolean;
  likedcount: number;
  walletBalance: number;
  IsMetamaskInstalled: boolean;
  nbCopie: number;
  accountAddress: string;
  NFTavailable: number;
  highestBid: number;
  creator: string;
  isOwner: boolean;
  error: {
    nbCopie?: string;
  };
}
export class ItemDetailScreen extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      nft: new NFTModal(),
      nft_transaction: [],
      nft_bidtransaction: [],
      //@ts-ignore
      tokenId: String(props.match.params.id),
      playingTokenId: -1,
      userId: '',
      accountAddress: '',
      createNFTResult: {},
      OrderResult: {},
      isLoading: false,
      isBuyNow: false,
      isWallet: false,
      wallet: "",
      IsMetamaskInstalled: false,
      transactionHashBuyNow: "",
      descToggle: "hide",
      popupMessage: "",
      priceMatic: 0,
      popupStatus: "loading",
      popupButton: "",
      showImage: "",
      popupTitle: "Status",
      liked: false,
      likedcount: 0,
      nbCopie: 1,
      NFTavailable: 0,
      highestBid: 0,
      walletBalance: 0,
      creator: '',
      isOwner: false,
      error: {
        nbCopie: ""
      }
    };

  }

  async componentDidMount() {
    try {



      this.props.GetNFT(this.state.tokenId, () => {
        this.setState({ nft: this.props.getNFTResult, userId: String(window.localStorage.getItem('userId')), likedcount: (this.props.getNFTResult.likes != "undefined") ? this.props.getNFTResult.likes : 0 }, () => {
          this.setState({ liked: this.state.nft.liked });
          this.setState({ isOwner: this.props.getNFTResult.creator == String(window.localStorage.getItem('userId')) })

          fetch('https://api-stg.transak.com/api/v2/currencies/price?partnerApiKey='
            + '3557d2f6-6581-45b4-8848-501c6563ddee' +
            '&fiatCurrency=USD&cryptoCurrency=MATIC&isBuyOrSell=BUY&network=polygon&paymentMethod=credit_debit_card&cryptoAmount=1', options)

            .then(response => response.json())
            .then(response => {
              let data = response.response;
              this.setState({ priceMatic: Number((data.fiatAmount - data.slippage).toFixed(2)) });


            })
            .catch(err => console.error(err));

        });
      });


    } catch (ex) {
      console.log(ex);
    }
  }


  async AddtoFavorite() {
    this.setState({ liked: !this.state.liked });
    if (this.state.liked) this.setState({ likedcount: this.state.likedcount - 1 });
    else this.setState({ likedcount: this.state.likedcount + 1 });
    notification_handler(this.state.nft)
  }
  ShowPopupMessage(isShowLoading: boolean, message: string, status: string, _transactionHash: any, title: string, buttom: string) {
    try {
      this.setState({
        isLoading: isShowLoading,
        popupTitle: title,
        popupStatus: status,
        popupMessage: message,
        popupButton: buttom,
      });
    } catch (ex) {
      console.log(ex);
    }
  }
  ShowPopupBuyNow(show: boolean, walletBalance: number, wallet: string) {
    try {
      this.setState({
        isBuyNow: show,
        walletBalance: walletBalance,
        wallet: wallet
      });
    } catch (ex) {
      console.log(ex);
    }
  }

  renderButtons() {


    if (this.state.isOwner && this.state.nft.status == "AVAILABLE") {
      return (
        <div className="col-12">
          <a
            href={"/edit-item/" + this.state.nft._id}
            className="btn btn-dark col-12"
          >
            Edit the Item
          </a>
        </div>
      )
    }

    else {
      if (this.state.nft.status == "AVAILABLE") {
        return (<><div className="col-12">
          <button
            type="button"
            onClick={() => {
              this.BuyNowhandleclick();
            }}
            className="btn btn-dark col-12"
          >
            Buy now
          </button>
        </div>
          <div className="mt-1"></div>
          <div className="col-12">
            <button
              type="button"
              onClick={(e) => {
                this.sendmessage();
              }}
              className="btn btn-outline-dark col-12"
            >
              Send a message to the seller
            </button>
          </div>
          <div className="mt-1"></div>
          <span className="text-muted product-info fs-6 lh-1 mt-1">After placing your order, please use our chat system to contact the seller and arrange the delivery details.</span></>)
      }
      else {
        return (<><div className="col-12">
          <button
            type="button"

            className="btn btn-dark col-12 disabled"
          >
            Sold
          </button>
        </div><div className="mt-1"></div><div className="col-12">
            <button
              type="button"
              onClick={(e) => {
                this.sendmessage();
              }}
              className="btn btn-outline-dark col-12"
            >
              Send a message to the seller
            </button>
          </div>  <div className="mt-1"></div>
          <span className="text-muted product-info fs-6 lh-1 mt-1">After placing your order, please use our chat system to contact the seller and arrange the delivery details.</span></>)
      }

    }


  }

  async sendmessage() {


    const userToken = await localStorage.getItem("accessToken");
    let account_connected = await localStorage.getItem("connectedAccount");
    let connected = await web3Utills.isEtheriumConnected();

    if (!userToken || !connected) window.location.href = "/connect-wallet";


    let payload = {
      sender: this.state.userId,
      product_id: this.state.nft.product_id,
      receiver: this.state.nft.creator,
      message: "I am interested in your item",
    }

    let tokenA = await localStorage.getItem("accessToken");

    this.props.RequestMessage(payload, tokenA, () => {

      window.location.href = "/inbox";

    });
  }


  async BuyNowhandleclick() {
    let isAuthenticated = utils.constant.getAccessToken()
    if (isAuthenticated == false) {
      window.location.href = '/connect-wallet?returnUrl=/item-details/' + this.state.nft.product_id;

    }
    else {
      let walletbalance = await web3Utills.getCurrentAccountBalanceCoins();
      let wallet = await web3Utills.getCurrentAccount();
      console.log("walletbalance " + walletbalance)
      this.ShowPopupBuyNow(true, walletbalance, wallet);
    }
    //await web3Utills.SignwithMetamask();
  }
  async doLogin() {
    await web3Utills.SignwithMetamask();
  }

  filterCategory(selection: any) {

    if (selection == 'All') { return "all"; }
    else if (selection == 'Collectible & Art') { return "collectible-art"; }
    else if (selection == 'Clothing & Accessories') { return "clothing-accessories"; }
    else if (selection == 'Electronics') { return "electronics"; }
    else if (selection == 'Home & Garden') { return "home-garden"; }
    else if (selection == 'Others') { return "others"; }

  }


  ShowErrorMessage(isShowLoading: boolean, message: string, status: string, transactionHash: any, title: string) {
    try {
      this.setState({
        isLoading: isShowLoading,
        popupTitle: title,
        popupStatus: status,
        popupMessage: message
      });
    } catch (ex) {
      console.log(ex);
    }
  }

  async buyMaticNow() {

    this.setState({ isBuyNow: false });

    let accountAddress = await web3Utills.getCurrentAccount();

    //@ts-ignore

    const userId = await window.localStorage.getItem("userId");

    const settings = {
      apiKey: process.env.REACT_APP_TRANSAK_APIKEY,  // Your API Key
      environment: process.env.REACT_APP_TRANSAK_MODE, // STAGING/PRODUCTION
      defaultCryptoCurrency: 'MATIC',
      themeColor: '000000', // App theme color
      hostURL: window.location.origin,
      defaultPaymentMethod: 'credit_debit_card',
      widgetHeight: "700px",
      widgetWidth: "400px",
      walletAddress: accountAddress,
      cryptoCurrencyList: 'MATIC',
      // email: accountEmail,
      networks: 'POLYGON'
    }


    let transak = new transakSDK(settings);


    transak.init();


    // To get all the events
    transak.on(transak.ALL_EVENTS, (data) => {
      // console.log(data)
    });

    // This will trigger when the user closed the widget
    transak.on(transak.EVENTS.TRANSAK_WIDGET_CLOSE, (eventData) => {
      //console.log(eventData);
      // transak.close();
    });

    // This will trigger when the user marks payment is made.
    transak.on(transak.EVENTS.TRANSAK_ORDER_SUCCESSFUL, (orderData) => {
      console.log(orderData);
      if (orderData.eventName == 'TRANSAK_ORDER_SUCCESSFUL') {
        this.ShowErrorMessage(true, "Bought" + orderData.status.cryptoAmount + " MATIC Successfully. You will receive confirmation email shortly and BNB will be transferred to you wallet.", "pending", '', "Status");
        setTimeout(() => { this.setState({ isLoading: false, isBuyNow: true }); }, 6000);
      }
      transak.close();
    });


  }


  async payOrder() {

    this.setState({ isBuyNow: false });

    var uid = await window.localStorage.getItem('userId');
    let tokenA = await localStorage.getItem("accessToken");

    let accountAddress = await web3Utills.getCurrentAccount();
    let vendor = this.state.nft.authorData[0].eth_wallet_address;
    let product_qty = 1;
    let product_id = this.state.nft.product_id;
    let price = this.state.nft.price


    //let priceBN = String(web3Utills.toWei(String(0.1)))

    let priceBN = String(web3Utills.toWei(String(price)));



    console.log("accountAddress " + accountAddress)
    console.log("vendor " + this.state.nft.authorData[0].eth_wallet_address)
    console.log("product_id " + this.state.nft.product_id)
    console.log("priceBN" + priceBN)



    let gasPrice = await AlchemyProvider.core.getGasPrice();
    console.log("gasPrice rec:" + gasPrice);

    this.ShowPopupMessage(true, "Your transaction is in-progress. Please confirm it in Metamask", "pending", "", "Buying Product", '');
    try {
      await sabuyContract.methods
        .payOrder(product_id, vendor, product_qty, priceBN)
        .send({
          from: accountAddress,
          gas: gasLimit,
          gasPrice: gasPrice.add(extraGas),
          value: priceBN
        })

        .on("transactionHash", (transactionHash: any) => {
          this.setState({ transactionHashBuyNow: transactionHash });
          this.ShowPopupMessage(
            true,
            "Your transaction is in-progress. Transaction Details: <a target='_blank' href='" +
            process.env.REACT_APP_ETH_SCAN_URL +
            transactionHash +
            "'> Details Link </a>",
            "pending",
            transactionHash,
            "Transaction Status",
            "OK"
          );
        })
        .on(
          "confirmation",
          async (confirmationNumber: any, reciept: any) => {
            this.ShowPopupMessage(
              true,
              "Your transaction has been confirmed!",
              "success",
              this.state.transactionHashBuyNow,
              "Transaction Status",
              "OK"
            );
            if (reciept && confirmationNumber == 1) {
              let saveData = this.state.createNFTResult;
              saveData.product_id = product_id;
              saveData.product = this.state.nft._id;
              saveData.buyer = uid;
              saveData.transactionhash = reciept.transactionHash;
              saveData.seller = this.state.nft.authorData[0]._id;
              saveData.query_type = "PENDING";

              this.props.ProcessBuyNow(saveData, tokenA, async () => {
                console.log(this.props.OrderResult);

                window.location.href = "/my-transactions";
              });
            }
          }
        )
        .catch((errors3: any) => {

          if (errors3.code == 4001)
            this.ShowPopupMessage(
              true,
              "You rejected the operation in your Metamask",
              "failed",
              "",
              "Rejected",
              "Close"
            );
          if (errors3.code == -32603)
            this.ShowPopupMessage(
              true,
              "Unexpected error occurred",
              "failed",
              "",
              "Error",
              "Close"
            );
        });
    }
    catch (err) { console.log(err) }



  }

  render() {
    const d = new Date();
    return (
      <>
        <HelmetProvider>
          <Helmet>
            <title>{this.state.nft.title} | www.sabuy.shop </title>
            <meta name="title" content={this.state.nft.title} />
            <meta name="description" content={this.state.nft.description} />
            <meta property="og:type" content="website" />
            <meta property="og:url" content={window.location.href} />
            <meta property="og:title" content={this.state.nft.title} />
            <meta
              property="og:description"
              content={this.state.nft.description}
            />
            <meta
              property="og:image"
              content={this.state.nft.previewImagePath}
            />
            <meta property="twitter:card" content="summary_large_image" />
            <meta property="twitter:url" content={window.location.href} />
            <meta property="twitter:title" content={this.state.nft.title} />
            <meta
              property="twitter:description"
              content={this.state.nft.description}
            />
            <meta
              property="twitter:image"
              content={this.state.nft.previewImagePath}
            />
          </Helmet>
        </HelmetProvider>
        <div className="mt-4"></div>
        <div className="container-fluid">
          <div className="row">
            <div className="col-0 col-sm-1"></div>
            <div className="col-12 col-sm-6">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a
                      href={
                        "/explore/" +
                        this.filterCategory(this.state.nft.category)
                      }
                    >
                      {this.state.nft.category}
                    </a>
                  </li>
                  <li className="breadcrumb-item">
                    <a href={"/item-details/" + this.state.nft.product_id}>
                      {this.state.nft.title}
                    </a>
                  </li>
                  {/* <li className="breadcrumb-item active" aria-current="page">Subsubcategory1</li> */}
                </ol>
              </nav>
            </div>
          </div>
        </div>
        <div
          className="no-bottom no-top"
          id="content"
          style={{ backgroundSize: "cover" }}
        >
          <div id="top" style={{ backgroundSize: "cover" }}></div>

          <MessageLoadingModal
            onHide={() => {
              this.setState({ isLoading: false });
              return true;
            }}
            title={this.state.popupTitle}
            modalShow={this.state.isLoading}
            button={this.state.popupButton}
            message={this.state.popupMessage}
            status={this.state.popupStatus}
          ></MessageLoadingModal>
          <Walletmodal
            handlelogin={this.doLogin.bind(this)}
            walletinstalled={this.state.IsMetamaskInstalled}
            modalShow={this.state.isWallet}
            onHide={() => {
              this.setState({ isWallet: false });
            }}
          />
          <BuyNowModal
            onHide={() => {
              this.setState({ isBuyNow: false });
              return true;
            }}
            modalShow={this.state.isBuyNow}
            product={this.state.nft}
            balance={this.state.walletBalance}
            wallet={this.state.wallet}
            product_id={this.state.nft.product_id}
            payOrder={this.payOrder.bind(this)}
            buyMaticNow={this.buyMaticNow.bind(this)}
          ></BuyNowModal>
          <div className="container-fluid">
            <div className="row product-details">
              <div className="col-0 col-lg-1"></div>
              <div className="col-12 col-lg-6">
                <div
                  id="carouselExampleDark"
                  className="carousel carousel-dark slide"
                  data-bs-ride="carousel"
                >
                  <div className="carousel-indicators">
                    {this.state.nft.previewImagePath?.length > 0
                      ? this.state.nft.previewImagePath.map(
                        (_image: any, index: any) => {
                          return (
                            <button
                              type="button"
                              data-bs-target=""
                              data-bs-slide-to={index}
                              className="active"
                              aria-current="true"
                              aria-label="Slide 1"
                            ></button>
                          );
                        }
                      )
                      : null}
                  </div>
                  <div className="carousel-inner">
                    {this.state.nft.previewImagePath?.length > 0 ? (
                      this.state.nft.previewImagePath.map(
                        (image: any, index: any) => {
                          return (
                            <div
                              className={
                                index == 0
                                  ? "carousel-item active"
                                  : "carousel-item"
                              }
                              data-bs-interval="10000"
                            >
                              <div
                                className="container text-center"
                                style={{ height: "auto" }}
                              >
                                <img
                                  src={image}
                                  id={
                                    "img" + this.state.nft._id + "_" + index
                                  }
                                  // onLoad={(e: any) => {
                                  //     this.changeImageSize(e, 'img2' + this.state.nft._id)
                                  // }}
                                  className="img-fluid img-rounded mb-sm-30"
                                  alt=""
                                />
                              </div>
                              <div className="carousel-caption d-none d-md-block">
                                <h5>{index + 1}</h5>
                              </div>
                            </div>
                          );
                        }
                      )
                    ) : (
                      <img
                        src={this.state.nft.thumbnailImageBuffer}
                        // onLoad={(e: any) => {
                        //     this.changeImageSize(e, 'img2' + this.state.nft._id)
                        // }}
                        className="img-fluid img-rounded mb-sm-30"
                        alt=""
                      />
                    )}
                  </div>
                  <button
                    className="carousel-control-prev"
                    type="button"
                    data-bs-target="#carouselExampleDark"
                    data-bs-slide="prev"
                  >
                    <span
                      className="carousel-control-prev-icon"
                      aria-hidden="true"
                    ></span>
                    <span className="visually-hidden">Previous</span>
                  </button>
                  <button
                    className="carousel-control-next"
                    type="button"
                    data-bs-target="#carouselExampleDark"
                    data-bs-slide="next"
                  >
                    <span
                      className="carousel-control-next-icon"
                      aria-hidden="true"
                    ></span>
                    <span className="visually-hidden">Next</span>
                  </button>
                </div>
              </div>
              <div className="col-12 col-lg-3">
                <div className="col-12 border">
                  <ul className="list-group list-group-flush">
                    <li className="list-group-item">
                      <strong className="h3">
                        {this.state.nft.price} MATIC
                      </strong>
                      {this.state.priceMatic > 0 && <span className="ms-1">({Number(this.state.nft.price * this.state.priceMatic).toFixed(2)}$)</span>}
                    </li>
                    <li className="list-group-item">
                      <div className="container-fluid">
                        <div className="row text-secondary">
                          <div className="col-6" id="category">
                            Category
                          </div>
                          <div className="col-6" id="category_name">
                            {this.state.nft.category}
                          </div>
                          {/* <div className="col-6" id="brand">
                                Brand
                              </div>
                              <div className="col-6" id="brand_value">
                                Nike
                              </div> */}
                          <div className="col-6" id="condition">
                            Condition
                          </div>
                          <div className="col-6" id="condition_status">
                            {this.state.nft.condition}
                          </div>
                        </div>
                      </div>
                    </li>
                    <div className="mt-1"></div>
                    <div className="mt-1"></div>
                    <div className="container">
                      <div className="row">
                        <div className="col-12">
                          <div className="alert alert-primary" role="alert">
                            Your transaction is secured by the{" "}
                            <strong>Web3</strong> technology.{" "}
                            <a href="/help" className="alert-link">
                              Learn more
                            </a>
                          </div>
                        </div>
                        {this.renderButtons()}


                        <div className="mt-1"></div>
                      </div>
                    </div>
                    <li className="list-group-item">
                      <strong>{this.state.nft.title}</strong>
                      <br />
                      {this.state.nft.description}
                    </li>
                  </ul>
                  <div className="mt-1"></div>
                </div>
                <div className="mt-1"></div>
                <div className="col-12 border">
                  <div className="container">
                    <div className="row">
                      <div className="col-3">
                        <div className="mt-1"></div>
                        <div className="creator-info">
                          <a
                            href={
                              "/user/" + this.state.nft.authorData[0]?._id
                            }
                          >
                            {" "}
                            <img
                              className="lazy creator-avatar"
                              src={
                                this.state.nft.authorData[0]?.user_image
                                  ? this.state.nft.authorData[0]
                                    ?.user_image +
                                  "?time=" +
                                  d.getTime()
                                  : require("../../../assets/images/author_single/profile-placeholder.png")
                              }
                              alt=""
                            />
                          </a>
                        </div>
                        <div className="mt-1"></div>
                      </div>
                      <div className="col-9 d-flex align-items-center">
                        <div className="author_list_info">
                          <span>
                            <a
                              href={
                                "/user/" + this.state.nft.authorData[0]?._id
                              }
                            >
                              {this.state.nft.authorData[0]?.username
                                ? this.state.nft.authorData[0]?.username
                                : "0x..." +
                                this.state.nft.authorData[0]?.eth_wallet_address.slice(
                                  -5
                                )}
                            </a>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

function mapStateToProps(state: any) {
  return {
    getNFTResult: state.ItemDetailReducer || {},
    OrderResult: state.ItemDetailReducer || {},
    MessageResult: state.ItemDetailReducer || {},
  };
}
function mapDispatchToProps(dispatch: Function) {
  return {
    GetNFT: (tokenId: Number, callback: Function) => dispatch(GetNFT(tokenId, callback)),
    ProcessBuyNow: (payload: any, token: string, callback: Function) => dispatch(ProcessBuyNow(payload, token, callback)),
    RequestMessage: (payload: any, token: string, callback: Function) => dispatch(RequestMessage(payload, token, callback)),
  }
}
export const ItemDetailModule = withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(ItemDetailScreen)
);
export const ItemDetail = AppLayout(ItemDetailModule);