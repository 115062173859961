import React, { PureComponent } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { GetAllNFTForExplore, GetNFTbyCategory } from "../";
import AppLayout from "../../../applayout/applayout";
import NftCard from "../../../component/NftCard";
import SkeletonCard from "../../../component/SkeletonCard";
import "../../../component/style.scss";
import { NFTModal, UserDataModal } from "../../../modal";
interface Props {
  t?: any;
  history?: any;
  GetAllNFTForExplore?: any;
  GetNFTbyCategory?: any;
  getUserProfileData: Function;
  UpdateNFT?: any;
  userProfileData: UserDataModal;
  nftsList: any;
  nftsFilteredList: any;
  updateNFTResult?: any;
}
interface State {
  userId: string;
  username: string;
  fullName: string;
  nftsFilteredList: any;
  email: string;
  condition: string;
  twitterUsername: string;
  userProfileImage: string;
  country: string;
  updateNFTResult: any;
  accountAddress: string;
  isLoading: boolean;
  popupMessage: string;
  popupStatus: string;
  popupTitle: string;
  count: number;
  createNFTResult: any;
  phone: string;
  newImageSrc: string;
  nftsList: any;
  bio: string;
  extraDisplay: string;
  opacity?: string;
  site: string;
  instaUsername: string;
  category: string;
  filtercategory: string;
  subcategory: string;
  playingTokenId: number;
  playingTokenId2: number;
  playingTokenId3: number;
  collectedClass: string;
  createdClass: string;
  onsaleClass: string;
  searchText: string;
  showImage: string;
  loading: boolean;
  //phone: userData.phone, twitterUsername: userData.twitterUsername, instaUsername: userData.instaUsername, newImageSrc: userData.user_image
}
export class Explore1Screen extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      userId: "",
      username: "",
      country: "",
      searchText: "",
      updateNFTResult: {},
      fullName: "",
      email: "",
      condition: '',
      newImageSrc: "",
      isLoading: false,
      popupMessage: "",
      popupStatus: "loading",
      popupTitle: "Status",
      count: 50,
      extraDisplay: "",
      createNFTResult: {},
      opacity: "0",
      twitterUsername: "",
      phone: "",
      showImage: "",
      userProfileImage: "",
      accountAddress: "",
      nftsList: null,
      nftsFilteredList: null,
      bio: "",
      site: "",
      instaUsername: "",
      playingTokenId: -2,
      playingTokenId2: -2,
      playingTokenId3: -2,
      collectedClass: "active",
      createdClass: "",
      onsaleClass: "",
      //@ts-ignore
      category: String(props.match.params.category),
      filtercategory: "",
      subcategory: "",
      loading: true,
    };
  }
  async searchNFT(nftName: any) {
    var nftResult = await this.state.nftsList.filter((obj: any) => {
      if (String(obj.title).includes(nftName)) {
        return obj;
      }
    });
    this.setState({ nftsFilteredList: nftResult, count: 50 });
  }
  RenderNFTs(nft: NFTModal) {
    return <NftCard nft={nft} key={nft._id} />;
  }
  async filterCategory(selection: any) {
    console.log("selection", selection);

    (() => {
      switch (selection) {
        case "clothing-accessories":
          selection = "clothing-accessories";
          this.setState({
            category: "Clothing & Accessories",
            filtercategory: "clothing-accessories",
          });
          return;
        case "cosmetics":
          selection = "cosmetics";
          this.setState({
            category: "Cosmetics",
            filtercategory: "cosmetics",
          });
          return;
        case "health-sport":
          selection = "health-sport";
          this.setState({
            category: "Health & Sport",
            filtercategory: "health-sport",
          });
          return;
        case "electronics":
          selection = "electronics";
          this.setState({
            category: "Electronics",
            filtercategory: "electronics",
          });
          return;
        case "home-garden":
          selection = "home-garden";
          this.setState({
            category: "Home & Garden",
            filtercategory: "home-garden",
          });
          return;
        // case "collectible-art":
        //   selection = "collectible-art";
        //   this.setState({
        //     category: "Collectible & Art",
        //     filtercategory: "collectible-art",
        //   });
        //   return;
        case "Others":
          selection = "Others";
          this.setState({ category: "0", filtercategory: "All" });
          return;
        default:
          this.setState({ category: "All", filtercategory: "All" });
          return;
      }
    })();
  }

  async filter_all(clear: any) {
    this.setState({ loading: true });

    if (clear == false) {
      this.props.GetNFTbyCategory(
        { userId: this.state.userId, condition: this.state.condition, category: this.state.category },
        this.state.filtercategory,
        () => {
          this.setState({
            nftsList: this.props.nftsList,
            nftsFilteredList: this.props.nftsList,
            loading: false,
          });
          setTimeout(() => {
            //@ts-ignore
            window.LoadBuyNowLink();
            //@ts-ignore
            window.reloadImages();
            //@ts-ignore
            window.de_size();
          }, 600);
        }
      );
    } else {
      this.props.GetAllNFTForExplore({ userId: this.state.userId }, () => {
        this.setState({
          nftsList: this.props.nftsList,
          nftsFilteredList: this.props.nftsList,
          loading: false
        });
        setTimeout(() => {
          //@ts-ignore
          window.LoadBuyNowLink();
          //@ts-ignore
          window.reloadImages();
          //@ts-ignore
          window.de_size();
        }, 300);
      });
    }
  }

  async filterBuyNow(selection: any) {
    var nftResult = await this.state.nftsList.filter((obj: any) => {
      if (selection == obj.nftType) {
        return obj;
      } else if (selection == "All") {
        return obj;
      }
    });
    console.log(nftResult);
    this.setState({ nftsFilteredList: nftResult });
  }
  async filterAllitems(selection: any) {
    var nftResult = await this.state.nftsList.filter((obj: any) => {
      if (String(obj.title).includes(selection)) {
        return obj;
      }
    });
    this.setState({ nftsFilteredList: nftResult });
  }
  async componentDidMount() {
    let userId = await localStorage.getItem("userId");
    let fullName = await localStorage.getItem("full_name");
    let username = await localStorage.getItem("username");
    let userProfileImage = await localStorage.getItem("profile_image");
    let twitterUserName = await localStorage.getItem("twitter_user_name");
    let instaUsername = await localStorage.getItem("insta_user_name");
    this.setState({
      userId: String(userId),
      fullName: String(fullName),
      username: String(username),
      twitterUsername: String(twitterUserName),
      userProfileImage: String(userProfileImage),
      instaUsername: String(instaUsername),
    });
    let payload = {
      userId,
    };
    let Category_NFTs = "";

    console.log("this.state.category", this.state.category);

    if (this.state.category) {
      {
        (() => {
          switch (this.state.category) {
            case "clothing-accessories":
              Category_NFTs = "clothing-accessories";
              this.setState({ category: "Clothing & Accessories" });
              return;
            case "electronics":
              Category_NFTs = "electronics";
              this.setState({ category: "Electronics" });
              return;
            case "cosmetics":
              Category_NFTs = "cosmetics";
              this.setState({ category: "Cosmetics" });
              return;
            case "health-sport":
              Category_NFTs = "health-sport";
              this.setState({ category: "Health & Sport" });
              return;
            case "home-garden":
              Category_NFTs = "home-garden";
              this.setState({ category: "Home & Garden" });
              return;
            case "collectible-art":
              Category_NFTs = "collectible-art";
              this.setState({ category: "Collectible & Art" });
              return;
            case "Others":
              Category_NFTs = "Others";
              this.setState({ category: "All" });
              return;
            default:
              this.setState({ category: "All" });
              return;
          }
        })();
      }
      //Calling API to get ONLY filtered NFT by Category.
      if (Category_NFTs.length > 0) {
        this.props.GetNFTbyCategory(payload, Category_NFTs, () => {
          this.setState({
            nftsList: this.props.nftsList,
            nftsFilteredList: this.props.nftsList,
            loading: false,
          });
          setTimeout(() => {
            //@ts-ignore
            window.LoadBuyNowLink();
            //@ts-ignore
            window.reloadImages();
            //@ts-ignore
            window.de_size();
          }, 300);
        });
      } else {
        this.props.GetAllNFTForExplore(payload, () => {
          this.setState({
            nftsList: this.props.nftsList,
            nftsFilteredList: this.props.nftsList,
            loading: false,
          });
          setTimeout(() => {
            //@ts-ignore
            window.LoadBuyNowLink();
            //@ts-ignore
            window.reloadImages();
            //@ts-ignore
            window.de_size();
          }, 300);
        });
      }
    }
  }
  ShowErrorMessage(
    isShowLoading: boolean,
    message: string,
    status: string,
    transactionHash: any,
    title: string
  ) {
    try {
      this.setState({
        isLoading: isShowLoading,
        popupTitle: title,
        popupStatus: status,
        popupMessage: message,
      });
    } catch (ex) {
      console.log(ex);
    }
  }
  ShowIamge(isShowLoading: boolean, s: string) {
    try {
      this.setState({
        isLoading: isShowLoading,
        popupTitle: "Profile Image",
        showImage: s,
      });
    } catch (ex) {
      console.log(ex);
    }
  }
  handleFiledUpdate = (key: string, value: any) => {
    //@ts-ignore
    this.setState({ [key]: value });
  };
  loadMore() {
    console.log(this.state.nftsFilteredList.length, this.state.count);
    if (this.state.nftsFilteredList.length >= this.state.count) {
      var c = this.state.count + 50;
      this.setState({ count: c });
      setTimeout(() => {
        //@ts-ignore
        window.reloadImages();
        //@ts-ignore
        window.de_size();
      }, 300);
    } else {
      //@ts-ignore
      document.getElementById("loadBtn").style.display = "none";
    }
  }
  render() {
    return (
      <div className="container">
        <div className="row">
          <div className="col-12 col-lg-8">
            <h1>
              {this.state.category != "All" ? this.state.category : "Category"}
            </h1>
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <a href="/explore">All Products</a>
                </li>
                {this.state.category != "All" && (
                  <li className="breadcrumb-item">
                    <a href="#">{this.state.category}</a>
                  </li>
                )}

                {/* <li className="breadcrumb-item"><a href="#">Sub-category1</a></li>
                                <li className="breadcrumb-item active" aria-current="page">Sub-sub-category1</li> */}
              </ol>
            </nav>
            <div className="col-12 border d-none d-sm-block"></div>
          </div>
          <div className="mt-1"></div>
          <div className="col-12 col-lg-8 d-flex justify-content-end">
            <button
              className="btn btn-dark col-12 col-sm-2"
              type="button"
              data-bs-toggle="offcanvas"
              data-bs-target="#offcanvasScrolling"
              aria-controls="offcanvasScrolling"
            >
              Filters
            </button>
            <div
              className="offcanvas offcanvas-start"
              data-bs-scroll="true"
              data-bs-backdrop="false"
              id="offcanvasScrolling"
              aria-labelledby="offcanvasScrollingLabel"
            >
              <div className="offcanvas-header">
                <h5 className="offcanvas-title" id="offcanvasScrollingLabel">
                  Filters
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="offcanvas"
                  aria-label="Close"
                ></button>
              </div>
              <div className="offcanvas-body">
                <p>Find what you're searching for.</p>
                <div className="dropdown-center">
                  <button
                    className="btn btn-light dropdown-toggle w-100 rounded-0 border"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    {this.state.category}
                  </button>
                  <ul className="dropdown-menu w-100 text-center rounded-0">
                    <li>
                      <a
                        className="dropdown-item"
                        onClick={() => {
                          this.filterCategory("0");
                        }}
                      >
                        All
                      </a>
                    </li>
                    <li>
                      <a
                        className="dropdown-item"
                        onClick={() => {
                          this.filterCategory("cosmetics");
                        }}
                      >
                        Cosmetics
                      </a>
                    </li>
                    <li>
                      <a
                        className="dropdown-item"
                        onClick={() => {
                          this.filterCategory("clothing-accessories");
                        }}
                      >
                        Clothing & Accessories
                      </a>
                    </li>

                    {/* <li>
                      <a
                        className="dropdown-item"
                        onClick={() => {
                          this.filterCategory("collectible-art");
                        }}
                      >
                        Collectible & Art
                      </a>
                    </li> */}
                    <li>
                      <a
                        className="dropdown-item"
                        onClick={() => {
                          this.filterCategory("electronics");
                        }}
                      >
                        Electronics
                      </a>
                    </li>
                    <li>
                      <a
                        className="dropdown-item"
                        onClick={() => {
                          this.filterCategory("home-garden");
                        }}
                      >
                        Home & Garden
                      </a>
                    </li>
                    <li>
                      <a
                        className="dropdown-item"
                        onClick={() => {
                          this.filterCategory("health-sport");
                        }}
                      >
                        Health & Sport
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="dropdown-center">
                  <button
                    className="btn btn-light dropdown-toggle w-100 rounded-0 border-bottom border-start border-end"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    {this.state.condition == '' ? 'All' : this.state.condition}
                  </button>
                  <ul className="dropdown-menu w-100 text-center rounded-0">
                    <li>
                      <a className="dropdown-item" href="#" onClick={() => { this.setState({ condition: 'All' }) }}>
                        All
                      </a>
                    </li>
                    <li>
                      <a className="dropdown-item" href="#" onClick={() => { this.setState({ condition: 'New with tags' }) }}>
                        New with tags
                      </a>
                    </li>
                    <li>
                      <a className="dropdown-item" href="#" onClick={() => { this.setState({ condition: 'New without tags' }) }}>
                        New without tags
                      </a>
                    </li>
                    <li>
                      <a className="dropdown-item" href="#" onClick={() => { this.setState({ condition: 'Very good' }) }}>
                        Very good
                      </a>
                    </li>
                    <li>
                      <a className="dropdown-item" href="#" onClick={() => { this.setState({ condition: 'Good' }) }}>
                        Good
                      </a>
                    </li>
                    <li>
                      <a className="dropdown-item" href="#" onClick={() => { this.setState({ condition: 'Satisfactory' }) }}>
                        Satisfactory
                      </a>
                    </li>
                  </ul>
                </div>

                <div className="mt-4"></div>
                <button
                  data-bs-dismiss="offcanvas"
                  type="button"
                  onClick={() => {
                    this.filter_all(false);
                  }}
                  className="btn btn-dark w-100 text-center"
                >
                  Show results
                </button>
                <div className="mt-1"></div>
                <button
                  data-bs-dismiss="offcanvas"
                  type="button"
                  onClick={() => {
                    this.filter_all(true);
                  }}
                  className="btn btn-dark w-100 text-center"
                >
                  Clear Filters
                </button>
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-8">
            <div className="row">
              {this.state.loading == true ? (
                <SkeletonCard />
              ) : (
                <div className="row wow fadeIn animated">
                  {this.state.nftsFilteredList &&
                    this.state.nftsFilteredList.length > 0 ? (
                    this.state.nftsFilteredList.map(
                      (nft: NFTModal, index: any) => {
                        if (index < this.state.count) {
                          return this.RenderNFTs(nft);
                        }
                      }
                    )
                  ) : (
                    <>
                      <span>
                        No items in the category!{" "}
                        <span
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            window.location.href = "/explore";
                          }}
                        >
                          <u>
                            <b>Explore the marketplace</b>
                          </u>
                        </span>
                      </span>
                    </>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
function mapStateToProps(state: any) {
  return {
    nftsList: state.authorReducer || {},
    nftsFilteredList: state.authorReducer || {},
    userProfileData: state.userProfileReducer || [],
    updateNFTResult: state.UpdateNFTReducer || {},
  };
}
function mapDispatchToProps(dispatch: Function) {
  return {
    GetAllNFTForExplore: (payload: any, callback: Function) =>
      dispatch(GetAllNFTForExplore(payload, callback)),
    GetNFTbyCategory: (payload: any, Category_NFTs: any, callback: Function) =>
      dispatch(GetNFTbyCategory(payload, Category_NFTs, callback)),
  };
}
export const Explore1Module = withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(Explore1Screen)
);
export const Explore3 = AppLayout(Explore1Module);
