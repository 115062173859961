import React, { PureComponent } from "react";
import { connect } from "react-redux";
import AppLayout from "../../../applayout/applayout";
import { withTranslation } from "react-i18next";
import {
  GetNFTsByUser,
  GetLikedNFTsByUser,
  getUserProfileData,
  GetReviews,
  HideUserItem,
  DeleteUserItem
} from "../";
import { NFTModal, UserDataModal } from "../../../modal";
import web3Utills from '../../../utils/web3Utills';
import NftCardAuthor from "../../../component/NftCardAuthor";
interface Props {
  t?: any;
  history?: any;
  GetNFTsByUser?: any;
  GetLikedNFTsByUser: Function;
  getUserProfileData: Function;

  userProfileData: UserDataModal;
  nftsList: any;
  getReviews: Function;
  HideUserItem: Function;
  DeleteUserItem: Function;
  reviewslist: any;
}
interface State {
  userId: string;
  username: string;
  fullName: string;
  email: string;
  userProfileImage: string;
  country: string;
  updateNFTResult: any;
  accountAddress: string;
  imageClass: string;
  isLoading: boolean;
  popupMessage: string;
  popupStatus: string;
  popupTitle: string;
  createNFTResult: any;
  phone: string;
  newImageSrc: string;
  nftsList: any;
  bio: string;
  extraDisplay: string;
  public_visibility: boolean;
  opacity?: string;
  site: string;
  LikedNFTlist: any;
  reviewlists: any;
}
export class AuthorScreen extends PureComponent<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            userId: "",
            username: "",
            imageClass: "",
            country: "",
            updateNFTResult: {},
            fullName: "",
            email: "",
            newImageSrc: "",
            public_visibility: true,
            isLoading: false,
            popupMessage: "",
            popupStatus: "loading",
            popupTitle: "Status",
            extraDisplay: "",
            createNFTResult: {},
            opacity: "0",
            
            phone: "",
            userProfileImage: "",
            accountAddress: "",
            nftsList: null,
            bio: "",
            site: "",
            reviewlists : null,
          
            LikedNFTlist: null,
            
            
        };
       
    }

  
    async DeleteItem (product_id: any) {

        let user_id = this.state.userId;

        let payload = {
          user_id,
          product_id
        };

        this.props.DeleteUserItem(payload, (result:any) => {
            console.log(result);
        });

    }

    async HideItem (product_id:any) {

        let user_id = this.state.userId;

        let payload = {
          user_id,
          product_id,
        };

        this.props.HideUserItem(payload, ( result:any) => {
          console.log(result);
        });
    }

  async GetReviews(): Promise<void> {

    let user_id = this.state.userId;
    
    let payload = {
      user_id,
    };

     this.props.getReviews(payload, () => {
      

      this.setState({
        reviewlists: this.props.reviewslist.data,
      });
  })
}

    RenderNFTs(nft: NFTModal) {
        return (
          <NftCardAuthor nft={nft} key={nft._id} DeleteItem={this.DeleteItem.bind(this)} HideItem= {this.HideItem.bind(this)} />
        );
    }
    
    
    async getNftForUser(): Promise<void> {
        let userId = await localStorage.getItem("userId");
        let fullName = await localStorage.getItem("full_name");
        let username = await localStorage.getItem("username");
        let userProfileImage = await localStorage.getItem("profile_image");
      
        let bio = await localStorage.getItem("bio");
        let site = await localStorage.getItem("site");
        this.setState({
            userId: String(userId),
            fullName: String(fullName),
            username: String(username),
           
            userProfileImage: String(userProfileImage),
            bio: String(bio),
            site: String(site)
        });
        let payload = {
            userId
        }
        const userToken = await localStorage.getItem("accessToken");
        if (userToken) {
            this.props.getUserProfileData('', userToken, () => {
                let userData = this.props.userProfileData;
                this.setState({ fullName: userData.fullName, username: userData.username, email: userData.email, bio: userData.bio, site: userData.site, country: userData.country, phone: userData.phone,  newImageSrc: userData.user_image });
            });
        }
        else {
            window.location.href = "/connect-wallet";
        }
        this.props.GetNFTsByUser(payload, () => {
            this.setState({ nftsList: this.props.nftsList.created_nfts, LikedNFTlist: this.props.nftsList.liked_nfts });
           
        });
    
    }

    async componentDidMount() {
        try {
            let connected = await web3Utills.isEtheriumConnected();
            if (!connected) { window.location.href = "/connect-wallet"; return; }
        }
        catch (err) { console.log(err); this.ShowErrorMessage(true, `Please Switch to ${(process.env.REACT_APP_ETH_CHAIN_ID == '80001') ? "Polygon Mumbai network" : "Polygon Mumbai Test Network"} .` + "<br/>" + ` Learn more from <a href="https://sabuy.shop/help" target="_blank">Tutorial</a> .`, "failed", "", "Wrong Network") }
        
        await this.getNftForUser()
        await this.GetReviews();
       
        
    }


renderReviews(data: any) {
  let rating = data.review.rating;

;
   let content :  string[] = [];
    for (let i = 0; i < 5; i++) {
      //@ts-ignore
      content.push(<span className={rating >= 1 ? "selected" : ""}>★</span>);
    }
    return content;



}

    ShowErrorMessage(isShowLoading: boolean, message: string, status: string, transactionHash: any, title: string) {
        try {
            this.setState({
                isLoading: isShowLoading,
                popupTitle: title,
                popupStatus: status,
                popupMessage: message,
            });
        } catch (ex) {
            console.log(ex);
        }
    }
    async connect() {
        //@ts-ignore
        const eth = window.ethereum;
        // window.addEventListener('load', async () => {
        // Modern dapp browsers...
        if (eth) {
            try {
                // Request account access if needed
                //@ts-ignore
                await eth.enable();
                //@ts-ignore
                const acc = ethereum ? await ethereum.request({ method: 'eth_accounts' }) : [];
                window.localStorage.setItem('connectedAccount', acc[0])
            }
            catch (error) {
                //@ts-ignore
                window.alert(error.message)
                // User denied account access...
            }
        }
        else {
            window.location.href = "https://metamask.io/download";
            console.log('Non-Ethereum browser detected. You should consider trying MetaMask!');
        }
        // });
        if (eth) {
            console.log(eth);
            //@ts-ignore
            eth.on('accountsChanged', function (accounts) {
                console.log(accounts[0]);
                window.localStorage.setItem('connectedAccount', accounts[0])
                window.location.reload();
            });
            //@ts-ignore
            eth.on('connect', function (accounts) {
                window.localStorage.setItem('connectedAccount', accounts[0])
            });
        }
    }
 
    render() {
        return (
          <>
            <div className="container-fluid">
              <div className="row">
                <div className="mt-4"></div>
                <div className="col-0 col-lg-1"></div>
                <div className="col-12 col-lg-6">
                  <div className="container">
                    <div className="row">
                      <div className="col-4 col-sm-3">
                        <div
                          className="bg-secondary rounded-circle"
                          style={{ height: "100px", width: "100px" }}
                        >
                          <img
                            src={
                              this.state.newImageSrc &&
                              this.state.newImageSrc != ""
                                ? this.state.newImageSrc
                                : require("../../../assets/images/author_single/profile-placeholder.png")
                            }
                            className="rounded-circle"
                            style={{ height: "100px", width: "100px" }}
                          />
                        </div>
                      </div>
                      <div className="col-8 col-sm-9">
                        <div className="mt-1"></div>
                        <h1>{this.state.username}</h1>
                        <p>{this.state.country}</p>
                      </div>
                      <div className="mt-2"></div>
                      <div className="col-sm-12">
                        <p className="text-secondary">{this.state.bio}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="container-fluid">
                <div className="row">
                  <div className="col-0 col-lg-1"></div>
                  <div className="col-12 col-lg-8">
                    <div className="col-12 col-sm-2">
                      <a href="/profile">
                        <button type="button" className="btn btn-dark w-100">
                          Edit profile
                        </button>
                      </a>
                    </div>
                    <div className="mt-2"></div>
                    <ul className="nav nav-tabs" id="myTab" role="tablist">
                      <li className="nav-item" role="presentation">
                        <button
                          className="nav-link active"
                          id="home-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#home-tab-pane"
                          type="button"
                          role="tab"
                          aria-controls="home-tab-pane"
                          aria-selected="true"
                        >
                          Selling
                        </button>
                      </li>
                      <li className="nav-item" role="presentation">
                        <button
                          className="nav-link"
                          id="profile-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#profile-tab-pane"
                          type="button"
                          role="tab"
                          aria-controls="profile-tab-pane"
                          aria-selected="false"
                        >
                          Reviews
                        </button>
                      </li>
                    </ul>
                    <div className="tab-content" id="myTabContent">
                      {/* <!--Selling section begins here--> */}
                      <div
                        className="tab-pane fade show active"
                        id="home-tab-pane"
                        role="tabpanel"
                        aria-labelledby="home-tab"
                        tabIndex={0}
                      >
                        <div className="mt-1"></div>
                        {/* <!--Selling items begins here--> */}
                        <div className="row">
                          {this.state.nftsList &&
                          this.state.nftsList.length > 0 ? (
                            this.state.nftsList.map((nft: NFTModal) => {
                              return this.RenderNFTs(nft);
                            })
                          ) : (
                            <>
                              <span>
                                Why not sell your first product today?{" "}
                                <button
                                  style={{ marginLeft: 5 }}
                                  className="btn-main"
                                  onClick={() => {
                                    window.location.href = "/sell-now";
                                  }}
                                >
                                  Sell Now
                                </button>
                              </span>
                            </>
                          )}
                        </div>
                        {/* <!--Selling items ends here--> */}
                      </div>
                      {/* <!--Selling section ends here-->
                        <!--Reviews section beings here--> */}
                      <div
                        className="tab-pane fade"
                        id="profile-tab-pane"
                        role="tabpanel"
                        aria-labelledby="profile-tab"
                        tabIndex={0}
                      >
                        <div className="col-12">
                          {this.state.reviewlists?.length > 0 ? (
                            <ul className="list-group list-group-flush">
                              {this.state.reviewlists.map((review: any) => {
                                return (
                                  <li className="list-group-item">
                                    <div className="mt-1"></div>
                                    <div className="col-12">
                                      @
                                      {review.userData.length > 0
                                        ? review.userData[0].username
                                        : review.userData[0].eth_wallet_address}
                                      <br />
                                      {this.renderReviews(review)}
                                    </div>
                                    <div className="mt-1"></div>
                                    <div className="row">
                                      <div className="col-12 text-secondary">
                                        {review.review.comment + " "}
                                      </div>
                                    </div>
                                    <div className="mt-1"></div>
                                  </li>
                                );
                              })}
                            </ul>
                          ) : (
                            <div className="mt-3">No Reviews yet</div>
                          )}
                        </div>
                      </div>
                      {/* <!--Review section ends here--> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        );
    }
}
function mapStateToProps(state: any) {
    console.log(state);
    return {
      nftsList: state.authorReducer || {},
      LikedNFTlist: state.authorReducer || {},
      userProfileData: state.userProfileReducer || [],
      reviewslist: state.UserReducer || {},
    };
}
function mapDispatchToProps(dispatch: Function) {
    return {
      GetNFTsByUser: (payload: any, callback: Function) =>
        dispatch(GetNFTsByUser(payload, callback)),
      GetLikedNFTsByUser: (payload: any, callback: Function) =>
        dispatch(GetLikedNFTsByUser(payload, callback)),
      getUserProfileData: (payload: any, token: any, callback: Function) =>
        dispatch(getUserProfileData(payload, token, callback)),
      getReviews: (payload: any, callback: Function) =>
        dispatch(GetReviews(payload, callback)),
      HideUserItem: (payload: any, callback: Function) =>
        dispatch(HideUserItem(payload, callback)),
      DeleteUserItem: (payload: any, callback: Function) =>
        dispatch(DeleteUserItem(payload, callback)),
    };
}
export const AuthorModule = withTranslation()(
    connect(mapStateToProps, mapDispatchToProps)(AuthorScreen)
);
export const Author = AppLayout(AuthorModule);
