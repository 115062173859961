import {
  NovuProvider,
  PopoverNotificationCenter,
  NotificationBell,
  IMessage,
} from '@novu/notification-center';
import React, { memo } from 'react';

const NOVU_IDENTIFIER = process.env.REACT_APP_NOVU_IDENTIFIER;



export function NotificationCenter({ user_id }) {
  
  function onNotificationClick(message) {

    //console.error('message', message)
    // your logic to handle the notification click
    if (message?.cta?.data?.url) {
      window.location.href = message.cta.data.url;
    }
  }

  return (
    <NovuProvider
      subscriberId={user_id}
      applicationIdentifier={NOVU_IDENTIFIER}
    >
      <PopoverNotificationCenter
        onNotificationClick={onNotificationClick}
        colorScheme={"light"}
      >
        {({ unseenCount }) => (
          <NotificationBell
            unseenCount={unseenCount}
            unseenBadgeBackgroundColor={"#000"}
          />
        )}
      </PopoverNotificationCenter>
    </NovuProvider>
  );
}



export function NotificationMessageCenter({ user_id }) {
  
  function onNotificationClick(message) {

    //console.error('message', message)
    // your logic to handle the notification click
    if (message?.cta?.data?.url) {
      window.location.href = message.cta.data.url;
    }
  }

  return (
    <NovuProvider
      subscriberId={user_id}
      applicationIdentifier={NOVU_IDENTIFIER}
    >
      <PopoverNotificationCenter
        onNotificationClick={onNotificationClick}
        colorScheme={"light"}
      >
        {({ unseenCount }) => (
          <NotificationBell
            unseenCount={unseenCount}
            unseenBadgeBackgroundColor={"#000"}
          />
        )}
      </PopoverNotificationCenter>
    </NovuProvider>
  );
}

export default memo(NotificationCenter);