import { UserDataModal } from "../../modal";
import Utils from "../../utils";
export function userProfileReducer(
  state: UserDataModal = new UserDataModal(),
  action: any
) {

  switch (action.type) {
    case Utils.actionName.USER_PROFILE:
      return { ...state, ...action.payload.data };
    default:
      return state;
  }
}
