import Web3 from "web3";

var web3 = new Web3();

//@ts-ignore
if (window.ethereum) {
  //@ts-ignore
  web3 = new Web3(window.ethereum);
  //@ts-ignore
} else if (window.web3) {
  //@ts-ignore
  web3 = new Web3(window.web3.currentProvider);
}

export default web3;
