import React, { Component } from "react";
import Modal from "react-bootstrap/Modal";
import { Translation } from "react-i18next";

interface Props {
    onHide?: any;
    confirm?: Function;
    modalShow?: boolean;
    message: string;
    title: string;
    status: string;
    button?:string;
}

interface State { }

export class MessageLoadingModal extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {};
    }
    LogoutFunction = () => {
        localStorage.clear();
    };
    render() {
        return (
            <React.Fragment>
                <Translation>
                    {(t: Function) => (
                        <Modal
                            show={this.props.modalShow}
                            onHide={this.props.onHide}
                            aria-labelledby="contained-modal-title-vcenter"
                            centered
                            animation={false}
                            backdrop="static"
                        >
                            <Modal.Header
                                closeButton={false}
                                className="modal-header"
                            >
                                <Modal.Title className="modal-title">
                                    {this.props.title}
                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Body className="modal-body">
                                <div className="row">
                                    <div className="col-md-3 text-center">
                                        {this.props.status == "pending" ? (
                                            <figure className="postjob_modal_icon">
                                                <img
                                                    src={require("../../assets/images/ic_transaction_loading.svg")}
                                                    alt=""
                                                    className="src"
                                                />
                                            </figure>) : this.props.status == "failed" ? (
                                                <figure className="postjob_modal_icon">
                                                    <svg id="failureAnimation" className="animated" xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 70 70">
                                                        <circle id="failureAnimationCircle" cx="35" cy="35" r="24" stroke="#ff0000" strokeWidth="2" strokeLinecap="round" fill="transparent" />
                                                        <polyline className="failureAnimationCheckLine" stroke="#ff0000" strokeWidth="2" points="25,25 45,45" fill="transparent" />
                                                        <polyline className="failureAnimationCheckLine" stroke="#ff0000" strokeWidth="2" points="45,25 25,45" fill="transparent" />
                                                    </svg>
                                                </figure>) : (
                                            <figure className="postjob_modal_icon">
                                                <svg className="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30" width="30" height="30" ><circle className="checkmark__circle" cx="26" cy="26" r="25" fill="none" /><path className="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" /></svg>
                                            </figure>)}
                                    </div>
                                    <div className="col-md-9">
                                        <p dangerouslySetInnerHTML={{ __html: this.props.message }}  ></p>
                                    </div>
                                </div>
                                {(this.props.button!="" ) &&  
                                <div className="row">
                                    <div className="col-md-12">
                                        <button className="btn btn-default pull-right" onClick={this.props.onHide} >
                                            {this.props.button&&this.props.button!=""?this.props.button:t("cancel")}
                                        </button>
                                    </div>
                                </div>}
                               
                            </Modal.Body>
                        </Modal>
                    )}
                </Translation>
            </React.Fragment>
        );
    }
}