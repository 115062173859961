import React, { memo, useState, useEffect } from 'react';
import { notification_handler } from "../utils/common"

import LazyLoad from 'react-lazyload';

function NftCardHome({ nft, displayProfile = false, DisplayBuy = true }) {
    const [liked, setLiked] = useState(false);
    const [likedcount, UpdatedLikedCount] = useState(0);
    
  
    const AddtoFavorite = () => {
        setLiked((prevliked) => !prevliked);
        if (liked) UpdatedLikedCount(likedcount - 1);
        else UpdatedLikedCount(likedcount + 1);
        notification_handler(nft)
    };
    useEffect(() => {
        setLiked(nft.liked);
        if (nft.likes > 0) { UpdatedLikedCount(nft.likes) }
    }, []);
    return (
      <>
        <div className="col-6 col-sm-3 col-lg-2 gy-4">
          <div className="card product border-0">
            <div className="">
              <a href={"/item-details/" + nft.product_id}>
                {String(nft.filePath).toLowerCase().includes("gif") ? (
                  <LazyLoad>
                    <img
                      style={{}}
                      src={nft.filePath}
                      id={"img" + nft._id}
                      className="lazy nft__item_preview"
                      alt=""
                    />
                  </LazyLoad>
                ) : (
                  <LazyLoad>
                    <img
                      style={{}}
                      src={nft.thumbnailImageBuffer[0]}
                      id={"img" + nft._id}
                      className="lazy nft__item_preview"
                      alt=""
                    />
                  </LazyLoad>
                )}
              </a>
            </div>
            <div style={{ height: "10px" }}></div>
            <div className="container">
              <div className="row">
                <div className="col-12 text-start title">
                  {nft.status == "AVAILABLE" ? nft.price + " MATIC" : "Sold"}
                  <div className="likes">
                    <span
                      onClick={(event) => {
                        AddtoFavorite();
                      }}
                    >
                      <i
                        className={liked ? "fa fa-heart" : "fa fa-heart-o"}
                      ></i>{" "}
                      <span>{likedcount > 0 && likedcount}</span>
                    </span>
                  </div>
                </div>
                <div className="col-12 text-muted" style={{ fontSize: "12px" }}>
                  {nft.category}
                </div>
                <div className="col-12 fw-bolder" style={{ fontSize: "12px" }}>
                  {nft.title.length > 30
                    ? nft.title.slice(0, 30) + "..."
                    : nft.title}
                </div>
                <div className="col-12 text-muted" style={{ fontSize: "12px" }}>
                  Condition : {nft.condition}
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
};
export default memo(NftCardHome);
