import React, { PureComponent } from "react";
import { connect } from "react-redux";
import AppLayout from "../../../applayout/applayout";
import { withTranslation } from "react-i18next";
import { updateProfile } from '../profile.action';
import { getUserProfileData } from '../profile.action';
import { deleteProfile } from '../profile.action';
import { UserDataModal } from "../../../modal";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import { Modal } from "react-bootstrap";
import Utils from "../../../utils";
import web3Utills from '../../../utils/web3Utills';
interface Props {
    t?: any;
    history?: any;
    updateProfile: Function;
    getUserProfileData: Function;
    userProfileData: UserDataModal;
    deleteProfile: Function;
}
interface State {
    showaccount: boolean;
    account: string;
    userId: string;
    username: string;
    password: string;
    passwordConfirm: string;
    email: string;
    fullName: string;
    eT: any;
    country: string;
    city: string;
    phone: string;
    site: string;
    bio: string;
    twitterUsername: string;
    instaUsername: string;
    userProfileData: UserDataModal;
    newImageSrc: string;
    userProfileImage: string;
    fileName: string;
    isLoading: boolean;
    popupMessage: string;
    showDeleteModal: boolean;
    popupStatus: string;
    popupTitle: string;
    showCropModal: boolean;
    cropComplete?: any;
    crop: any;
    newImageFile: any;
    croppedImageUrl: any;
    error: {
        emailError: string;
        usernameError: string;
        fullNameError: string;
        countryError: string;
        phoneError: string;
        bioError: string;
        siteError: string;
        twitterUsernameError: string;
        instaUsernameError: string;
        passwordError: string;
        passwordConfirmError: string;
    };
}
export class ProfileScreen extends PureComponent<Props, State> {
    imageRef: any = "";
    fileUrl: any = "";
    constructor(props: Props) {
        super(props);
        this.state = {
            showaccount: true,
            account: "",
            userId: "",
            username: "",
            password: "",
            passwordConfirm: "",
            fullName: "",
            email: "",
            eT: null,
            site: "",
            bio: "",
            country: "",
            city: "",
            phone: "",
            twitterUsername: "",
            instaUsername: "",
            userProfileImage: "",
            userProfileData: new UserDataModal(),
            newImageSrc: "",
            fileName: "",
            isLoading: false,
            popupMessage: "",
            showDeleteModal: false,
            popupStatus: "loading",
            popupTitle: "Status",
            showCropModal: false,
            newImageFile: "",
            cropComplete: "",
            crop: {
                unit: "%",
                width: 100,
                // height:50
                aspect: 1 / 1
            },
            croppedImageUrl: "",
            error: {
                emailError: "",
                fullNameError: "",
                usernameError: "",
                bioError: "",
                siteError: "",
                countryError: "",
                phoneError: "",
                twitterUsernameError: "",
                instaUsernameError: "",
                passwordError: "",
                passwordConfirmError: ""
            },
        }
    }
    handleFiledUpdate = (key: string, value: any) => {
        //@ts-ignore
        this.setState({ [key]: value });
    };
    updatecountry = (country: string) => {
        //@ts-ignore
        this.setState({ country: country.label });
    };
    async componentDidMount() {
        const userToken = await localStorage.getItem("accessToken");
        let account_connected = await localStorage.getItem("connectedAccount");
        let connected = await web3Utills.isEtheriumConnected();

        if (userToken && connected) {
            this.props.getUserProfileData('', userToken, () => {
                let userData = this.props.userProfileData;

                //@ts-ignore
                this.setState({ fullName: userData.fullName, username: userData.username, country: userData.country, email: userData.email, bio: userData.bio, site: userData.site, phone: userData.phone, twitterUsername: userData.twitterUsername, instaUsername: userData.instaUsername, newImageSrc: userData.user_image, account: account_connected });
                this.setState({ userProfileData: userData });
                //this.updatecountry(userData.country)    
            });
        }
        else {
            window.location.href = "/connect-wallet";
        }
    }
    async imageFinal() {
        var e = this.state.eT
        console.log('in function', e.target.files[0])
        if (e.target.files && e.target.files.length > 0) {
            console.log('in if')
            let file = e.target.files[0];
            console.log(file)
            const reader: any = new FileReader();
            reader.addEventListener("load", () => {
                console.log('setting state', reader.result)
                this.setState({
                    newImageSrc: reader.result,
                }, () => {
                    console.log('in image final', this.state.newImageSrc)
                })
            }
            );
            this.setState({
                fileName: e.target.files[0].name
                    ? e.target.files[0].name
                    : "newImage.jpg",
            });
            reader.readAsDataURL(e.target.files[0]);
            e.target.value = "";
        }
    }

    async handleLogout() {
        //
        localStorage.clear();
        await web3Utills.SignwithMetamask();
        window.location.href = '/home'
    }
    saveImage = async (file: any) => {
        console.log(this.state.eT, "in save image")
        //this.imageFinal()
        // this.setState({
        //   loading: true,
        // });
        // await S3FileUpload.uploadFile(file, Utils.constant.amazonS3Config)
        //   .then((response: any) => {
        //     this.setState({
        //       pic: response.location,
        //       loading: false,
        //     });
        //   })
        //   .catch((err: any) => {
        //     alert("error in updating profilePic");
        //     this.setState({
        //       loading: false,
        //     });
        //     console.error(err, "##########");
        //   });
    };
    onImageLoaded = (image: any) => {
        console.log(image, '\n onimageloaded')
        this.imageRef = image;
    };
    image = (e: any) => {
        if (e.target.files && e.target.files.length > 0) {
            this.setState({ showCropModal: true, eT: e });
            const reader: any = new FileReader();
            reader.addEventListener("load", () =>
                this.setState({
                    newImageSrc: reader.result,
                })
            );
            this.setState({
                fileName: e.target.files[0].name
                    ? e.target.files[0].name
                    : "newImage.jpg",
            });
            reader.readAsDataURL(e.target.files[0]);
            e.target.value = "";
        }
    };
    onCropComplete = (crop: any) => {
        this.setState({ cropComplete: crop });
    };
    onCropChange = (crop: any, percentCrop?: any) => {
        // You could also use percentCrop:
        // this.setState({ crop: percentCrop });
        this.setState({ crop });
    };
    makeClientCrop = async (crop: any) => {
        console.log('crop ', crop)
        if (this.imageRef && crop.width && crop.height) {
            const croppedImageUrl = await this.getCroppedImg(
                this.imageRef,
                crop,
                this.state.fileName
            );
            if (this.state.newImageFile) {
                console.log('new image file', this.state.newImageFile, ' image ref', this.imageRef.src)
                // this.saveImage(this.state.newImageFile);
                const reader: any = new FileReader();
                reader.addEventListener("load", () => {
                    console.log('setting state', reader.result)
                    this.setState({
                        newImageSrc: reader.result.replace('application/octet-stream', 'image/jpeg'),
                    }, () => {
                        console.log('in image final', this.state.newImageSrc)
                    })
                }
                );
                reader.readAsDataURL(this.state.newImageFile);
                this.setState({
                    croppedImageUrl,
                    cropComplete: "",
                    showCropModal: false,
                    // newImageSrc:this.imageRef.src
                });
            }
        }
    };
    closeModel = () => {
        this.setState({
            showCropModal: false,
        });
    };
    saveCroppedImage = () => {
        if (this.state.cropComplete) {
            this.makeClientCrop(this.state.cropComplete);
        }
    };
    getCroppedImg = (image: any, crop: any, fileName: any) => {
        const canvas = document.createElement("canvas");
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        canvas.width = crop.width;
        canvas.height = crop.height;
        const ctx: any = canvas.getContext("2d");
        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width,
            crop.height
        );
        return new Promise((resolve, reject) => {
            canvas.toBlob((blob: any) => {
                if (!blob) {
                    //reject(new Error('Canvas is empty'));
                    console.error("Canvas is empty");
                    return;
                }
                blob.name = fileName + +new Date();
                if (this.fileUrl) window.URL.revokeObjectURL(this.fileUrl);
                this.fileUrl = window.URL.createObjectURL(blob);
                const newFile: any = new File([blob], blob.name);
                this.setState({ newImageFile: newFile });
                resolve(this.fileUrl);
            }, "image/jpeg");
        });
    };
    checkImageSize() {
        // var _URL = window.URL || window.webkitURL;
        // ////
        // const image = new Image();
        // image.addEventListener('load', () => {
        //     console.log(`${image.width}x${image.height}`)
        //     if(image.width>image.height/2){
        //          const reader: any = new FileReader();
        //     reader.addEventListener("load", () => {
        //         console.log('setting state',reader.result)
        //         this.setState({
        //             newImageSrc: reader.result,
        //         },()=>{
        //             console.log(this.state.newImageSrc)
        //         })
        //     }
        //     );
        //     this.setState({
        //         fileName: e.target.files[0].name
        //             ? e.target.files[0].name
        //             : "newImage.jpg",
        //     });
        //     reader.readAsDataURL(e.target.files[0]);
        //     e.target.value = "";
        //     }
        //     else{
        //         this.ShowErrorMessage(true, "Error uploading image.", "fail", "Error");
        //     }
        // });
        // image.src = URL.createObjectURL(file)
    }
    handleSubmit = async (event: any) => {

        
        //change button text to updating


        let { username, error, bio, country, city } = this.state;
        let formError: any;


        formError = Utils.constant.validateForm({ username, error, country });

        let isValid = Object.keys(formError).every((k) => formError[k] === "");
        this.setState({ error: Object.assign({}, formError) }, () => { });
        if (!isValid) {
            console.log('form error', formError)
            return false;
        } else {
            event.currentTarget.disabled = true;
        event.currentTarget.innerHTML = 'Updating...';
        event.preventDefault();

            this.ShowErrorMessage(true, "Please wait while your profile is being updated.", "pending", "Updating Profile");
            const token = await localStorage.getItem("fcmToken");
            const userToken = await localStorage.getItem("accessToken");
            let payload: any;
            payload = {
                username,
                bio,
                country,
                device_token: token,
                device_type: "3",
                user_type: Utils.constant.userType.recruiter,
                device_id: "device123",
                fcm_token: token,
                user_image: this.state.newImageSrc,
                fullName: this.state.fullName,
            };
            console.log('payload', payload)
            this.props.updateProfile(payload, (data: any) => {
                console.log(data.user_image);
                window.localStorage.setItem('username', data.username);
                window.localStorage.setItem('full_name', data.fullName);
                window.localStorage.setItem('profile_image', data.user_image);
                window.localStorage.setItem('twitter_user_name', data.twitterUsername);
                window.localStorage.setItem('bio', data.bio);
                window.localStorage.setItem('site', data.site);
                this.setState({ isLoading: false });
            });
        }
    };
    ShowErrorMessage(isShowLoading: boolean, message: string, status: string, title: string) {
        this.setState({
            isLoading: isShowLoading,
            popupTitle: title,
            popupStatus: status,
            popupMessage: message,
        });
    }
    showDeleteModal() {
        this.setState({ showDeleteModal: true });
    }
    closeDeleteModal() {
        this.setState({ showDeleteModal: false });
    }
    render() {
        return (
            <>
                <div className="mt-4"></div>
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-sm-11 col-lg-10">
                            <h1>Settings</h1>
                            <div className="border d-none d-sm-block"></div>
                        </div>
                    </div>
                    <div className="mt-2"></div>
                    <div className="row">
                        <div className="col-sm-12 col-lg-2 mb-4">
                            <div className="list-group">
                                <a href="#" onClick={() => { this.setState({ showaccount: true }) }} className="list-group-item list-group-item-action text-bg-light">Edit profile</a>
                                <a href="#" onClick={() => { this.setState({ showaccount: false }) }} className="list-group-item list-group-item-action">Account setting</a>
                            </div>
                        </div>
                        <div className="col-sm-12 col-lg-8">
                            {this.state.showaccount ? (
                                <div className="container border rounded">
                                    <div className="mt-2"></div>
                                    <div className="row">
                                        <div className="col-lg-2 col-sm-1">
                                            <div className="bg-secondary rounded-circle" style={{ height: '60px', width: "60px" }}>
                                                <img src={this.state.newImageSrc && this.state.newImageSrc != "" ? this.state.newImageSrc : require("../../../assets/images/author_single/profile-placeholder.png")} className="rounded-circle" style={{ height: '60px', width: '60px' }}
                                                    id="click_profile_img" alt="" />
                                                <input type="file" onChange={(e) => {
                                                    this.image(e);
                                                }} id="upload_profile_img" />
                                            </div>
                                        </div>
                                        <div className="col-lg-9 col-sm-11 d-flex align-items-center">
                                            <p>Change profile picture</p>
                                        </div>
                                    </div>
                                    <Modal
                                        show={this.state.showCropModal}
                                        onHide={this.closeModel}
                                        // size="lg"
                                        aria-labelledby="contained-modal-title-vcenter"
                                        centered
                                    >
                                        <Modal.Header closeButton >
                                            <Modal.Title>Profile Image</Modal.Title>
                                        </Modal.Header>
                                        <div className="modal_saveImage_btn text-center">
                                                <br/>
                                                <button
                                                    className="btn btn-dark w-50"
                                                    onClick={this.saveCroppedImage}
                                                >
                                                    Save Image
                                                </button>
                                        </div>
                                        <Modal.Body className="crop_image_modal">
                                            {this.state.newImageSrc && (
                                                <ReactCrop
                                                    className="center"
                                                    src={this.state.newImageSrc}
                                                    crop={this.state.crop}
                                                    ruleOfThirds
                                                    onImageLoaded={this.onImageLoaded}
                                                    onComplete={this.onCropComplete}
                                                    onChange={this.onCropChange}
                                                />
                                            )}
                                            
                                        </Modal.Body>
                                    </Modal>
                                    <div className="spacer-30"></div>
                                    <div className="mt-2"></div>
                                    <div className="mb-3">
                                        <label htmlFor="exampleFormControlInput1" className="form-label">Username</label>
                                        <br /><span className="text-danger">{this.state.error.usernameError}</span>
                                        <input type="email" className="form-control" id="exampleFormControlInput1" placeholder={"@username"} value={this.state.username}
                                            onChange={(e: any) => {
                                                this.handleFiledUpdate("username", e.currentTarget.value);
                                            }} />
                                    </div>
                                    <div className="mb-3">
                                        <label htmlFor="exampleFormControlTextarea1" className="form-label">Description</label>
                                        <textarea className="form-control" id="exampleFormControlTextarea1" placeholder="Tell the world about yourself" value={this.state.bio} rows={3}
                                            onChange={(e: any) => {
                                                this.handleFiledUpdate("bio", e.currentTarget.value);
                                            }}
                                        ></textarea>
                                    </div>
                                    <div className="mb-3">
                                        
                                        <div className="row">
                                            <div className="col-lg-6 col-xs-12 mb-3">
                                            <label htmlFor="exampleFormControlInput1" className="form-label">Country</label>
                                            <span className="text-danger">{this.state.error.countryError}</span>
                                            <select id="country" name="country"  onChange={(e: any) => {
                                                this.handleFiledUpdate("country", e.currentTarget.value);
                                            }} value={this.state.country} className="form-control">
                                                <option value={this.state.country}>{this.state.country}</option>
                                                <option value="Afganistan">Afghanistan</option>
                                                <option value="Albania">Albania</option>
                                                <option value="Algeria">Algeria</option>
                                                <option value="American Samoa">American Samoa</option>
                                                <option value="Andorra">Andorra</option>
                                                <option value="Angola">Angola</option>
                                                <option value="Anguilla">Anguilla</option>
                                                <option value="Antigua & Barbuda">Antigua & Barbuda</option>
                                                <option value="Argentina">Argentina</option>
                                                <option value="Armenia">Armenia</option>
                                                <option value="Aruba">Aruba</option>
                                                <option value="Australia">Australia</option>
                                                <option value="Austria">Austria</option>
                                                <option value="Azerbaijan">Azerbaijan</option>
                                                <option value="Bahamas">Bahamas</option>
                                                <option value="Bahrain">Bahrain</option>
                                                <option value="Bangladesh">Bangladesh</option>
                                                <option value="Barbados">Barbados</option>
                                                <option value="Belarus">Belarus</option>
                                                <option value="Belgium">Belgium</option>
                                                <option value="Belize">Belize</option>
                                                <option value="Benin">Benin</option>
                                                <option value="Bermuda">Bermuda</option>
                                                <option value="Bhutan">Bhutan</option>
                                                <option value="Bolivia">Bolivia</option>
                                                <option value="Bonaire">Bonaire</option>
                                                <option value="Bosnia & Herzegovina">Bosnia & Herzegovina</option>
                                                <option value="Botswana">Botswana</option>
                                                <option value="Brazil">Brazil</option>
                                                <option value="British Indian Ocean Ter">British Indian Ocean Ter</option>
                                                <option value="Brunei">Brunei</option>
                                                <option value="Bulgaria">Bulgaria</option>
                                                <option value="Burkina Faso">Burkina Faso</option>
                                                <option value="Burundi">Burundi</option>
                                                <option value="Cambodia">Cambodia</option>
                                                <option value="Cameroon">Cameroon</option>
                                                <option value="Canada">Canada</option>
                                                <option value="Canary Islands">Canary Islands</option>
                                                <option value="Cape Verde">Cape Verde</option>
                                                <option value="Cayman Islands">Cayman Islands</option>
                                                <option value="Central African Republic">Central African Republic</option>
                                                <option value="Chad">Chad</option>
                                                <option value="Channel Islands">Channel Islands</option>
                                                <option value="Chile">Chile</option>
                                                <option value="China">China</option>
                                                <option value="Christmas Island">Christmas Island</option>
                                                <option value="Cocos Island">Cocos Island</option>
                                                <option value="Colombia">Colombia</option>
                                                <option value="Comoros">Comoros</option>
                                                <option value="Congo">Congo</option>
                                                <option value="Cook Islands">Cook Islands</option>
                                                <option value="Costa Rica">Costa Rica</option>
                                                <option value="Cote DIvoire">Cote DIvoire</option>
                                                <option value="Croatia">Croatia</option>
                                                <option value="Cuba">Cuba</option>
                                                <option value="Curaco">Curacao</option>
                                                <option value="Cyprus">Cyprus</option>
                                                <option value="Czech Republic">Czech Republic</option>
                                                <option value="Denmark">Denmark</option>
                                                <option value="Djibouti">Djibouti</option>
                                                <option value="Dominica">Dominica</option>
                                                <option value="Dominican Republic">Dominican Republic</option>
                                                <option value="East Timor">East Timor</option>
                                                <option value="Ecuador">Ecuador</option>
                                                <option value="Egypt">Egypt</option>
                                                <option value="El Salvador">El Salvador</option>
                                                <option value="Equatorial Guinea">Equatorial Guinea</option>
                                                <option value="Eritrea">Eritrea</option>
                                                <option value="Estonia">Estonia</option>
                                                <option value="Ethiopia">Ethiopia</option>
                                                <option value="Falkland Islands">Falkland Islands</option>
                                                <option value="Faroe Islands">Faroe Islands</option>
                                                <option value="Fiji">Fiji</option>
                                                <option value="Finland">Finland</option>
                                                <option value="France">France</option>
                                                <option value="French Guiana">French Guiana</option>
                                                <option value="French Polynesia">French Polynesia</option>
                                                <option value="French Southern Ter">French Southern Ter</option>
                                                <option value="Gabon">Gabon</option>
                                                <option value="Gambia">Gambia</option>
                                                <option value="Georgia">Georgia</option>
                                                <option value="Germany">Germany</option>
                                                <option value="Ghana">Ghana</option>
                                                <option value="Gibraltar">Gibraltar</option>
                                                <option value="Great Britain">Great Britain</option>
                                                <option value="Greece">Greece</option>
                                                <option value="Greenland">Greenland</option>
                                                <option value="Grenada">Grenada</option>
                                                <option value="Guadeloupe">Guadeloupe</option>
                                                <option value="Guam">Guam</option>
                                                <option value="Guatemala">Guatemala</option>
                                                <option value="Guinea">Guinea</option>
                                                <option value="Guyana">Guyana</option>
                                                <option value="Haiti">Haiti</option>
                                                <option value="Hawaii">Hawaii</option>
                                                <option value="Honduras">Honduras</option>
                                                <option value="Hong Kong">Hong Kong</option>
                                                <option value="Hungary">Hungary</option>
                                                <option value="Iceland">Iceland</option>
                                                <option value="Indonesia">Indonesia</option>
                                                <option value="India">India</option>
                                                <option value="Iran">Iran</option>
                                                <option value="Iraq">Iraq</option>
                                                <option value="Ireland">Ireland</option>
                                                <option value="Isle of Man">Isle of Man</option>
                                                <option value="Israel">Israel</option>
                                                <option value="Italy">Italy</option>
                                                <option value="Jamaica">Jamaica</option>
                                                <option value="Japan">Japan</option>
                                                <option value="Jordan">Jordan</option>
                                                <option value="Kazakhstan">Kazakhstan</option>
                                                <option value="Kenya">Kenya</option>
                                                <option value="Kiribati">Kiribati</option>
                                                <option value="Korea North">Korea North</option>
                                                <option value="Korea Sout">Korea South</option>
                                                <option value="Kuwait">Kuwait</option>
                                                <option value="Kyrgyzstan">Kyrgyzstan</option>
                                                <option value="Laos">Laos</option>
                                                <option value="Latvia">Latvia</option>
                                                <option value="Lebanon">Lebanon</option>
                                                <option value="Lesotho">Lesotho</option>
                                                <option value="Liberia">Liberia</option>
                                                <option value="Libya">Libya</option>
                                                <option value="Liechtenstein">Liechtenstein</option>
                                                <option value="Lithuania">Lithuania</option>
                                                <option value="Luxembourg">Luxembourg</option>
                                                <option value="Macau">Macau</option>
                                                <option value="Macedonia">Macedonia</option>
                                                <option value="Madagascar">Madagascar</option>
                                                <option value="Malaysia">Malaysia</option>
                                                <option value="Malawi">Malawi</option>
                                                <option value="Maldives">Maldives</option>
                                                <option value="Mali">Mali</option>
                                                <option value="Malta">Malta</option>
                                                <option value="Marshall Islands">Marshall Islands</option>
                                                <option value="Martinique">Martinique</option>
                                                <option value="Mauritania">Mauritania</option>
                                                <option value="Mauritius">Mauritius</option>
                                                <option value="Mayotte">Mayotte</option>
                                                <option value="Mexico">Mexico</option>
                                                <option value="Midway Islands">Midway Islands</option>
                                                <option value="Moldova">Moldova</option>
                                                <option value="Monaco">Monaco</option>
                                                <option value="Mongolia">Mongolia</option>
                                                <option value="Montserrat">Montserrat</option>
                                                <option value="Morocco">Morocco</option>
                                                <option value="Mozambique">Mozambique</option>
                                                <option value="Myanmar">Myanmar</option>
                                                <option value="Nambia">Nambia</option>
                                                <option value="Nauru">Nauru</option>
                                                <option value="Nepal">Nepal</option>
                                                <option value="Netherland Antilles">Netherland Antilles</option>
                                                <option value="Netherlands">Netherlands (Holland, Europe)</option>
                                                <option value="Nevis">Nevis</option>
                                                <option value="New Caledonia">New Caledonia</option>
                                                <option value="New Zealand">New Zealand</option>
                                                <option value="Nicaragua">Nicaragua</option>
                                                <option value="Niger">Niger</option>
                                                <option value="Nigeria">Nigeria</option>
                                                <option value="Niue">Niue</option>
                                                <option value="Norfolk Island">Norfolk Island</option>
                                                <option value="Norway">Norway</option>
                                                <option value="Oman">Oman</option>
                                                <option value="Pakistan">Pakistan</option>
                                                <option value="Palau Island">Palau Island</option>
                                                <option value="Palestine">Palestine</option>
                                                <option value="Panama">Panama</option>
                                                <option value="Papua New Guinea">Papua New Guinea</option>
                                                <option value="Paraguay">Paraguay</option>
                                                <option value="Peru">Peru</option>
                                                <option value="Phillipines">Philippines</option>
                                                <option value="Pitcairn Island">Pitcairn Island</option>
                                                <option value="Poland">Poland</option>
                                                <option value="Portugal">Portugal</option>
                                                <option value="Puerto Rico">Puerto Rico</option>
                                                <option value="Qatar">Qatar</option>
                                                <option value="Republic of Montenegro">Republic of Montenegro</option>
                                                <option value="Republic of Serbia">Republic of Serbia</option>
                                                <option value="Reunion">Reunion</option>
                                                <option value="Romania">Romania</option>
                                                <option value="Russia">Russia</option>
                                                <option value="Rwanda">Rwanda</option>
                                                <option value="St Barthelemy">St Barthelemy</option>
                                                <option value="St Eustatius">St Eustatius</option>
                                                <option value="St Helena">St Helena</option>
                                                <option value="St Kitts-Nevis">St Kitts-Nevis</option>
                                                <option value="St Lucia">St Lucia</option>
                                                <option value="St Maarten">St Maarten</option>
                                                <option value="St Pierre & Miquelon">St Pierre & Miquelon</option>
                                                <option value="St Vincent & Grenadines">St Vincent & Grenadines</option>
                                                <option value="Saipan">Saipan</option>
                                                <option value="Samoa">Samoa</option>
                                                <option value="Samoa American">Samoa American</option>
                                                <option value="San Marino">San Marino</option>
                                                <option value="Sao Tome & Principe">Sao Tome & Principe</option>
                                                <option value="Saudi Arabia">Saudi Arabia</option>
                                                <option value="Senegal">Senegal</option>
                                                <option value="Seychelles">Seychelles</option>
                                                <option value="Sierra Leone">Sierra Leone</option>
                                                <option value="Singapore">Singapore</option>
                                                <option value="Slovakia">Slovakia</option>
                                                <option value="Slovenia">Slovenia</option>
                                                <option value="Solomon Islands">Solomon Islands</option>
                                                <option value="Somalia">Somalia</option>
                                                <option value="South Africa">South Africa</option>
                                                <option value="Spain">Spain</option>
                                                <option value="Sri Lanka">Sri Lanka</option>
                                                <option value="Sudan">Sudan</option>
                                                <option value="Suriname">Suriname</option>
                                                <option value="Swaziland">Swaziland</option>
                                                <option value="Sweden">Sweden</option>
                                                <option value="Switzerland">Switzerland</option>
                                                <option value="Syria">Syria</option>
                                                <option value="Tahiti">Tahiti</option>
                                                <option value="Taiwan">Taiwan</option>
                                                <option value="Tajikistan">Tajikistan</option>
                                                <option value="Tanzania">Tanzania</option>
                                                <option value="Thailand">Thailand</option>
                                                <option value="Togo">Togo</option>
                                                <option value="Tokelau">Tokelau</option>
                                                <option value="Tonga">Tonga</option>
                                                <option value="Trinidad & Tobago">Trinidad & Tobago</option>
                                                <option value="Tunisia">Tunisia</option>
                                                <option value="Turkey">Turkey</option>
                                                <option value="Turkmenistan">Turkmenistan</option>
                                                <option value="Turks & Caicos Is">Turks & Caicos Is</option>
                                                <option value="Tuvalu">Tuvalu</option>
                                                <option value="Uganda">Uganda</option>
                                                <option value="United Kingdom">United Kingdom</option>
                                                <option value="Ukraine">Ukraine</option>
                                                <option value="United Arab Erimates">United Arab Emirates</option>
                                                <option value="United States of America">United States of America</option>
                                                <option value="Uraguay">Uruguay</option>
                                                <option value="Uzbekistan">Uzbekistan</option>
                                                <option value="Vanuatu">Vanuatu</option>
                                                <option value="Vatican City State">Vatican City State</option>
                                                <option value="Venezuela">Venezuela</option>
                                                <option value="Vietnam">Vietnam</option>
                                                <option value="Virgin Islands (Brit)">Virgin Islands (Brit)</option>
                                                <option value="Virgin Islands (USA)">Virgin Islands (USA)</option>
                                                <option value="Wake Island">Wake Island</option>
                                                <option value="Wallis & Futana Is">Wallis & Futana Is</option>
                                                <option value="Yemen">Yemen</option>
                                                <option value="Zaire">Zaire</option>
                                                <option value="Zambia">Zambia</option>
                                                <option value="Zimbabwe">Zimbabwe</option>
                                            </select>
                                            </div>
                                            <div className="col-lg-6 col-xs-12 mb-3">
                                            <label htmlFor="exampleFormControlInput1" className="form-label">City</label>
                                            <input type="email" className="form-control" id="exampleFormControlInput1" placeholder='e.g. "Paris"' value={this.state.city}
                                                onChange={(e: any) => {
                                                    this.handleFiledUpdate("city", e.currentTarget.value);
                                                }} />
                                                </div>
                                        </div>
                                    </div>
                                    <div className="mt-1"></div>
                                    <button type="button" className="btn btn-dark w-100" onClick={(event) => { this.handleSubmit(event) }}>Update profile</button>
                                    <div className="mt-1"></div>
                                </div>
                            ) :
                                (
                                    <div className="container border rounded">
                                        <div className="mt-2"></div>


                                        <div className="row">
                                            <div className="col-2 col-lg-1">
                                                <img src="images/metamask-logo.svg" style={{ height: '50px', width: '50px' }} />
                                            </div>
                                            <div className="col-10">
                                                <p><span className="text-secondary">Metamask </span><span className="badge bg-success">Connected</span>
                                                    <br />{this.state.account.split('').slice(0, 6).join('') + '...' + this.state.account.split('').slice(-4).join('')}
                                                </p>
                                            </div>
                                        </div>

                                        <div className="mt-1"></div>

                                        <button type="button" onClick={() => { this.handleLogout() }} className="btn btn-outline-dark w-100">Change wallet</button>
                                        <div className="mt-1"></div>
                                    </div>
                                )
                            }
                        </div>
                    </div>
                </div>
                <div className="mt-4"></div>

            </>
        );
    }
}
function mapStateToProps(state: any) {
    return {
        userProfileData: state.userProfileReducer || [],
    };
}
function mapDispatchToProps(dispatch: Function) {
    return {
        updateProfile: (payload: any, callback: Function) => dispatch(updateProfile(payload, callback)),
        getUserProfileData: (payload: any, authToken: string, callback: Function) => dispatch(getUserProfileData(payload, authToken, callback)),
        deleteProfile: (payload: any, callback: Function) => dispatch(deleteProfile(payload, callback))
    }
}
export const ProfileModule = withTranslation()(
    connect(mapStateToProps, mapDispatchToProps)(ProfileScreen)
);
export const Profile = AppLayout(ProfileModule);
