import { NFTModal } from "../../modal";
import Utils from "../../utils";

export function GetNFT(tokenId: Number, callback: Function) {
  return (dispatch: Function, getState: Function) => {
    let hasConnection = Utils.constant.hasConnection();

    if (!hasConnection) {
      return false;
    }

    Utils.constant.handleOverlayLoader(true);

    let endPoint = Utils.endpoints.GetNFT + tokenId;

    let payload = {};

    Utils.api.getApiCall(
      endPoint,
      "",
      "",
      function (response: any) {
        if (response.status) {
          let data: NFTModal = response.data;

          dispatch({
            type: "ITEM_DETAILS_NFT",
            payload: { data },
          });

          callback();
        }
      },
      function (error: any) {
        if (error.status === 501) {
          Utils.constant.apiErrorMessage(error.data.message);
        }
      }
    );
  };
}

export function RequestMessage(
  payload: any,
  token: string,
  callback: Function
) {
  return (dispatch: Function, getState: Function) => {
    let hasConnection = Utils.constant.hasConnection();

    if (!hasConnection) {
      return false;
    }

    Utils.constant.handleOverlayLoader(true);

    let endPoint = "send-messages";

    Utils.api.postApiCallWithoutWebInUrl(
      endPoint,
      payload,
      token,
      function (response: any) {
        if (response.status) {
          let data = response.data;

          dispatch({
            type: "REQUEST_MESSAGE",
            payload: { data },
          });
          callback();
        }
      },
      function (error: any) {
        if (error.status === 501) {
          Utils.constant.apiErrorMessage(error.data.message);
        }
      }
    );
  };
}

export function ProcessBuyNow(
  payload: any,
  token: string,
  callback: Function
): (dispatch: Function, getState: Function) => false | undefined {
  return (dispatch: Function, getState: Function) => {
    let hasConnection = Utils.constant.hasConnection();

    if (!hasConnection) {
      return false;
    }

    Utils.constant.handleOverlayLoader(true);

    let endPoint = Utils.endpoints.Process_Order;

    Utils.api.postApiCallWithoutWebInUrl(
      endPoint,
      payload,
      token,
      function (response: any) {
        if (response.status) {
          let data: NFTModal = response.data;
          console.log(data);
          dispatch({
            type: Utils.actionName.PROCESS_ORDER,
            payload: { data },
          });
          callback();
        }
      },
      function (error: any) {
        if (error.status === 501) {
          Utils.constant.apiErrorMessage(error.data.message);
        }
      }
    );
  };
}
