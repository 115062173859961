
import Utils from "../../utils";
export function homeReducer(
  state: any = {},
  action: any
) {
  switch (action.type) {
    case Utils.actionName.Get_PopNFTs:
      return action.payload;
    case Utils.actionName.Get_PopMultiNFTs:
      return action.payload;
    case Utils.actionName.GET_POP_COLLECTIONS:
      return action.payload;
    default:
      return state;
  }
}
