import React, { PureComponent } from "react";
import { connect } from "react-redux";
import AppLayout from "../../../applayout/applayout";
import { withTranslation } from "react-i18next";
import { getTransactionData, getUserProfileData, UpdateOrder } from "..";
import { TransactionModal, UserDataModal } from "../../../modal";
import { MessageLoadingModal } from "../../../component/modal-popup/modal-popup";
import web3Utills from '../../../utils/web3Utills';
import  BuyerLayout  from './BuyerLayout'
import SellerLayout from './SellerLayout'
interface Props {
    t?: any;
    history?: any;
    getUserProfileData: Function;
    getTransactionData: Function;
    UpdateOrder: Function;
    TransactionList: any;
    UpdateStatus: any;
    userProfileData: any;
}
interface State {
    userId: string,
    username: string,
    fullName: string,
    email: string,
    twitterUsername: string,
    userProfileImage: string,
    country: string,
    accountAddress: string,
    imageClass: string;
    isLoading: boolean;
    popupMessage: string;
    popupButton: string;
    popupStatus: string;
    popupTitle: string;
    phone: string,
    newImageSrc: string,
    bio: string,
    extraDisplay: string;
    opacity?: string;
    site: string,
    instaUsername: string,
    showImage: string;
    TransactionList: any;
    selectedtransaction: any;
    //phone: userData.phone, twitterUsername: userData.twitterUsername, instaUsername: userData.instaUsername, newImageSrc: userData.user_image
}
export class AuthorTransactionScreen extends PureComponent<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            userId: "",
            username: "",
            imageClass: "",
            country: "",
            fullName: "",
            email: "",
            site: "",
            instaUsername: "",
            newImageSrc: "",
            isLoading: false,
            popupButton: '',
            popupMessage: "",
            showImage: "",
            popupStatus: "loading",
            popupTitle: "Status",
            extraDisplay: "",
            twitterUsername: "",
            phone: "",
            userProfileImage: "",
            accountAddress: "",
            bio: "",
            TransactionList: null,
            selectedtransaction: null
        };
    }
    async getNftForUser(): Promise<void> {
        let userId = await localStorage.getItem("userId");
        let accountAddress = await localStorage.getItem("connectedAccount");
        let fullName = await localStorage.getItem("full_name");
        let username = await localStorage.getItem("username");
        let userProfileImage = await localStorage.getItem("profile_image");
        this.setState({
            userId: String(userId),
            fullName: String(fullName),
            username: String(username),
            userProfileImage: String(userProfileImage),
            accountAddress: String(accountAddress)
        });
        let payload = {
            userId
        }
        const userToken = await localStorage.getItem("accessToken");
        if (userToken) {
            this.props.getUserProfileData('', userToken, () => {
                let userData = this.props.userProfileData;
                this.setState({ fullName: userData.fullName, username: userData.username, email: userData.email, bio: userData.bio, site: userData.site, country: userData.country, phone: userData.phone, twitterUsername: userData.twitterUsername, instaUsername: userData.instaUsername, newImageSrc: userData.user_image });
            });
            this.props.getTransactionData('', userToken, () => {
                console.log("called transaction log")
                console.log(this.props.TransactionList.data.length)
                let transactionlist = this.props.TransactionList.data
                this.setState({ TransactionList: transactionlist, isLoading: false, selectedtransaction: transactionlist[0] });
            });
        }
        else {
            window.location.href = "/connect-wallet";
        }
    }
    async componentDidMount() {
        try {
            let connected = await web3Utills.isEtheriumConnected();
            if (!connected) { window.location.href = "/connect-wallet"; return; }
        }
        catch (err) { console.log(err); this.ShowErrorMessage(true, `Please Switch to ${(process.env.REACT_APP_ETH_CHAIN_ID == '97') ? "BNBTestNet" : "Binance Smart Chain Network"} .` + "<br/>" + ` Learn more from <a href="https://bit.ly/divenci_tutorial" target="_blank">Tutorial</a> .`, "failed", "", "Wrong Network") }
        await this.getNftForUser()
    }
    

    // let saveData = {
    //     product_id: this.props.product.productData[0].product_id,
    //     product: this.props.product.productData[0]._id,
    //     buyer: this.props.product.buyer,
    //     seller: this.props.product.seller,
    //     reason: data.reason,
    //     query_type: 'CONTESTING'
    //   }
    


    //     let saveData = {
    //         product_id: 1013,
    //         tracking: 'xxx',
    //         tracking_url: 'xxx.com',
    //         seller: '639cdf205ce5ea190865cb81',
    //         query_type: 'SHPPING'
    //     }


    UpdateOrderShippingStatus = async (saveData: any) => {



        const userToken = await localStorage.getItem("accessToken");
        
        console.log("saveData", saveData);

        
        if (userToken) {
            this.ShowPopupMessage(true, "Updating order status.", "pending", "", "Update", '');

            this.props.UpdateOrder(saveData, userToken, () => {

                console.log("UpdateStatus", this.props.UpdateStatus)

                let UpdateStatus = this.props.UpdateStatus.data.data
                let UpdateMessage = this.props.UpdateStatus.data.message

                if(UpdateStatus) this.ShowPopupMessage(true, UpdateMessage, "", "", "Update", 'Close');
                else this.ShowPopupMessage(true, UpdateMessage, "failed", "", "Update", 'Close');
                
                // reload in 3 seconds
                setTimeout(() => {
                    window.location.reload();
                }, 3000);
            });
        }
        else {
            window.location.href = "/connect-wallet";
        }
    }




    renderBuyerSellerDetails() {
        if (this.state.selectedtransaction) {
            if (this.state.selectedtransaction.buyer == this.state.userId) {
                return <BuyerLayout product={this.state.selectedtransaction} UpdateOrderShippingStatus={this.UpdateOrderShippingStatus.bind(this)} key={this.state.selectedtransaction._id}  />
            }
            else {
                return <SellerLayout product={this.state.selectedtransaction} UpdateOrderShippingStatus={this.UpdateOrderShippingStatus.bind(this)} key={this.state.selectedtransaction._id} />
            }
        }
    }

    ShowPopupMessage(isShowLoading: boolean, message: string, status: string, transactionHash: any, title: string, buttom: string) {
        try {
            this.setState({
                isLoading: isShowLoading,
                popupTitle: title,
                popupStatus: status,
                popupMessage: message,
                popupButton: buttom,
            });
        } catch (ex) {
            console.log(ex);
        }
    }

    ShowErrorMessage(isShowLoading: boolean, message: string, status: string, transactionHash: any, title: string) {
        try {
            this.setState({
                isLoading: isShowLoading,
                popupTitle: title,
                popupStatus: status,
                popupMessage: message,
            });
        } catch (ex) {
            console.log(ex);
        }
    }
    render() {
        return (
            <>
                <div className="mt-4"></div>
                <div className="container">
                <MessageLoadingModal onHide={() => { this.setState({ isLoading: false }); return true; }} title={this.state.popupTitle} modalShow={this.state.isLoading} button={this.state.popupButton} message={this.state.popupMessage} status={this.state.popupStatus}></MessageLoadingModal>
                    <div className="row">
                        <div className="col-12 col-sm-11 col-lg-10">
                            <h1>Transactions</h1>
                            <div className="border d-none d-sm-block"></div>
                        </div>
                    </div>
                    <div className="mt-2"></div>
                    <div className="row">
                        <div className="col-12 col-sm-4 col-lg-2">
                            <div className="overflow-auto" style={{ height: "240px" }}>
                                <div className="list-group">
                                    {(this.state.TransactionList && this.state.TransactionList.length > 0) && (
                                        this.state.TransactionList.map((transaction: any) => {
                                            return (
                                                <a href="#" onClick={(e) => {
                                                    e.preventDefault();
                                                    this.setState({ selectedtransaction: transaction })
                                                }} className="list-group-item list-group-item-action text-bg-light transactionlist">
                                                    <div className="container" key={transaction._id}>
                                                        <div className="row">
                                                            <img src={transaction.productData[0].thumbnailImageBuffer[0]} className='col-3 col-sm-5 rounded' />
                                                            <div className="col-9 col-sm-7">
                                                                <p>{transaction.productData[0].title.slice(0, 12)}...
                                                                    <br />
                                                                    {/* Transaction status:
                                                            enum: ['SHIPPED', 'CONTESTED', 'PROCESSED', 'CANCELLED', 'REFUNDED', ''],
                                                            
                                                            type
                                                            buyer & seller 
                                                            */}
                                                                    {(this.state.userId == transaction.buyer) ? (
                                                                        (() => {
                                                                            switch (transaction.status) {
                                                                                case "CONTESTED": return <span className="badge text-bg-secondary"> Contested </span>;
                                                                                case "PROCESSED": return <span className="badge text-bg-success"> Bought </span>;
                                                                                case "CANCELLED": return <span className="badge text-bg-warning"> Failed </span>;
                                                                                default: return <span className="badge text-bg-secondary"> Buying </span>;
                                                                            }
                                                                        })()
                                                                    ) :
                                                                        (() => {
                                                                            switch (transaction.status) {
                                                                                case "CONTESTED": return <span className="badge text-bg-secondary"> Contested </span>;
                                                                                case "PROCESSED": return <span className="badge text-bg-success"> Sold </span>;
                                                                                case "CANCELLED": return <span className="badge text-bg-warning"> Failed </span>;
                                                                                default: return <span className="badge text-bg-secondary"> Selling </span>;
                                                                            }
                                                                        })()
                                                                    }
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </a>
                                            )
                                        }
                                        ))}
                                </div>
                            </div>
                        </div>
                        <div className="d-block d-sm-none" style={{ height: "10px" }}></div>
                        <div className="d-block d-sm-none" style={{ height: "10px" }}></div>
                        <div className="col-12 col-sm-7 col-lg-8">
                            <div className="container border rounded">
                                <div className="mt-1"></div>
                                <div className="vstack gap-3 text-center">
                                    <div>
                                        <div className="alert alert-primary" role="alert">
                                            Your transaction is secured by the <strong>Web3</strong> technology. <a href="/help" className="alert-link">Learn more</a>
                                        
       
                                        </div>
                                    </div>
                                    {(this.state.selectedtransaction) ? (
                                        this.renderBuyerSellerDetails()
                                    ) : ''}
                                </div>
                                <div className="mt-1"></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="mt-4"></div>
            </>
        );
    }
}
function mapStateToProps(state: any) {
    console.log(state);
    return {
        TransactionList: state.TransactionReducer || {},
        UpdateStatus: state.TransactionReducer || {},
        userProfileData: state.userProfileReducer || []
    };
}
function mapDispatchToProps(dispatch: Function) {
    return {
        getUserProfileData: (payload: UserDataModal, token: any, callback: Function) => dispatch(getUserProfileData(payload, token, callback)),
        getTransactionData: (payload: TransactionModal, token: any, callback: Function) => dispatch(getTransactionData(payload, token, callback)),
        UpdateOrder: (payload: any, token: any, callback: Function) => dispatch(UpdateOrder(payload, token, callback)),
    }
}
export const AuthorTransactionModule = withTranslation()(
    connect(mapStateToProps, mapDispatchToProps)(AuthorTransactionScreen)
);
export const AuthorTransaction = AppLayout(AuthorTransactionModule);
