import Utils from "../../utils";
import { NFTModal } from "../../modal";


export function GetAllMessage(message_id: number){
  
    let hasConnection = Utils.constant.hasConnection();

    var data= "";

    if (!hasConnection) {
      return false;
    }

    Utils.constant.handleOverlayLoader(true);

    let endPoint = "message/" + message_id;

    Utils.api.getApiCall(
      endPoint,
      '',
      '',
      function (response: any) {
        if (response.status) {

          data = response.data.data;
          console.log(data)
         
        }
      },
      function (error: any) {
        if (error.status === 501) {
          Utils.constant.apiErrorMessage(error.data.message);
        }
      }
    );
return data;

} 

export function GetMessages(userid: Number, callback: Function) {

  return (dispatch: Function, getState: Function) => {

    let hasConnection = Utils.constant.hasConnection();

    if (!hasConnection) {
      return false;
    }

    Utils.constant.handleOverlayLoader(true);

    let endPoint = "messages/" + userid;

    Utils.api.getApiCall(
      endPoint,
      '',
      '',
      function (response: any) {
        if (response.status) {

          let data: any = response.data.data;

          dispatch({
            type: "INBOX_LIST",
            payload: { data },
          });

          callback();
        }
      },
      function (error: any) {
        if (error.status === 501) {
          Utils.constant.apiErrorMessage(error.data.message);
        }
      }
    );
  };
}

