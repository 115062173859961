import React, { FunctionComponent } from "react";
//import { Right } from "react-bootstrap/lib/Media";
import { useTranslation } from "react-i18next";

interface Props { }
interface State { }

const Footer: FunctionComponent = () => {

    const { t, i18n } = useTranslation();

    return (
        <div className="container">
  <footer className="py-3 my-4">
    <ul className="nav justify-content-center border-bottom pb-3 mb-3">
      <li className="nav-item"><a href="/contact" className="nav-link px-2 text-body-secondary">Contact us</a></li>
      <li className="nav-item"><a href="/help" className="nav-link px-2 text-body-secondary">Help</a></li>
      <li className="nav-item"><a href="/terms" className="nav-link px-2 text-body-secondary">Terms</a></li>
      <li className="nav-item"><a href="/privacy" className="nav-link px-2 text-body-secondary">Privacy Policy</a></li>
    </ul>
    <p className="text-center text-body-secondary">SABUY &copy; 2023 Company, Inc</p>
  </footer>
</div>
    );
};

export default Footer;