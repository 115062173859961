import React, { PureComponent } from "react";
import AppLayout from "../../../applayout/applayout";

interface Props {}

interface State {}

export class TermsPageScreen extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
  }

  render() {
    return (
      <>
        <>
          <div className="container-fluid">
            <div className="row border bg-light" style={{ height: "200px" }}>
              <div className="d-flex flex-column justify-content-center align-items-center text-center">
                <div className="p-2">
                  <h1>TERMS OF USE</h1>
                  <p>Last updated April 25, 2023</p>
                </div>
              </div>
            </div>
          </div>
          <div style={{ height: "10px" }}></div>
          <div style={{ height: "10px" }}></div>
        </>
        <section>
          <div className="container-lg">
            <p>
              <strong>Welcome to Sabuy</strong>
            </p>
            <p>
              By using the Services, you agree to comply with these Terms of Use
              and all applicable laws and regulations. You are responsible for
              all activities that occur under your blockchain wallet.
            </p>
            <p>
              Sabuy is a web3 marketplace platform that connects buyers and
              sellers of various goods, including clothing and accessories,
              electronics, home and garden items, and collectibles and art.
            </p>
            <p>&nbsp;</p>
            <ol>
              <li>
                <strong>1. Delivery of Goods</strong>
              </li>
            </ol>
            <p>
              At Sabuy, buyers and sellers are responsible for arranging the
              delivery of the goods themselves. Sabuy offers a chat option for
              buyers and sellers to communicate with each other and make
              arrangements. It's easy to use and ensures that the delivery
              process is transparent and reliable.
            </p>
            <p>&nbsp;</p>
            <ol>
              <li>
                <strong>2. Connecting to Blockchain Wallet</strong>
              </li>
            </ol>
            <p>
              To use certain features of the Services, you may need to connect
              to your blockchain wallet, such as Metamask. You understand that
              Sabuy does not access your blockchain wallet or any information
              stored therein. We only require access to your wallet address in
              order to enable you to use certain features of our platform. To
              make any transaction in the blockchain, the blockchain wallet such
              as Metamask is required. You are responsible for any activities or
              actions under your blockchain wallet.
            </p>
            <p>&nbsp;</p>
            <ol>
              <li>
                <strong>3. Blockchain Escrow Services</strong>
              </li>
            </ol>
        
            <p>
              Sabuy offers Blockchain Escrow services to secure the payment:
              Online shopping is convenient, but it can also be risky.
              Fortunately, there is a solution: Blockchain Escrow services.
              These services eliminate the need for middlemen and provide a
              secure way to make payments. The buyer's payment is held in a
              secure account until they confirm the receipt of the product or
              service they paid for, giving both parties peace of mind.
            </p>
            <p>&nbsp;</p>
            <p>
              Moreover, Blockchain Escrow services ensure transparency and
              immutability of the transaction. This means that the transaction
              history cannot be altered or deleted, providing a transparent and
              trustworthy record of the transaction. With Blockchain Escrow
              services, online shopping can be safer and more reliable for
              everyone.
            </p>
            <p>
              To use Blockchain Escrow services, you will be required to confirm
              your transaction using your blockchain wallet. Once the
              transaction is confirmed, the funds will be held in escrow until
              the buyer confirms receipt of the goods or services.
            </p>
            <p>
              Sabuy.shop does not access your blockchain wallet or any
              information stored therein. We only require access to your wallet
              address to enable the use of Blockchain Escrow services.
            </p>
            <p>&nbsp;</p>
            <ol>
              <li>
                <strong>4. Intellectual Property</strong>
              </li>
            </ol>
        
            <p>
              All content on the Sabuy website, excluding user-generated
              content, including but not limited to text, graphics, logos,
              images, audio clips, and software, is the property of Sabuy or its
              content suppliers and is protected by applicable intellectual
              property laws. Users retain ownership of their own content posted
              on Sabuy. By posting content on Sabuy, users grant Sabuy a
              non-exclusive, royalty-free, perpetual, irrevocable, and fully
              sublicensable right to use, reproduce, modify, adapt, publish,
              translate, create derivative works from, distribute, and display
              such content throughout the world in any media. Users represent
              and warrant that they have all the rights necessary to grant such
              rights to Sabuy.
            </p>
            <p>&nbsp;</p>
            <ol>
              <li>
                <strong>5. Disclaimer of Warranties</strong>
              </li>
            </ol>
  
            <p>
              Sabuy provides the Services "as is" and without any warranty or
              condition, express, implied or statutory. Sabuy specifically
              disclaims any implied warranties of title, merchantability,
              fitness for a particular purpose and non-infringement.
            </p>
            <p>&nbsp;</p>
            <ol>
              <li>
                <strong>6. Limitation of Liability</strong>
              </li>
            </ol>
  
            <p>
              Sabuy shall not be liable for any direct, indirect, incidental,
              special, consequential, or exemplary damages, including but not
              limited to damages for loss of profits, goodwill, use, data, or
              other intangible losses resulting from your use of the Services or
              inability to use the Services.
            </p>
            <p>&nbsp;</p>
            <ol>
              <li>
                <strong>7. Indemnification</strong>
              </li>
            </ol>
        
            <p>
              You agree to indemnify, defend, and hold Sabuy and its affiliates,
              officers, directors, agents, and employees harmless from any claim
              or demand, including reasonable attorneys' fees, made by any third
              party due to or arising out of your use of the Services, your
              violation of these Terms of Use, or your violation of any rights
              of another.
            </p>
            <p>&nbsp;</p>
            <p>
              Thank you for bringing these additional sections to our attention.
              If you have any further questions or concerns, please do not
              hesitate to contact us.
            </p>
          </div>
        </section>
      </>
    );
  }
}

export const TERMS_PAGE = AppLayout(TermsPageScreen);
