import Utils from "../../utils";
import { SelectCategoryModel, NFTModal } from "../../modal";



export function getUserProfileData(
  payload: any,
  authToken: string,
  callback: Function = function () { }
) {
  return (dispatch: Function, getState: Function) => {
    let hasConnection = Utils.constant.hasConnection();
    if (!hasConnection) {
      return false;
    }

    Utils.constant.handleOverlayLoader(true);

    let endPoint = Utils.endpoints.getUserProfileData;

    console.log(endPoint);

    Utils.api.getApiCall(
      endPoint,
      payload,
      authToken,
      function (response: any) {
        if (response.status) {
          let data: any = response.data.data;

          dispatch({
            type: Utils.actionName.USER_PROFILE,
            payload: { data },
          });

          callback();
        }
      },
      function (error: any) {
        if (error.status === 501) {
          Utils.constant.apiErrorMessage(error.data.message);
        }
      }
    );
  };
}

export function getTransactionData(
  payload: any,
  authToken: string,
  callback: Function = function () { }
) {
  return (dispatch: Function, getState: Function) => {
    let hasConnection = Utils.constant.hasConnection();
    if (!hasConnection) {
      return false;
    }

    Utils.constant.handleOverlayLoader(true);

    let endPoint = Utils.endpoints.getUserTransactionData;

    console.log(endPoint);

    Utils.api.getApiCall(
      endPoint,
      payload,
      authToken,
      function (response: any) {
        if (response.status) {
          let data: any = response.data.data;
          //console.log(data)
          dispatch({
            type: Utils.actionName.GET_Transaction_BY_USER,
            payload: { data },
          }); 

          callback(data);
        }
      },
      function (error: any) {
        if (error.status === 501) {
          Utils.constant.apiErrorMessage(error.data.message);
        }
      }
    );
  };
}



export function UpdateOrder(
  payload: any,
  token: string,
  callback: Function = function () { }
  )
  {
return async (dispatch: Function, getState: Function) => {
  let hasConnection = Utils.constant.hasConnection();
  if (!hasConnection) {
    return false;
  }

  Utils.constant.handleOverlayLoader(true);



  let endPoint = Utils.endpoints.UpdateOrder;

  console.error(endPoint);

  Utils.api.postApiCallWithoutWebInUrl(
    endPoint,
    payload,
    token,
    function (response: any) {
      if (response.status) {
        let data: any = response.data;

        dispatch({
          type: Utils.actionName.UPDATE_ORDER,
          payload: { data },
        });

        callback();
      }
    },
    function (error: any) {
      if (error.status === 501) {
        Utils.constant.apiErrorMessage(error.data.message);
      }
    }
  );
};
}
