import React, { memo, useState, useEffect } from "react";
import { notification_handler } from "../utils/common";

function NftCardAuthor({ nft, HideItem, DeleteItem }) {
  const [liked, setLiked] = useState(false);
  const [likedcount, UpdatedLikedCount] = useState(0);
  const [showDeleteOverlay, setShowDeleteOverlay] = useState(false);

  const AddtoFavorite = () => {
    setLiked((prevliked) => !prevliked);
    if (liked) UpdatedLikedCount(likedcount - 1);
    else UpdatedLikedCount(likedcount + 1);
    notification_handler(nft);
  };

  const renderOverlayGroup = () => {
    if (nft.status == "AVAILABLE") {
      return (
        <>
          <div className="btn-group-vertical w-75">
            <a
              className="btn btn-dark mb-2"
              href={"/edit-item/" + nft._id}
            >
              Edit Item
            </a>
          </div>
        </>
      );
    } 
  };

  const handleDeleteClick = () => {
    DeleteItem(nft.product_id);
  };

  const handleHideClick = () => {
    HideItem(nft.product_id);
  };

  useEffect(() => {
    setLiked(nft.liked);
    if (nft.likes > 0) {
      UpdatedLikedCount(nft.likes);
    }
  }, []);
  return (
    <>
      <div
        className="col-6 col-sm-3 gy-4"
        onMouseEnter={() => setShowDeleteOverlay(true)}
        onMouseLeave={() => setShowDeleteOverlay(false)}
      >
        <div className="card product border-0">
          <div className="">
           
            <a href={"/item-details/" + nft.product_id}>
               {showDeleteOverlay && (
                <div
                  className="col-12"
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    backgroundColor: "rgba(255, 255, 255, 0.8)",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {renderOverlayGroup()}
                </div>
              )}
              {String(nft.filePath).toLowerCase().includes("gif") ? (
                <img
                  style={{}}
                  src={nft.filePath}
                  id={"img" + nft._id}
                  className="lazy nft__item_preview"
                  alt=""
                />
              ) : (
                <img
                  style={{}}
                  src={nft.thumbnailImageBuffer[0]}
                  id={"img" + nft._id}
                  className="lazy nft__item_preview"
                  alt=""
                />
                
              )}
            </a>
          </div>
          <div style={{ height: "10px" }}></div>
          <div className="container">
            <div className="row">
              <div className="col-12 text-start title">
                {nft.status == "AVAILABLE"
                  ? nft.price + " MATIC"
                  : nft.status == "SOlD"
                  ? "Sold"
                  : "Selling"}
                <div className="likes">
                  <span
                    onClick={(event) => {
                      AddtoFavorite();
                    }}
                  >
                    <i className={liked ? "fa fa-heart" : "fa fa-heart-o"}></i>{" "}
                    <span>{likedcount > 0 && likedcount}</span>
                  </span>
                </div>
              </div>
              <div className="col-12 text-muted" style={{ fontSize: "12px" }}>
                {nft.category}
              </div>
              <div className="col-12 text-muted" style={{ fontSize: "12px" }}>
                Condition :{nft.condition}
              </div>
              
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default memo(NftCardAuthor);
