import React, { Component } from "react";
import Modal from "react-bootstrap/Modal";
import { NavLink } from "react-router-dom";
import { Translation } from "react-i18next";

interface Props {
  onHide?: any;
  confirm?: Function;
  modalShow?: boolean;
}

interface State {}

export class LogoutConfirmationModal extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }
  LogoutFunction = () => {
    localStorage.clear(); window.location.href = '/home' 
  };
  render() {
    return (
      <React.Fragment>
        <Translation>
          {(t: Function) => (
            <Modal
              show={this.props.modalShow}
              onHide={this.props.onHide}
              animation={false}
              dialogClassName="modal-90w"
              aria-labelledby="contained-modal-title-vcenter"
              centered
            >
              <Modal.Header
                closeButton={true}
                className="border-bottom-0 padding-bottom-0"
              >
                <Modal.Title className="modal_header">
                  {t("sign_out")}
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <figure className="postjob_modal_icon">
                  {/* <img
                    src={require("../../assets/images/ic_postnew_job.svg")}
                    alt=""
                    className="src"
                  /> */}
                </figure>

                <p className="modal_para text-center">
                  {t("areyousureyouwanttologout")}
                </p>

                <div className="text-center">
                  <button
                    className="btn btn-default"
                    onClick={this.props.onHide}
                  >
                    {t("cancel")}
                  </button>
                  <NavLink to="/">
                    <button
                      className="btn btn-primary m-l-10 "
                      onClick={this.LogoutFunction}
                    >
                      {t("sign_out")}
                    </button>
                  </NavLink>
                </div>
              </Modal.Body>
            </Modal>
          )}
        </Translation>
      </React.Fragment>
    );
  }
}
