import React, { PureComponent } from "react";
import { connect } from "react-redux";
import "react-toastify/dist/ReactToastify.css";
import { doLogin } from "..";
import AuthLayout from "../../../applayout/authlayout";
import utils from "../../../utils";
import web3Utills from "../../../utils/web3Utills";

interface Props {
  t: any;
  history?: any;
  doLogin: Function;
  loginResponce?: any;
}

export class SigninScreen extends PureComponent<Props> {
  constructor(props: Props) {
    super(props);
  }
  async componentDidMount() {
    let walletconnected = await web3Utills.isEtheriumConnected();
    let isAuthenticated = await utils.constant.getAccessToken();
    if (walletconnected && isAuthenticated != null) window.location.href = "/";
  }

  handleSubmit = async () => {
    web3Utills.SignwithMetamask();
  };

  render() {
    return (
      <div id="wrapper">
        <div className="no-bottom" id="content">
          <div id="top"></div>

          <section className="full-height relative no-top no-bottom vertical-center bglogin">
            <div className="overlay-gradient t50">
              <div className="center-y relative">
                <div className="container">
                  <div className="row align-items-center">
                    <div
                      className="col-lg-5 wow fadeInRight"
                      data-wow-delay=".5s"
                    >
                      <div className="spacer-10"></div>
                      <h1>Create, sell or collect digital items.</h1>
                    </div>

                    <div
                      className="col-lg-5 offset-lg-1 wow fadeIn"
                      data-wow-delay=".5s"
                    >
                      <div
                        className="box-rounded padding40"
                        data-bgcolor="#ffffff"
                      >
                        <h3 className="mb10">Sign In</h3>

                        <div className="field-set">
                          <div className="col-lg-12 mb30">
                            <a
                              className="box-url"
                              href="#"
                              onClick={() => {
                                this.handleSubmit();
                              }}
                            >
                              <span className="box-url-label">
                                Most Popular
                              </span>
                              <img
                                src="images/wallet/1.png"
                                alt=""
                                className="mb20"
                              />
                              <h4>Metamask</h4>
                              <p>
                                Start exploring blockchain applications in
                                seconds. Trusted by over 1 million users
                                worldwide.
                              </p>
                            </a>
                          </div>
                        </div>

                        <div className="field-set text-center">
                          <>
                            <button
                              className="btn-main btn-wallet-login"
                              onClick={(e) => {
                                this.handleSubmit();
                              }}
                            >
                              <i className="icon_wallet_alt mr10"></i>
                              <span>Connect Wallet</span>
                            </button>
                          </>
                        </div>

                        <div className="clearfix"></div>

                        <div className="spacer-single"></div>

                        {/* <ul className="list s3">
                            <li>Login with:</li>
                            <li><a href="#">Facebook</a></li>
                            <li><a href="#">Google</a></li>
                          </ul> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    );
  }
}
function mapStateToProps(state: any) {
  return {
    loginResponce: state.authReducer,
  };
}
function mapDispatchToProps(dispatch: Function) {
  return {
    doLogin: (payload: any, callback: Function) =>
      dispatch(doLogin(payload, callback)),
  };
}

export const SigninModule = connect(mapStateToProps, mapDispatchToProps)(SigninScreen);

export const Signin = AuthLayout(SigninModule);
function handleSignMessage(address: any, nonce: any) {
  throw new Error("Function not implemented.");
}
