import Utils from "../../utils";
import { SelectCategoryModel, SelectCollectionModel, NFTModal } from "../../modal";

/***************************************Select Collection*********************************************/

export function CreateNFT(payload: FormData, token: string, callback: Function) {

  return (dispatch: Function, getState: Function) => {

    let hasConnection = Utils.constant.hasConnection();

    if (!hasConnection) {
      return false;
    }

    Utils.constant.handleOverlayLoader(true);

    let endPoint = Utils.endpoints.CreateItem;

    Utils.api.postMultipartFormData(
      endPoint,
      payload,
      token,
      function (response: any) {
        if (response.status) {

          let data: NFTModal = response.data.data;

          console.log(data);

          dispatch({
            type: Utils.actionName.CREATE_NFT,
            payload: { data },
          });

          callback();
        }
      },
      function (error: any) {
        if (error.status === 501) {
          Utils.constant.apiErrorMessage(error.data.message);
        }
      }
    );
  };
}


export function UpdateNFT(payload: NFTModal, token: string, callback: Function) {

  return (dispatch: Function, getState: Function) => {

    let hasConnection = Utils.constant.hasConnection();

    if (!hasConnection) {
      return false;
    }

    Utils.constant.handleOverlayLoader(true);

    let endPoint = Utils.endpoints.UpdateNFT;

    Utils.api.postApiCallWithoutWebInUrl(
      endPoint,
      payload,
      token,
      function (response: any) {
        if (response.status) {
          let data: NFTModal = response.data.data;
          console.log(data);
          dispatch({
            type: Utils.actionName.UPDATE_NFT,
            payload: { data },
          });
          callback();
        }
      },
      function (error: any) {
        if (error.status === 501) {
          Utils.constant.apiErrorMessage(error.data.message);
        }
      }
    );
  };
}


export function doStripePayment(payload: any, callback: Function = function () { }) {
  return (dispatch: Function, getState: Function) => {
    let hasConnection = Utils.constant.hasConnection();
    if (!hasConnection) {
      return false;
    }
    Utils.constant.handleOverlayLoader(true);
    let endPoint = Utils.endpoints.makestripepayment;
    payload.device_id = "device123";
    Utils.api.postApiCall(
      endPoint,
      payload,
      function (response: any) {
        if (
          response.status &&
          response.data &&
          response.data.code &&
          response.data.code === 200
        ) {
          let data: any = response.data.data;
          callback(data);
        } else if (response.status && response.data) {
          let data: any = response.data;
          let error = data.code && data.code != 200 ? data.message : "";
          error = error ? error : "Something went wrong";
          Utils.constant.apiErrorMessage(error);
          callback({ loginerror: true });
        }
      },
      function (error: any) {
        Utils.constant.apiErrorMessage(error.data.message);
        callback({ loginerror: true });
      }
    );
  };
}
