import React, { PureComponent } from "react";
import AppLayout from "../../../applayout/applayout";

interface Props {
   
}

interface State {
   
}



export class HelpPageScreen extends PureComponent<Props, State> {
    constructor(props: Props) {
        super(props);
        
       
    }
    

    async componentDidMount() {
        try {

           console.log("not found");
                 
        } catch (ex) {
            console.log(ex);
        }
    }



    render() {
        
        return (
           
            <><><div className="container-fluid">
                <div className="row border bg-light" style={{ height: "200px" }}>
                    <div className="d-flex flex-column justify-content-center align-items-center text-center">
                        <div className="p-2">
                            <h1>
                                Help Center


                            </h1>

                        </div>
                    </div>
                </div>
            </div>
                <div style={{ height: "10px" }}></div>
                <div style={{ height: "10px" }}></div></><section>
            
            <div className="container-lg">
            <h4 className="help-text"><strong>What is Sabuy ? </strong></h4>
<p className="help-text">Sabuy is a special online store where you can buy things safely and without any worries. It's like having a special lockbox for your money, so you can be sure that you'll get what you paid for. Sabuy uses the blockchain technology to make sure that everything is fair and transparent. The money you use to buy goods on Sabuy is called MATIC, a digital money that is used on the Polygon blockchain. This makes shopping on Sabuy. Shop even more secure and dependable! With Sabuy, you can shop with confidence and know that you're getting what you paid for.</p>
<p className="help-text">&nbsp;</p>
<h4 className="help-text"><strong>Welcome to Sabuy, the secure and private platform for buying and selling goods. Here's how to get started:</strong></h4>
<p className="help-text"><strong>Step 1</strong>: To get started on sabuy.shop, you'll need to download a reliable wallet application such as Metamask. If you plan on using your mobile device, simply install the Metamask mobile app. Alternatively, if you prefer to use your laptop, consider downloading the Metamask laptop version for optimal convenience</p>
<p className="help-text">This ensures that all transactions on Sabuy.shop are secure and private, and your data is not kept by the platform Sabuy</p>
<p className="help-text"><strong>Step 2</strong>:</p>
<ul className="list-group">
<li className="list-group-item">If you use your <strong>Mobile phone</strong>:
<p className="help-text">With the built-in browser in your Metamask wallet, accessing sabuy.shop for product listings or listing your own items for sale is incredibly easy. There's no need for an account, which means there's no hassle either. It's that simple! No account, no hassle !</p></li>
<li className="list-group-item">if you use your <strong>laptop</strong>:
<p className="help-text">To access sabuy.shop, open your preferred browser, such as Google Chrome, Edge, or Firefox. Once on the website, you'll be prompted by your browser to connect to Metamask, which is available in the laptop version. Simply accept the connection to proceed. From there, you can either view product listings to make a purchase or list your own items for sale.</p></li>
</ul>
<br/>
<p className="help-text"><strong>Step 3</strong>: When you find an item you want to purchase, simply make a payment through Sabuy's secure blockchain escrow system. The funds will be transferred to a &ldquo;neutral escrow account&rdquo; in the blockchain to ensure a safe transaction.</p>
<p className="help-text"><strong>Step 4</strong>: If you're a seller, you'll receive a notification when a purchase is made. Sabuy offers a chat option for buyers and sellers to connect with each other and arrange for delivery of the goods.</p>
<p className="help-text"><strong>Step 5</strong>: Once the buyer confirms the reception of the goods, the payment will be released to the seller.</p>
<p className="help-text">In the event that the buyer fails to confirm receipt of the goods, payment will be released to the seller 15 days after the payment date. The seller may communicate with the buyer via chat to prompt them to confirm receipt of the goods.</p>
<br/>
<h4 className="help-text"><strong>With Sabuy, you can buy and sell goods securely and privately without worrying about your personal data being tracked or compromised. Give it a try and start shopping today!</strong></h4>
<p className="help-text"><strong>1) Secure Transactions with Sabuy's Blockchain Escrow Payment System </strong></p>
<p className="help-text">Sabuy's payment system uses a blockchain escrow service that ensures secure and reliable transactions for both buyers and sellers: When a buyer makes a payment on Sabuy, the funds are transferred to a neutral escrow account in the blockchain. This means that the payment is held securely until the buyer receives the product and confirms delivery, giving both parties peace of mind.</p>
<p className="help-text"><strong>2) Dispute Resolution: Protecting Buyers and Sellers</strong></p>
<p className="help-text">Sabuy's blockchain escrow service also helps prevent disputes and fraud by ensuring transparency and accountability. If there is a dispute within 15 days of payment for example the goods are not delivered or the product is defective, the buyer can contact Sabuy to put the escrow payment on hold until the issue is resolved.</p>
<p className="help-text">In case of a dispute, the fund should either be returned to the buyer or released to the seller based on the final outcome</p>
<p className="help-text"><strong>3) Timely Delivery: Ensuring Seller Accountability</strong></p>
<p className="help-text">In order to ensure prompt delivery, Sabuy has established a timeframe for sellers to send their goods. After receiving payment, the seller has a maximum of 5 days to ship the items. If the seller is unable to provide tracking information within this timeframe (5 days), the buyer will receive &ldquo;a refund&rdquo; for their purchase. This policy helps to ensure a positive experience for both buyers and sellers and encourages timely and efficient transactions.</p>
<p className="help-text">This ensures that sellers are accountable and responsible for delivering goods on time.</p>
<p className="help-text">Overall, Sabuy's payment system is secure, reliable, and transparent, making it a safe and trustworthy platform for buying and selling goods online.</p>
<p className="help-text"><strong>Delivery of the Goods:</strong></p>
<p className="help-text">At Sabuy, buyers and sellers are responsible for arranging the delivery of the goods themselves. Sabuy offers a chat option for buyers and sellers to communicate with each other and make arrangements. It's easy to use and ensures that the delivery process is transparent and reliable.</p>
<p className="help-text"><strong>Notification of your transactions:</strong></p>
<p className="help-text">Sabuy believes in keeping things transparent and simple. Sellers receive a notification when a purchase is made, and buyers and sellers can easily connect with each other through Sabuy's chat option.</p>
<p className="help-text">Sabuy doesn't keep any of your data, ensuring the utmost privacy and security for all transactions on the platform. Trust us, with Sabuy, you can buy and sell goods with complete peace of mind.</p>
<p className="p2">&nbsp;</p>
<h3 className="help-text"><strong>Learn more:</strong></h3>
<p className="help-text"><strong>How Web3 Empowers You!</strong></p>
<p className="help-text">Web3 is also called the decentralized web, and it has a lot of benefits for people who like to shop online. These benefits make shopping easier and more fun!</p>
<p className="help-text"><strong>How Web3 Protects its Users Data and Privacy:</strong></p>
<p className="help-text">Web 3 is a new way of using the internet that makes it really easy and safe for people to buy and sell things without having to give away their personal information or create accounts with big companies like Google or Facebook. Instead, people just need to connect with their digital wallet, which is like a digital piggy bank where they keep their money and important information.</p>
<p className="help-text"><strong>Shop Smarter, Not Harder: How Web3 Saves You Money and Increases Security!</strong></p>
<p className="help-text">Web3 is a new way of shopping online that's built on a different kind of technology. With Web3, you can buy things directly from other people without having to use big companies like banks or payment processors. This makes it easier to shop online, saves you money and keeps your information more secure. Plus, you don't have to worry about third parties getting involved in your transactions. It's like having your own personal shopping network!</p>
<p className="help-text"><strong>Enhanced Security for Online Transactions:</strong></p>
<p className="help-text">Web3 is a new and exciting way of shopping online that uses advanced technology to keep your information safe and secure. With Web3, you can be sure that your personal and financial information is protected from theft or tampering. This is because Web3 uses something called blockchain technology, which creates a secure and tamper-proof record of your transactions. This makes your transactions transparent and your data immutable, so you can shop with confidence knowing that your information is safe!</p>
<p className="help-text"><strong>Transparency in Online Transactions:</strong></p>
<p className="help-text">With Web3, every transaction is recorded on a public ledger using blockchain technology. This means that you can easily view the transaction history of any product or service you're interested in. This ensures that you're getting exactly what you paid for and allows you to verify the authenticity of the product. With Web3, you can shop with confidence and transparency!</p>
<p className="help-text"><strong>How our Blockchain Escrow Services Can Make Online Shopping Safer and More Reliable:</strong></p>
<p className="help-text">Online shopping is convenient, but it can also be risky. Fortunately, there is a solution: Blockchain Escrow services. These services eliminate the need for middlemen and provide a secure way to make payments. The buyer's payment is held in a secure account until they confirm the receipt of the product or service they paid for, giving both parties peace of mind.</p>
<p className="help-text">Moreover, Blockchain Escrow services ensure transparency and immutability of the transaction. This means that the transaction history cannot be altered or deleted, providing a transparent and trustworthy record of the transaction. With Blockchain Escrow services, online shopping can be safer and more reliable for everyone.</p>
            
            </div>
                    
                </section></>


            
        );
    }
}



export const HELP_PAGE = AppLayout(HelpPageScreen);