import React from "react";
import Skeleton,  { SkeletonTheme } from "react-loading-skeleton";
import 'react-loading-skeleton/dist/skeleton.css'
const SkeletonCard = () => {
    return (

        
        
<SkeletonTheme baseColor="#fffcfc" highlightColor="#fcf6f6">
          {Array(8)
            .fill()
            .map((item, index) => (
                <div className='col-lg-3 col-md-6 col-sm-6 col-xs-12 mb-4' key={'SkeletonTheme'+ index} >
                <div className='skeleton_nft__item'>
                
                    
                    <div className="nft__item_info">
                        
                        <Skeleton height={250} />
                                     
                        <div className="">
                        <Skeleton height={20} />
                        </div>
    
                       {/*<div className="nft__item_like">
                        <span className="type"><i className="fa fa-image"></i>
                        </span> 
                        <span>
                            <i className="fa fa-heart"></i><span>{}</span>
                            </span>
                             
                        </div>   
            */}
                      
                    </div>
                
            </div>  
            </div>
           
           ))}
           </SkeletonTheme>
        
    );
  };
  export default SkeletonCard;
