import React, { Component, useState } from "react";
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'



export function EmailForm  ({SetEmailData, EmailStatus})  {

 const [buttonText, setButtonText] = useState(EmailStatus)
  
  const formSchema = Yup.object().shape({
    email: Yup.string()
        .required('Email is mandatory')
        .email(1, 'Not a proper email'),
    message: Yup.string()
        .required('Message is empty')
        .min(5,'Minimum character is 5')
        .max(300,'Maximum character count reached')
  }) 

  const formOptions = { resolver: yupResolver(formSchema) }
  const { register, handleSubmit, reset, formState } = useForm(formOptions)
  const { errors } = formState



  async function onSubmit(data) {
    
    SetEmailData(data)
  }

    return(
        <><div className="container-fluid">
            <div className="row border bg-light" style={{ height: "200px" }}>
                <div className="d-flex flex-column justify-content-center align-items-center text-center">
                    <div className="p-2">
                        <h1>Contact Us</h1>
                        <p>Feel free to ask questions</p>
                    </div>
                </div>
            </div>
        </div><div style={{ height: "10px" }}></div><div style={{ height: "10px" }}></div><section>
                <div className="container-md">
                    <div className="mx-auto col-10 col-md-8 col-lg-6">

                        <form className="form-signin" onSubmit={handleSubmit(onSubmit)}>
                            <div className="mb-3">
                                <label className='form-label'>Your email <span className='starspan'>*</span></label>
                                <input type="email"
                                 
                                    id="email"
                                    name="email"
                                    
                                    {...register('email')}
                                    className={`form-control ${errors.email ? 'is-invalid' : ''}`}    
                                   
                                    placeholder="Enter your e-mail" required autoFocus />
                                    <div className="invalid-feedback">{errors.email?.message}</div>
                            </div>
                            <div className="mb-3">
                                <div><label className='arealabel' htmlFor='textarea'>Message <span className='starspan'>*</span></label></div>
                                <div>
                                    <textarea 
                                      className={`form-control ${errors.message ? 'is-invalid' : ''}`}    
                                    placeholder="Message" 
                                    {...register('message')}
                                    id="textarea"
                                    rows={5}></textarea>
                                    <div className="invalid-feedback">{errors.message?.message}</div>
                                    </div>

                            </div>
                            <div className="mb-3">
                            {EmailStatus=='Send Message' ? <button className="btn btn-dark"  type="submit">{EmailStatus}</button>
                            : <button className="btn btn-dark" disabled type="submit">{EmailStatus}</button>} 
                                
                            </div>
                        </form>
                    </div>
                    <div className="col-lg-3 mb-3"></div>

                </div>


            </section></>
    )
  
}

export default EmailForm;