import React, { useState, memo, useContext } from "react";
import { LogoutConfirmationModal } from "../../component/confirmation-modal/confirmationmodal";
import { MetaMaskContext } from "../../hooks/metamask";
import NotificationCenter from "../../component/notificationscenter"
import { removeSession } from '../../utils/session';
interface Props {
    changeLanguage?: any;
}
export default memo(function Onboardingheader(props: Props) {
    const metamask = useContext(MetaMaskContext);
    const accessToken = localStorage.getItem("accessToken");
    const user_id = localStorage.getItem("userId");
    const [logoutConfirmation, setLogoutConfirmation] = useState(false);

    //@ts-ignore
    //console.error("accessToken", accessToken, metamask.currentaccount);
    //@ts-ignore
    const isconntected = accessToken && metamask.currentaccount != '';


    function handleLogout() {
        //
        //@ts-ignore
        metamask.disconnect();
        removeSession();
        window.location.href = '/home'
    }
    return (
        <>
            <nav className="navbar navbar-expand-lg bg-light sticky-top">
                <div className="container-fluid">
                    <a className="navbar-brand" href="/">SABUY</a>


                    <div className="d-flex flex-row-reverse align-items-center">


                        <button className="navbar-toggler border-0" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav1" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"></span>
                        </button>

                        {isconntected && (<>


                            <a href="/liked-items">

                                <button className="navbar-toggler border-0" type="button" data-bs-toggle="collapse" data-bs-target="#" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">

                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-heart" viewBox="0 0 16 16">
                                        <path d="m8 2.748-.717-.737C5.6.281 2.514.878 1.4 3.053c-.523 1.023-.641 2.5.314 4.385.92 1.815 2.834 3.989 6.286 6.357 3.452-2.368 5.365-4.542 6.286-6.357.955-1.886.838-3.362.314-4.385C13.486.878 10.4.28 8.717 2.01L8 2.748zM8 15C-7.333 4.868 3.279-3.04 7.824 1.143c.06.055.119.112.176.171a3.12 3.12 0 0 1 .176-.17C12.72-3.042 23.333 4.867 8 15z" />
                                    </svg>

                                </button>
                            </a>
                            <span className="navbar-toggler border-0" data-bs-toggle="collapse" data-bs-target="#" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                                <NotificationCenter user_id={user_id} />
                            </span>
                            <a href="/inbox">

                                <button className="navbar-toggler border-0" type="button" data-bs-toggle="collapse" data-bs-target="#" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-envelope" viewBox="0 0 16 16">
                                        <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4Zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1H2Zm13 2.383-4.708 2.825L15 11.105V5.383Zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741ZM1 11.105l4.708-2.897L1 5.383v5.722Z" />
                                    </svg>
                                </button>

                            </a>
                        </>)}
                    </div>


                    <div className="collapse col-12 text-center" id="navbarNav1">
                        <div style={{ height: "10px" }}></div>
                        <div style={{ height: "10px" }}></div>
                        <form className="d-flex w-100" role="search">
                            <input className="form-control me-2 text-center" type="search" placeholder='Search e.g. "winter jacket"' aria-label="Search" />
                        </form>
                        <ul className="navbar-nav">
                            <div style={{ height: "10px" }}></div>
                            <li className="nav-item">
                                <a className="nav-link" aria-current="page" href="/explore/clothing-accessories">Clothing & Accessories</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" aria-current="page" href="/explore/cosmetics">Cosmetics</a>
                            </li>
                            {/* <li className="nav-item">
                                <a className="nav-link" aria-current="page" href="/explore/collectible-art">Collectible & Art</a>
                            </li> */}
                            <li className="nav-item">
                                <a className="nav-link" aria-current="page" href="/explore/electronics">Electronics</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" aria-current="page" href="/explore/home-garden">Home & Garden</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" aria-current="page" href="/explore/health-sport">Health & Sport</a>
                            </li>

                            <li className="nav-item">
                                <a className="nav-link" aria-current="page" href="/help">Learn more</a>
                            </li>
                            <div style={{ height: "10px" }}></div>
                            <div style={{ height: "10px" }}></div>
                            <div style={{ height: "10px" }}></div>
                            <div style={{ height: "10px" }}></div>
                            {
                                isconntected ? (<>


                                    <li className="nav-item">
                                        <a className="nav-link" aria-current="page" href="/author">View my profile</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" href="/profile">Edit my account</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" href="/my-transactions">Transactions</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" onClick={() => { handleLogout() }}>Logout</a>
                                    </li></>) : (<>
                                        {/* Not logged */}
                                        <li className="nav-item dropdown mt-1">
                                            <a href="connect-wallet"><button type="button" className="btn btn-outline-dark w-100">Connect a wallet</button></a>
                                            <div style={{ height: "10px" }}></div>
                                        </li>
                                        <li className="nav-item dropdown">
                                            <a href="/sell-now"><button type="button" className="btn btn-dark w-100">Sell now</button></a>
                                            <div style={{ height: "10px" }}></div>
                                        </li></>)
                            }
                            <div style={{ height: "10px" }}></div>
                        </ul>
                    </div>
                    {/* Desktop version */}
                    <div className="navbar-collapse d-none d-lg-block d-xl-none" id="navbarNav1">
                        <ul className="navbar-nav">
                            <li className="nav-item">
                                <form className="d-flex" role="search">
                                    <input className="form-control me-2" type="search" placeholder="Search" aria-label="Search" />
                                </form>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" aria-current="page" href="/explore/clothing-accessories">Clothing & Accessories</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" aria-current="page" href="/explore/cosmetics">Cosmetics</a>
                            </li>
                            {/* <li className="nav-item">
                                <a className="nav-link" aria-current="page" href="/explore/collectible-art">Collectible & Art</a>
                            </li> */}
                            <li className="nav-item">
                                <a className="nav-link" aria-current="page" href="/explore/electronics">Electronics</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" aria-current="page" href="/explore/home-garden">Home & Garden</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" aria-current="page" href="/explore/health-sport">Health & Sport</a>
                            </li>

                            <li className="nav-item">
                                <a className="nav-link" aria-current="page" href="/help">Learn more</a>
                            </li>
                        </ul>
                    </div>
                    {
                        isconntected ? (<>
                            <div className="navbar-collapse d-none d-lg-block d-xl-none justify-content-end" id="navbarNav1">
                                <ul className="navbar-nav d-flex align-items-center">
                                    <li className="nav-item">
                                        <a className="nav-link active" aria-current="page" href="/inbox">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-envelope" viewBox="0 0 16 16">
                                                <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4Zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1H2Zm13 2.383-4.708 2.825L15 11.105V5.383Zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741ZM1 11.105l4.708-2.897L1 5.383v5.722Z" />
                                            </svg>
                                        </a>
                                    </li>
                                    <li className="nav-item">
                                        <NotificationCenter user_id={user_id} />

                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link active" aria-current="page" href="/liked-items">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-heart" viewBox="0 0 16 16">
                                                <path d="m8 2.748-.717-.737C5.6.281 2.514.878 1.4 3.053c-.523 1.023-.641 2.5.314 4.385.92 1.815 2.834 3.989 6.286 6.357 3.452-2.368 5.365-4.542 6.286-6.357.955-1.886.838-3.362.314-4.385C13.486.878 10.4.28 8.717 2.01L8 2.748zM8 15C-7.333 4.868 3.279-3.04 7.824 1.143c.06.055.119.112.176.171a3.12 3.12 0 0 1 .176-.17C12.72-3.042 23.333 4.867 8 15z" />
                                            </svg>
                                        </a>
                                    </li>
                                    <li className="nav-item dropdown">
                                        <a className="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                            My account
                                        </a>
                                        <ul className="dropdown-menu dropdown-menu-end text-center">
                                            <li><a className="dropdown-item" href="/author">View my profile</a></li>
                                            <li><a className="dropdown-item" href="/profile">Edit my account</a></li>
                                            <li><a className="dropdown-item" href="/my-transactions">Transactions</a></li>
                                            <li><a className="dropdown-item" href="#" onClick={() => { handleLogout() }}>Logout</a></li>
                                        </ul>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link active" aria-current="page" href="/sell-now">
                                            <button type="button" className="btn btn-dark text-center">Sell now</button>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </>) : (<>
                            {/* Not logged */}
                            <div className="navbar-collapse d-none d-lg-block d-xl-none justify-content-end" id="navbarNav1">
                                <ul className="navbar-nav">
                                    <li className="nav-item dropdown">
                                        <a href="/connect-wallet" style={{ paddingRight: "5px" }}><button className="btn btn-outline-dark lg-mr-1">Connect a wallet</button></a>
                                        <a href="/sell-now" className="pl-1"><button type="button" className="btn btn-dark">Sell now</button></a>
                                    </li>
                                </ul>
                            </div></>
                        )
                    }
                </div>
            </nav>
            <LogoutConfirmationModal
                confirm={handleLogout}
                modalShow={logoutConfirmation}
                onHide={() => {
                    setLogoutConfirmation(false);
                }}
            />
        </>
    );
});
