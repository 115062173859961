import Utils from "../utils";
import { message_notification } from "../utils/common";
import { sabuyContract, AlchemyProvider } from "./contractWeb3";
import web3 from "./web3";

//@ts-ignore
const ethereum = window.ethereum;
const multiplicationFactor = 1.2;

const gasLimit = 1500000;
const extraGas = 1000000;

const payOrder = async function (
  product_id: Number,
  vendor: String,
  qty: Number,
  price: Number
) {
  //interact.Check();

  let web3status = { status: false, message: "" };

  
  let accountAddress = await getCurrentAccount();
  let priceBN = String(toWei(String(price)));
  
  let product_qty = web3.utils.toWei(String(qty));

  console.log("accountAddress " + accountAddress);
  console.log("vendor " + vendor);
  console.log("product_id " + product_id);
  console.log("priceBN" + priceBN);


  let gasPrice = await AlchemyProvider.core.getGasPrice();
  
  console.log("gasPrice rec:");
  console.log("gasPrice rec:" + gasPrice);

  await sabuyContract.methods
    .payOrder(product_id, vendor, product_qty, priceBN)
    .send({
      from: accountAddress,
      gasLimit: gasLimit,
      gasPrice: gasPrice.add(extraGas),
      value: priceBN,
    })
    // .once('transactionHash', function(hash:any){  })
    // .once('receipt', function(receipt:any){  })
    // .on('confirmation', function(confNumber:any, receipt:any){  })
    // .on('error', function(error:any){  })
    .then(function (receipt: any) {
      // will be fired once the receipt is mined
      console.log(receipt);
      web3status.status = receipt.status;
      web3status.message = "Transaction successful";
      return web3status;
    })
    .catch((errors: any) => {
      if (errors.code == 4001)
        web3status.message = "User rejected the transaction!";
      if (errors.code == -32603)
        web3status.message = "Unexpected error occurred!";

      return web3status;
    });

  return web3status;
};

const isContested = async function (product_id: Number) {
  let iscontested = await sabuyContract.methods.isContested(product_id).call();

  return iscontested;
};

const sabuyContractTest = async function name(product_id: number) {
  console.log("product_id" + product_id);

  let accountAddress = await getCurrentAccount();
  console.log("accountAddress " + accountAddress);

  let buyer = await sabuyContract.methods.getBuyer(product_id).call();
  console.log("buyer " + buyer);

  let vendor = await sabuyContract.methods.getVendor(product_id).call();
  console.log("vendor " + vendor);

  let isPaid = await sabuyContract.methods.isPaid(product_id).call();
  console.log("isPaid " + isPaid);

  let isContested = await sabuyContract.methods.isContested(product_id).call();
  console.log("isContested " + isContested);

  let isTracking = await sabuyContract.methods.isTracking(product_id).call();
  console.log("isTracking " + isTracking);

  let getPlatformAddress = await sabuyContract.methods
    .getPlatformAddress()
    .call();
  console.log("getPlatformAddress " + getPlatformAddress);

  let getCommission = await sabuyContract.methods.getCommission().call();
  console.log("getCommission " + getCommission);
};

const setTracking = async function (product_id: Number) {
  let web3status = { status: false, message: "" };
  let accountAddress = await getCurrentAccount();

  await sabuyContract.methods
    .setTracking(product_id)
    .send({ from: accountAddress, gas: "5000000", gasPrice: "2000000000" })
    .then(function (receipt: any) {
      // will be fired once the receipt is mined
      console.log(receipt);
      web3status.status = receipt.status;
      web3status.message = "Added Tracking info.";
      return web3status;
    })
    .catch((errors3: any) => {
      console.log("3", errors3);

      web3status.status = false;
      web3status.message = errors3.message;
      return web3status;
    });

  return web3status;
};

const getOrderSimple = async function (product_id: Number) {
  //get getOrderSimple
  let order = await sabuyContract.methods.getOrderSimple(product_id).call();
  return order;
};


export const contract_getCommission = async () => {
  const message = await sabuyContract.methods.getCommission().call();
  return message;
};

const getCurrentAccount = async function () {
  try {
    //@ts-ignore
    const connectedAccounts = ethereum
      ? await ethereum.request({ method: "eth_accounts" })
      : [];
    if (connectedAccounts.length > 0) {
      return connectedAccounts[0];
    }
  } catch (err) {
    console.log(err);
  }
  return "";
};
const getSignagnature = async function (address: any, nonce: any) {
  console.log("getSignagnature " + address);
  let msg = `You are signing to Sabuy.shop. Authentification code is: 0x${web3.utils.toHex(
    nonce
  )}`;

  
  try {
    //@ts-ignore
    const sign = await ethereum.request({
      method: "personal_sign",
      params: [msg, address,]
    });
    return sign;
    // //@ts-ignore
    // await ethereum.personal.sign(
    // //@ts-ignore
    //     `I am signing my one-time nonce: ${nonce}`,
    //     address,
    //     (err: any, signature: any) => {
    //       if (err)  return(false);
    //       else return signature;
    //     }
    //   );
  } catch (err) {
    console.log(err);
  }
  return false;
};
const isCorrectEtheriumNetwork = async function () {
  const chainId = parseInt(String(process.env.REACT_APP_ETH_CHAIN_ID));
  try {
    //@ts-ignore
    const connectedAccounts = ethereum
      ? await ethereum.request({ method: "eth_accounts" })
      : [];
    if (connectedAccounts.length > 0) {
      let currentChainId = await web3.eth.getChainId();
      if (currentChainId == chainId) {
        return true;
      }
    }
  } catch (err) {
    console.log(err);
    return false;
  }
  return false;
};
const isEtheriumConnected = async function () {
  try {
    //@ts-ignore
    const connectedAccounts = ethereum
      ? await ethereum.request({ method: "eth_accounts" })
      : [];
    if (connectedAccounts.length > 0) {
      console.log("connectedAccounts " + connectedAccounts[0]);
      return true;
    }
  } catch (err) {
    console.log(err);
    return false;
  }
  return false;
};
const getCurrentAccountBalanceCoins = async function () {
  try {
    //@ts-ignore
    const connectedAccounts = ethereum
      ? await ethereum.request({ method: "eth_accounts" })
      : [];
    if (connectedAccounts.length > 0) {
      let senderAddress = connectedAccounts[0];
      //@ts-ignore
      let balance = await web3.eth.getBalance(senderAddress);
      let etherBalance = web3.utils.fromWei(balance, "ether");
      return parseFloat(etherBalance);
    }
  } catch (err) {
    console.log(err);
  }
  return 0;
};
const fromWei = (amount: string) => {
  return parseFloat(web3.utils.fromWei(amount, "ether"));
};
const toWei = (amount: string) => {
  return parseFloat(web3.utils.toWei(amount, "ether"));
};
const bignumber = (amount: string) => {
  return web3.utils.toBN(amount);
};
const checkWallet = async function () {
  let userId = await localStorage.getItem("userId");
  let isEthereumCorrectNetwork = await isCorrectEtheriumNetwork();
  if (!isEthereumCorrectNetwork) {
    await switcNetwork();
  }
  if (userId) {
    //@ts-ignore
    const acc = ethereum
      ? await ethereum.request({ method: "eth_accounts" })
      : [];
    if (acc.length <= 0) {
      return "";
    }
    // });
  } else {
    window.location.href = "/connect-wallet";
  }
};
const connect = async function () {
  // window.addEventListener('load', async () => {
  // Modern dapp browsers...
  if (ethereum) {
    try {
      // Request account access if needed **** deprecated
      //@ts-ignore
      // await ethereum.enable();
      //@ts-ignore
      const acc = ethereum
        ? await ethereum.request({ method: "eth_requestAccounts" })
        : [];
      window.localStorage.setItem("connectedAccount", acc[0]);
    } catch (error) {
      //@ts-ignore
      window.alert(error.message);
      // User denied account access...
    }
  } else {
    window.location.href = "https://metamask.io/download";
    console.log(
      "Non-Ethereum browser detected. You should consider trying MetaMask!"
    );
  }
};
const connectWallet = async () => {
  try {
    // Will open the MetaMask UI
    // You should disable this button while the request is pending!
    //@ts-ignore
    await ethereum.request({ method: "eth_requestAccounts" });
  } catch (error) {
    console.error(error);
  }
};
const switcNetwork = async function () {
  const chainId = parseInt(String(process.env.REACT_APP_ETH_CHAIN_ID));
  const rpcUrl = String(process.env.REACT_APP_ETH_RPC_URL);
  try {
    //@ts-ignore
    await ethereum.request({
      method: "wallet_switchEthereumChain",
      params: [{ chainId: web3.utils.toHex(chainId) }],
    });
  } catch (switchError) {
    // This error code indicates that the chain has not been added to MetaMask.
    //@ts-ignore
    if (switchError.code === 4902) {
      try {
        //@ts-ignore
        await ethereum.request({
          method: "wallet_addEthereumChain",
          params: [{ chainId: web3.utils.toHex(chainId), rpcUrl: rpcUrl }],
        });
      } catch (addError) {
        // handle "add" error
        console.log(addError);
      }
    }
  }
};
//Created check function to see if the MetaMask extension is installed
const isMetaMaskInstalled = () => {
  //Have to check the ethereum binding on the window object to see if it's installed
  //@ts-ignore
  const { ethereum } = window;
  return Boolean(ethereum && ethereum.isMetaMask);
};
const getTransaction = async (tx: any) => {
  let block = await web3.eth.getTransaction(tx);
  return block.blockNumber;
};
const getTimeStamp = async (block: any) => {
  let blocktrans = await web3.eth.getBlock(block);
  return blocktrans.timestamp;
};
const SignwithMetamask = async () => {
  try {
    // Request account access if needed
    let isEthereumCorrectNetwork = await isCorrectEtheriumNetwork();
    if (!isEthereumCorrectNetwork) await switcNetwork();

    let isConnected = await isEtheriumConnected();
    if (!isConnected) await connect();

    let address = await getCurrentAccount();
    if (address == "") return false;

    try {
      const token = await localStorage.getItem("fcmToken");
      //@ts-ignore
      let payload = {
        address,
        device_token: token,
        device_type: "3",
        fcm_token: token,
      };
      Utils.constant.axios.defaults.headers.common["Authorization"] =
        "Basic YWRtaW5AZ3JhYmpvYnMuY29tOlBhc3NAd29yZDE=";
      Utils.constant.axios.defaults.headers.common["authToken"] = token;
      Utils.constant.axios
        .post(Utils.constant.apiUrl3 + "users/wallet_nonce", payload)
        .then(async (response: any) => {

          // server sends back custom nonce here
          //
          
          let nonce = response.data.data.nonce;
          //@ts-ignore
          try {
            let signature = await getSignagnature(address, nonce);
            if (signature != null) {
              let payload = {
                address,
                signature,
                device_token: token,
                device_type: "3",
                fcm_token: token,
              };
              Utils.constant.axios.defaults.headers.common["Authorization"] =
                "Basic YWRtaW5AZ3JhYmpvYnMuY29tOlBhc3NAd29yZDE=";
              Utils.constant.axios.defaults.headers.common["authToken"] = token;
              Utils.constant.axios
                .post(Utils.constant.apiUrl3 + "users/wallet_verify", payload)
                .then(async (response: any) => {
                  console.log(response.data.status);
                  let data = response.data.data;
                  if (response.data.status == "success") {
                    message_notification(
                      "Logged in. Redirecting...",
                      "success"
                    );
                    address = await getCurrentAccount();
                    let sessionData = {
                      accessToken: data.token,
                      user_id: data.user_id,
                      full_name:
                        data.fullName && !data.fullName.includes("undefined")
                          ? data.fullName
                          : `${data.first_name} ${data.last_name}`,
                      first_name: data.first_name,
                      last_name: data.last_name,
                      user_image: data.user_image,
                      twitter_user_name: data.twitterUsername,
                      username: data.username,
                      connectedAccount: address,
                    };
                    Utils.constant.setSession(sessionData);
                    setTimeout(() => {
                      //@ts-ignore
                      window.location.reload();
                    }, 2000);
                  } else {
                    return false;
                  }
                });
            }
          } catch (error) {
            console.error(error);
            return false;
          }
        });
    } catch (error) {
      //@ts-ignore
      window.alert(error.message);
      // User denied account access...
    }
  } catch (error) {
    //@ts-ignore
    window.alert(error.message);
    // User denied account access...
  }
};
export default {
  isCorrectEtheriumNetwork,
  isEtheriumConnected,
  getCurrentAccountBalanceCoins,
  getCurrentAccount,
  SignwithMetamask,
  connectWallet,
  connect,
  switcNetwork,
  isMetaMaskInstalled,
  getTransaction,
  getTimeStamp,
  fromWei,
  checkWallet,
  getSignagnature,
  payOrder,
  contract_getCommission,
  isContested,
  getOrderSimple,
  setTracking,
  sabuyContractTest,
  toWei,
};
function toHex(nonce: any) {
  throw new Error("Function not implemented.");
}
