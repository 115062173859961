import React, { PureComponent } from 'react';
import { connect } from "react-redux";
import AppLayout from "../../../applayout/applayout";
import { withTranslation } from "react-i18next";
import { GetNFTs } from "../";
import { NFTModal } from "../../../modal";
import NftCard from '../../../component/NftCardHome';
interface Props {
    t?: any;
    history?: any;
    GetNFTs?: any;
    GetMultiNFTs?: any;
    GetCollections?: any;
    UpdateNFT?: any;
    UpdateNFTBuyNow?: any;
    nftsList: any;
    multi_nftsList: any;
    collectionlist: any;
    updateNFTResult?: any;
    height?: any
}
interface State {
    nftsList: any;
    multi_nftsList: any;
    collectionlist: any;
    playingTokenId: number,
    display: string,
    userId: string,
    isLoading: boolean;
    popupMessage: string;
    popupStatus: string;
    createNFTResult: any;
    updateNFTResult: any;
    popupTitle: string;
    transactionHashBuyNow: string;
    done: number;
}
export class HomeScreen extends PureComponent<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            nftsList: null,
            multi_nftsList: null,
            collectionlist: null,
            playingTokenId: -1,
            display: "none",
            userId: '',
            isLoading: false,
            popupMessage: "",
            createNFTResult: {},
            popupStatus: "loading",
            transactionHashBuyNow: "",
            popupTitle: "Status",
            updateNFTResult: {},
            done: 0
        };
    }
    async componentDidMount() {
        let userId = await localStorage.getItem("userId");
        this.setState({
            userId: String(userId)
        })

        await this.props.GetNFTs(() => {
            this.setState({ nftsList: this.props.nftsList });
           
        });

    }
    RenderNFTs(nft: NFTModal) {
        return <NftCard nft={nft} key={nft._id} />
    }

    render() {
        return (
          <>
            <div className="container-fluid">
              <div className="row border bg-light" style={{ height: "300px" }}>
                <div className="d-flex flex-column justify-content-center align-items-center text-center">
                  <div className="p-2">
                    <h1>
                      Experience the speed and privacy of{" "}
                      <span className="text-primary">
                        <strong>Web3</strong>
                      </span>{" "}
                      shopping
                    </h1>
                    <p>
                      Zero tracking, no hassle with the security of our blockchain escrow
                    </p>
                    <br />
                    <a href="sell-now" style={{ paddingRight: "5px" }}>
                      <button type="button" className="btn btn-dark">
                        Sell now
                      </button>
                    </a>
                    <a href="/help">
                      <button type="button" className="btn btn-outline-dark">
                        Learn more
                      </button>
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div style={{ height: "10px" }}></div>
            <div style={{ height: "10px" }}></div>

            {/* // <!--Section 2 begins here--> */}

            <section>
              <div className="container-fluid">
                <div className="row">
                  <div className="col-8 text-start">
                    <h3>New items</h3>
                  </div>
                  <div className="col-4 text-end">
                    <p>
                      <a href="/explore">See all</a>
                    </p>
                  </div>
                </div>

                <div className="row">
                  {this.state.nftsList &&
                    this.state.nftsList.length &&
                    this.state.nftsList.map((nft: NFTModal, index: any) => {
                      return this.RenderNFTs(nft);
                    })}
                </div>
              </div>
            </section>
          </>
        );
    }
}
function mapStateToProps(state: any) {
    return {
        nftsList: state.homeReducer || {}
    };
}
function mapDispatchToProps(dispatch: Function) {
    return {
        GetNFTs: (callback: Function) => dispatch(GetNFTs(callback))
    }
}
export const HomeModule = withTranslation()(
    connect(mapStateToProps, mapDispatchToProps)(HomeScreen)
);
export const Home = AppLayout(HomeModule);
