import Utils from "../../utils";
export function UpdateProductApi(
  payload: FormData,
  token: string,
  callback: Function
) {
  return (dispatch: Function, getState: Function) => {
    let hasConnection = Utils.constant.hasConnection();
    if (!hasConnection) {
      return false;
    }
    Utils.constant.handleOverlayLoader(true);
    let endPoint = Utils.endpoints.UpdateItem;
    Utils.api.postMultipartFormData(
      endPoint,
      payload,
      token,
      function (response: any) {
        if (response.status) {
          let data: any = response.data.data;
        
          dispatch({
            type: Utils.actionName.UPDATE_ITEM,
            payload: { data },
          });
          callback();
        }
      },
      function (error: any) {
        if (error.status === 501) {
          Utils.constant.apiErrorMessage(error.data.message);
        }
      }
    );
  };
}
export function DeleteProductApi(
  payload: any,
  token: string,
  callback: Function = function () {}
) {
  return (dispatch: Function, getState: Function) => {
    let hasConnection = Utils.constant.hasConnection();
    if (!hasConnection) {
      return false;
    }
    Utils.constant.handleOverlayLoader(true);
    let endPoint = Utils.endpoints.DeleteItem;
    Utils.api.postApiCall(
      endPoint,
      payload,
      function (response: any) {
        if (response.status) {
          let data = response.data.data;

          dispatch({
            type: Utils.actionName.DELETE_ITEM,
            payload: { data },
          });
          callback();
        }
      },
      function (error: any) {
        if (error.status === 501) {
          Utils.constant.apiErrorMessage(error.data.message);
        }
      }
    );
  };
}
export function GetProductApi(
  payload: any,
  callback: Function = function () {}
) {
  return (dispatch: Function, getState: Function) => {
    let hasConnection = Utils.constant.hasConnection();
    if (!hasConnection) {
      return false;
    }
    Utils.constant.handleOverlayLoader(true);
    let endPoint = Utils.endpoints.getUserProduct;
    Utils.api.postApiCall(
      endPoint,
      payload,
      function (response: any) {
        if (response.status) {
          let data = response.data.data;
          dispatch({
            type: "EDIT-USER-ITEM",
            payload: { data },
          });
          callback();
        }
      },
      function (error: any) {
        if (error.status === 501) {
          Utils.constant.apiErrorMessage(error.data.message);
        }
      }
    );
  };
}
