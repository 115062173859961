import React, { PureComponent } from "react";
import { connect } from "react-redux";
import AppLayout from "../../../applayout/applayout";
import { MessageLoadingModal } from "../../../component/modal-popup/modal-popup";
import { withTranslation } from "react-i18next";
import { CreateNFT, UpdateNFT } from "../";
import web3Utills from '../../../utils/web3Utills';
import { NFTModal } from "../../../modal";
//import {Datetimepicker} from "../screen/datetimepicker"
const Web3 = require('web3');
const multiplicationFactor = 0.20;
const constGasLimit = 250130;
const constGasPrice = 10000000000;
interface Props {
    t?: any;
    history?: any;
    SelectCategory?: any;
    CreateNFT?: any;
    categoryList?: any;
    CollectionList?: any;
    createNFTResult?: any;
    SelectCollection?: any
}
interface State {
    newImageSrc: string;
    imageFile: any;
    fileName: string;
    newPreviewImageSrc: string;
    previewImageFile: any;
    previewFileName: string;
    price: number;
    category: string;
    collection: string;
    categoryList?: any;
    CollectionList?: any;
    description: string;
    title: string;
    pic: string;
    nftType: string;
    nftMediaType: string;
    createNFTResult: any;
    isLoading: boolean;
    popupMessage: string;
    popupStatus: string;
    popupTitle: string;
    tokenId: number;
    userProfileImage?: string;
    partnerCode: string;
    subCategory: string;
    accountAddress: string;
    availableForSale: boolean;
    artCreationDate: number;
    tags: string;
    navigateTo: string;
    uploadFileName: string;
    fileType: string;
    fileExtensions: string;
    uploadText: string;
    isFirstTime: boolean;
    error: {
        fileError?: string;
        titleError?: string;
        categoryError?: string;
        subCategoryError?: string;
        descriptionError?: string;
        priceError?: string;
        startBidError?: string;
        nftTypeError?: string;
        startingDateError?: string;
        expirationDateError?: string;
        creationDateError?: string;
        previewFileError?: string;
    };
}
export class RequestItemScreen extends PureComponent<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            imageFile: null,
            createNFTResult: {},
            newImageSrc: "",
            fileName: "",
            newPreviewImageSrc: "",
            previewImageFile: null,
            previewFileName: "",
            price: 0,
            category: "",
            collection: "",
            description: "",
            title: "",
            pic: "",
            fileType: "image",
            fileExtensions: "jpg, gif, png, jpeg",
            nftType: "Fixed Price",
            nftMediaType: "image",
        
            
            accountAddress: "",
            isLoading: false,
            popupMessage: "",
            popupStatus: "loading",
            popupTitle: "Status",
           
            tokenId: -1,
            userProfileImage: "",
            availableForSale: true,
            partnerCode: "",
            subCategory: "",
            artCreationDate: 0,
            tags: "",
           
           
           
            navigateTo: "",
            uploadFileName: 'Image file',
            uploadText: '',
            isFirstTime: true,
            error: {
                fileError: "",
                titleError: "",
                categoryError: "",
                subCategoryError: "",
                descriptionError: "",
                priceError: "",
                startBidError: "",
                nftTypeError: "",
                startingDateError: "",
                expirationDateError: "",
                creationDateError: "",
                previewFileError: "",
            },
        };
    }
    async componentDidMount() {
        try {
            let profileImage = await localStorage.getItem("profile_image");
            let token = await localStorage.getItem("accessToken");
            let userId = await localStorage.getItem("userId");
            this.setState({ userProfileImage: String(profileImage), categoryList: ["Tops", 'Bottoms', 'Outerwear', 'Footwear', 'Accessories', 'Others'] });
        } catch (ex) {
            console.log(ex);
        }
    }
    async image(e: any) {
        try {
            if (e.target.files && e.target.files.length > 0) {
                let ffname = String(e.target.files[0].name);
                let extension = ffname.toLowerCase().split('.').pop();
                //alert(extension);
                if (this.state.fileExtensions.includes((extension ? extension : ""))) {
                    this.setState({ error: { fileError: '' } });
                    let file = e.target.files[0];
                    console.log(e.target.files[0])
                    const reader: any = new FileReader();
                    await this.setState({
                        imageFile: file,
                    });
                    reader.addEventListener("load", () => {
                        this.setState({
                            newImageSrc: reader.result,
                        })
                    }
                    );
                    this.setState({
                        fileName: e.target.files[0].name
                            ? e.target.files[0].name
                            : "newImage.jpg",
                    });
                    reader.readAsDataURL(e.target.files[0]);
                    e.target.value = "";
                    //@ts-ignore
                    //  document.getElementById('file_name').innerText=e.target.files[0].name.length>10?e.target.files[0].name.substring(1,10)+"...":e.target.files[0].name
                    //@ts-ignore
                    //console.log(document.getElementById('file_name').innerText) 
                }
                else {
                    this.setState({ error: { fileError: 'Only use '+ this.state.fileExtensions+' files.' } },
                        () => {
                            //@ts-ignore
                            document.getElementById('upload_file').click();
                        })
                }
            }
        } catch (ex) {
            console.log(ex);
        }
    }
    async preViewImage(e: any) {
        try {
            if (e.target.files && e.target.files.length > 0) {
                let file = e.target.files[0];
                const reader: any = new FileReader();
                await this.setState({
                    previewImageFile: file,
                });
                reader.addEventListener("load", () => {
                    this.setState({
                        newPreviewImageSrc: reader.result,
                    })
                }
                );
                this.setState({
                    previewFileName: e.target.files[0].name
                        ? e.target.files[0].name
                        : "newImage.jpg",
                });
                reader.readAsDataURL(e.target.files[0]);
                e.target.value = "";
            }
        } catch (ex) {
            console.log(ex);
        }
    }
    handleChange(e: any) {
        try {
            let selectedCategory = e.currentTarget.value;
            this.setState({ category: selectedCategory});
        } catch (ex) {
            console.log(ex);
        }
    }
    handleSubChange(e: any) {
        try {
            this.setState({ subCategory: e.currentTarget.value });
        } catch (ex) {
            console.log(ex);
        }
    }
    handleFiledUpdate = (key: string, e: any) => {
        try {
            //@ts-ignore
            this.setState({ [key]: e });
        } catch (ex) {
            console.log(ex);
        }
    };
    handleSubmit = async (e: any) => {
        try {
            e.preventDefault();
            console.log('going in')
            //console.log(this.state.description);
            let creationError = this.state.error?.creationDateError == undefined ? "" : this.state.error?.creationDateError;
            console.log('setting states')
            await this.setState({
                error: {
                    fileError: !this.state.fileName ? "Please select the file." : "",
                    previewFileError: (this.state.nftMediaType == "audio" || this.state.nftMediaType == "video" || this.state.nftMediaType == "pdf") && !this.state.previewFileName ? "Please select the preview image." : "",
                    titleError: !this.state.title ? "Please enter the title." : "",
                    categoryError: !this.state.category ? "Please select the category." : "",
                    priceError: this.state.price <= 0 ? "Please enter the price." : "",
                    descriptionError: !this.state.description ? "Please add the description." : "",
                },
            }, async () => {
                console.log('checking state if', this.state.error?.creationDateError)
                if (
                    this.state.error?.fileError == "" &&
                    this.state.error?.previewFileError == "" &&
                    this.state.error?.categoryError == "" &&
                    this.state.error?.titleError == "" &&
                    this.state.error?.descriptionError == "" &&
                    this.state.error?.priceError == "") {
                    console.log('inside if')
                    this.ShowErrorMessage(true, "Please wait while your item is being created.", "pending", "", "Creating Item");
                    //Call Create NFT Function.
                    let accountAddress = await web3Utills.getCurrentAccount();
                    let userId = await localStorage.getItem("userId");
                    let token = await localStorage.getItem("accessToken");
                    console.log('making form data')
                    let formData = new FormData();
                    formData.append('nftFile', this.state.imageFile);
                    formData.append('type', 'ASK');
                    formData.append('previewImage', this.state.previewImageFile);
                    formData.append('category', this.state.category);
                    formData.append('description', this.state.description);
                    formData.append('title', this.state.title);
                    formData.append('creatorId', String(userId));
                    // handling the decimal places on submit
                    let price = parseFloat(this.state.price.toString()).toFixed(2);
                    price = price.toString();
                    formData.append('price', String(this.state.price));
                    // let startBid = parseFloat(this.state.startBid.toString()).toFixed(2);
                    // startBid = startBid.toString();
                    // console.log(startBid);
                    // console.log(formData);
                    // debugger;
                    //payload: FormData, token: string, callback: Function
                    
                    this.props.CreateNFT(formData, token,
                        async () => {
                            window.location.href = "/explore";
                        });
                }
                else if (this.state.error?.fileError != "") {
                    this.setState({ navigateTo: "#fileError" });
                }
                else if (this.state.error?.nftTypeError != "") {
                    this.setState({ navigateTo: "#nftTypeError" });
                }
                else if (this.state.error?.priceError != "") {
                    this.setState({ navigateTo: "#priceError" });
                }
               
                else if (this.state.error?.categoryError != "") {
                    this.setState({ navigateTo: "#categoryError" });
                }
                else if (this.state.error?.subCategoryError != "") {
                    this.setState({ navigateTo: "#subCategoryError" });
                }
                // else if (this.state.error?.collectionNameError != "") {
                //     this.setState({ navigateTo: "#collectionNameError" });
                // }
                else if (this.state.error?.titleError != "") {
                    this.setState({ navigateTo: "#titleError" });
                }
                else if (this.state.error?.descriptionError != "") {
                    this.setState({ navigateTo: "#descriptionError" });
                }
            });
        } catch (ex) {
            console.log(ex);
        }
    }
    ShowErrorMessage(isShowLoading: boolean, message: string, status: string, transactionHash: any, title: string) {
        try {
            this.setState({
                isLoading: isShowLoading,
                popupTitle: title,
                popupStatus: status,
                popupMessage: message,
            });
        } catch (ex) {
            console.log(ex);
        }
    }
   
    handlePriceChange = (key: string, e: any) => {
        try {
            let value = e.target.value;
            if (value != "") {
                if (value && Number(value) >= 0) {
                    this.setState({ price: value });
                }
            }
        } catch (ex) {
            console.log(ex);
        }
    }
    changeImageSize(image: any, imageId: any) {
        var height = image.target.height
        var width = image.target.width
        if (height > width) {
            console.log('portrait', height, width, imageId)
            //portrait
            // //@ts-ignore
            // document.getElementById(imageId).style.height='auto'
            //  //@ts-ignore
            //  document.getElementById(imageId).style.width='100%'
            image.target.classList.add('forPortrait');
            return true;
        }
        else if (height < width) {
            console.log('landscape', height, width, imageId)
            //landscape
            //  //@ts-ignore
            //  document.getElementById(imageId).style.height='262px'
            // //@ts-ignore
            // document.getElementById(imageId).style.width='auto'
            image.target.classList.add('forLandscape');
            return true;
        }
        else {
            console.log('simple', height, width, imageId)
            //landscape
            //  //@ts-ignore
            //  document.getElementById(imageId).style.height='262px'
            // //@ts-ignore
            // document.getElementById(imageId).style.width='auto'
            image.target.classList.add('forLandscape');
            return true;
        }
    }
    render() {
        let categoryOptions: any = null;
        if (
            this.state.categoryList &&
            Array.isArray(this.state.categoryList) &&
            this.state.categoryList.length > 0
        ) {
            categoryOptions = this.state.categoryList.map((category: any) => {
                return <option className="textBlack" style={{ color: "black" }} value={category} key={category}>{category}</option>;
            });
        }
        return (
            <>
                <div className="no-bottom" id="content">
                    <div id="top"></div>
                    <section id="section-intro" className="pt80">
                        <div className="center-y relative text-center">
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-12 text-center">
                                        <h1>Find a seller for the item you want to find</h1>
                                    </div>
                                    <div className="clearfix"></div>
                                    <div className="fine_line" ></div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <MessageLoadingModal onHide={() => { this.setState({ isLoading: false }); return true; }} title={this.state.popupTitle} modalShow={this.state.isLoading} message={this.state.popupMessage} status={this.state.popupStatus}></MessageLoadingModal>
                    <section aria-label="section">
                        <div className="container">
                            <div className="row wow fadeIn">
                                <div className="col-lg-7 offset-lg-1">
                                    <form id="form-create-item" className="form-border" method="post" >
                                        <div className="field-set">
                                            <h5>{this.state.uploadFileName}* <span className="text-danger" id="fileError">{this.state.error.fileError}</span></h5>
                                            <div className="d-create-file">
                                                <p id="file_name" style={{ overflowWrap: "break-word" }}>
                                                    {this.state.uploadText}
                                                </p>
                                                <figure
                                                    className="edit_user_img"
                                                    onChange={(e) => {
                                                        this.image(e);
                                                    }}
                                                >
                                                    <input type="button" id="get_file" className="btn-main" value="Upload Photos" />
                                                    <input type="file" id="upload_file" />
                                                </figure>
                                            </div>
                                            <div className="spacer-40"></div>
                                            <div className="switch-with-title" >
                                                <h5><i className="fa fa-exclamation-circle"></i> Use only your own photos.</h5>
                                                <div className="clearfix"></div>
                                                <p className="p-info">This listing might be hidden or removed if it contains photos that aren’t yours.</p>
                                            </div>
                                            <div className="spacer-40"></div>
                                            <h5>Title* <span className="text-danger" id="titleError">{this.state.error.titleError}</span></h5>
                                            <input
                                                value={this.state.title}
                                                onChange={(e: any) => {
                                                    this.handleFiledUpdate("title", e.currentTarget.value);
                                                }}
                                                maxLength={50}
                                                type="text" name="item_title" id="item_title" className="form-control" placeholder="e.g. White COS Jumper" />
                                            <div className="spacer-20"></div>
                                            <h5>Description* <span className="text-danger" id="descriptionError">{this.state.error.descriptionError}</span></h5>
                                            <p>Put the most accurate description of your item.</p>
                                            <textarea
                                                value={this.state.description}
                                                onChange={(e: any) => {
                                                    this.handleFiledUpdate("description", e.currentTarget.value);
                                                }}
                                                data-autoresize
                                                name="item_desc"
                                                id="item_desc"
                                                className="form-control"
                                                placeholder="e.g. only worn a few times, true to size"
                                            ></textarea>
                                            <div className="spacer-40"></div>
                                            <h5>Category* <span className="text-danger" id="categoryError">{this.state.error.categoryError}</span></h5>
                                            <select className="form-control col-md-12 dropdown textBlack" onChange={this.handleChange.bind(this)}>
                                                <option style={{ color: "black" }} value="">Choose a category </option>
                                                {categoryOptions}
                                            </select>
                                            <div className="spacer-60"></div>
                                            <div id="tab_opt_1">
                                                <h5>Price (MATIC)<span className="text-danger" id="priceError">{this.state.error.priceError}</span></h5>
                                                <input
                                                    value={this.state.price}
                                                    step="1"
                                                    min={0}
                                                    onChange={(e: any) => {
                                                        this.handlePriceChange("price", e);
                                                    }}
                                                    type="number" name="item_price" id="item_price" className="form-control" placeholder="MATIC 100" />
                                            </div>
                                            <div className="spacer-single"></div>
                                            <input onClick={(evt) => { this.handleSubmit(evt) }} type="button" id="submit" className="btn-main" value="Sell" />
                                            <div className="spacer-single"></div>
                                        </div>
                                    </form>
                                </div>
                                <div className="col-lg-3 col-sm-6 col-xs-12">
                                    <h5>Preview</h5>
                                    <div className="nft__item h-auto" style={{ height: "auto !important" }}>
                                        <a href="#">
                                            <img src={String(this.state.newImageSrc)} id="get_file_2"
                                                onLoad={(e: any) => {
                                                    //this.changeImageSize(e,'get_file_2')
                                                }}
                                                className="lazy nft__item_preview" alt="" />
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
                <a href="#" id="back-to-top"></a>
            </>
        );
    }
}
function mapStateToProps(state: any) {
    return {
        createNFTResult: state.CreateNFTReducer || {},
    };
}
function mapDispatchToProps(dispatch: Function) {
    return {
        CreateNFT: (payload: FormData, token: string, callback: Function) => dispatch(CreateNFT(payload, token, callback))
    }
}
export const RequestItemModule = withTranslation()(
    connect(mapStateToProps, mapDispatchToProps)(RequestItemScreen)
);
export const RequestItem = AppLayout(RequestItemModule);