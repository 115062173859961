import Utils from "../../utils";
import {  userProfile } from "../../modal";


export function deleteProfile(
  payload: any,
  callback: Function = function () { }
) {
  return (dispatch: Function, getState: Function) => {
    let hasConnection = Utils.constant.hasConnection();
    if (!hasConnection) {
      console.log("no connection")
      return false;
    }
    Utils.constant.handleOverlayLoader(true);
    let endPoint = Utils.endpoints.deleteProfile;
    Utils.api.postApiCall(
      endPoint,
      payload,
      function (response: any) {
        if (
          response.status &&
          response.data &&
          response.data.code &&
          response.data.code === 200
        ) {
          let data: any = response.data.data;
          localStorage.clear();
          window.location.href = '/home';
          callback(data);
        } else if (response.status && response.data) {
          let data: any = response.data;
          let error = data.code && data.code != 200 ? data.message : "";
          error = error ? error : "Something went wrong";
          Utils.constant.apiErrorMessage(error);
          callback(data);
        }
      },
      function (error: any) {
        Utils.constant.apiErrorMessage(error.data.message);
        callback({ message: error.data.message });
      }
    );
   };
}



export function updateProfile(
  payload: any,
  callback: Function = function () { }
) {
  return (dispatch: Function, getState: Function) => {
    let hasConnection = Utils.constant.hasConnection();
    if (!hasConnection) {
      console.log("no connection")
      return false;
    }
    Utils.constant.handleOverlayLoader(true);
    let endPoint = Utils.endpoints.updateProfile;
    Utils.api.postApiCall(
      endPoint,
      payload,
      function (response: any) {
        if (
          response.status &&
          response.data &&
          response.data.code &&
          response.data.code === 200
        ) {
          let data: any = response.data.data;
          callback(data);
         setInterval(()=>{ window.location.href = "/author";},3000)
         
        } else if (response.status && response.data) {
          let data: any = response.data;
          let error = data.code && data.code != 200 ? data.message : "";
          error = error ? error : "Something went wrong";
          Utils.constant.apiErrorMessage(error);
          callback({ loginerror: true });
        }
      },
      function (error: any) {
        Utils.constant.apiErrorMessage(error.data.message);
        callback({ loginerror: true });
      }
    );
    
  };
}

export function getUserProfileData(
  payload: any,
  authToken: string,
  callback: Function = function () { }
) {
  return (dispatch: Function, getState: Function) => {
    let hasConnection = Utils.constant.hasConnection();
    if (!hasConnection) {
      return false;
    }

    Utils.constant.handleOverlayLoader(true);

    let endPoint = Utils.endpoints.getUserProfileData;

    Utils.api.getApiCall(
      endPoint,
      payload,
      authToken,
      function (response: any) {
        if (response.status) {
          let data: userProfile = response.data.data;

          dispatch({
            type: Utils.actionName.USER_PROFILE,
            payload: { data },
          });

          callback();
        }
      },
      function (error: any) {
        if (error.status === 501) {
          Utils.constant.apiErrorMessage(error.data.message);
        }
      }
    );
  };
}
