import { Alchemy, Network } from "alchemy-sdk";
import contractABI from "../contracts/sabuy.json";
const { createAlchemyWeb3 } = require("@alch/alchemy-web3");


const ALCHEMY_URL = process.env.REACT_APP_API_PROVIDER;
const ALCHEMY_KEY = process.env.REACT_APP_ALCHEMY_KEY;
const contractAddress = process.env.REACT_APP_SABUY_CONTRACT_ADD;

const settings = {
  apiKey: ALCHEMY_KEY,
  network: Network.MATIC_MAINNET, // Replace with your network.
};

if (process.env.REACT_APP_MODE == "development")  { settings.network = Network.MATIC_MUMBAI; }


const alchemy = new Alchemy(settings);


const web3 = createAlchemyWeb3(ALCHEMY_URL);

export const sabuyContract = new web3.eth.Contract(
  contractABI,
  contractAddress
);

export const AlchemyProvider = alchemy;
