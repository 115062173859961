import React from "react";
import Header from "../../../component/header/header";
import Footer from '../../../component/footer/footer'

// import NetworkCard from "../../../component/network/network";
// import Loader from "../../../component/loader/loader";

class Account extends React.Component {
  render() {
    return (
      <div>
<Header></Header> 
        {/* <NetworkCard></NetworkCard>
        <Loader></Loader> */}
        <div className="bg_onboarding"></div>
        {this.props.children}<Footer></Footer>
        
      </div>
    );
  }
}
export default Account;
