import React, { PureComponent } from "react";
import { connect } from "react-redux";
import AppLayout from "../../../applayout/applayout";
import { sendMessage } from "../";
import "react-toastify/dist/ReactToastify.css";
import Utils from "../../../utils";
import EmailForm from "./emailform";
interface Props {
  t: any;
  sendMessage: Function;
  loginResponce?: any;
}
interface State {
  email: string;
  captchaChecked: boolean;
  showLoginLoading: boolean;
  emailResponse: string;
  error: {
    emailError: string;
    captchaError: string;
  };
}

export class ContactUsScreen extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      email: "",
      captchaChecked: false,
      emailResponse: "Send Message",
      showLoginLoading: false,
      error: {
        emailError: "",
        captchaError: "",
      },
    };
  }

  componentDidMount() {}

  handleCaptcha() {
    this.setState({ captchaChecked: true });
  }

  SetEmailData = async (data: any) => {
    {
      this.setState({ showLoginLoading: true, email: data.email, emailResponse:'Sending...' });

      let payload = {
        email: data.email,
        message: data.message,
      };

     

      this.props.sendMessage(payload, (response: any) => {
        this.setState(
          {
            email: "",
          },
          () => {
            setTimeout(() => {
              this.setState({ emailResponse: "Sent" })
            }, 2000); // wait 5 seconds
          }
        );
      });
    }
  };

  render() {
    return (
      <>
        <EmailForm SetEmailData={this.SetEmailData.bind(this)} EmailStatus={this.state.emailResponse} />
        {this.state.emailResponse == "Sent" ? (
          <div className="container-md">
            <div className="mx-auto col-10 col-md-8 col-lg-6">
              <div className="alert alert-success" role="alert">Sent email. Thank you for contacting us.</div>
            </div>
          </div>
        ) : (
          ""
        )}
      </>
    );
  }
}

function mapStateToProps(state: any) {
  return {
    loginResponce: state.authReducer,
  };
}
function mapDispatchToProps(dispatch: Function) {
  return {
    sendMessage: (payload: any, callback: Function) =>
      dispatch(sendMessage(payload, callback)),
  };
}

export const ContactUsModule = connect(
  mapStateToProps,
  mapDispatchToProps
)(ContactUsScreen);

export const ContactUs = AppLayout(ContactUsModule);
