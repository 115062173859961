import React, { Component } from "react";
import Clock from "./clock.js";
import TrackingForm from "../../../component/trackingForm.js";
import web3Utills from "../../../utils/web3Utills";


class SellerLayout extends Component {
    constructor(props) {
        super(props);
        this.state = {
            contested: false,
            dateTrack: '',
        };
    }
    getOrderStatus = async (product_id) => {
        let order = await web3Utills.getOrderSimple(product_id);
        this.setState({ dateTrack: order.dateTrack, contested: order.contested, });
    }

    // getting order status from blockchain

    componentDidMount() {

        //this.getOrderStatus(this.props.product.productData[0].product_id);
        //web3Utills.PaymentERC20Test(    this.props.product.productData[0].product_id)

    }

    addProtocol = (link) => {
      if (!link) return '#';

      if (!link.startsWith('http://') && !link.startsWith('https://')) {
            return 'https://' + link;
      }
      return link;
    }

    SetTrackInfo = async function (data) {
        //console.log(JSON.stringify(data, null, 4))

        let saveData = {
            product_id: this.props.product.productData[0].product_id,
            tracking: data.tracking,
            tracking_url: this.addProtocol(data.trackingUrl),
            seller: this.props.product.seller,
            buyer: this.props.product.buyer,
            query_type: 'SHIPPING'
        }


        this.props.UpdateOrderShippingStatus(saveData);

        //let TrackDate = await web3Utills.setTracking(this.props.product.productData[0].product_id);


    }

    // SetTrackInfoTest = async function () {
    //     //console.log(JSON.stringify(data, null, 4))


    //     let saveData = {
    //         product_id: 1013,
    //         tracking: 'xxx',
    //         tracking_url: 'xxx.com',
    //         seller: '639cdf205ce5ea190865cb81',
    //         query_type: 'SHPPING'
    //     }

    //     this.props.UpdateOrderShippingStatus(saveData);


    //     //let TrackDate = await web3Utills.setTracking(this.props.product.productData[0].product_id);


    // }


    render() {
        return (
          <>
            {/* <div><button onClick={ (e)=>{this.SetTrackInfoTest(this)}}>SetTrackInfoTest</button></div> */}
            <span>
              Order created on:{" "}
              {new Date(this.props.product.created_at).toDateString()},{" "}
              {new Date(this.props.product.created_at).toLocaleTimeString()}
            </span>
            <div>
              {" "}
              {this.props.product.productData[0].product_id} STATUS=
              {this.props.product.status}
            </div>
            <div className="d-flex justify-content-center">
              <a
                href={
                  "/item-details/" +
                  this.props.product.productData[0].product_id
                }
              >
                <img
                  src={
                    this.props.product.productData[0].thumbnailImageBuffer[0]
                  }
                  className="rounded"
                  style={{ height: "120px", width: "100px" }}
                />
              </a>
            </div>

            {this.props.product.status == "SHIPPED" && (
              <>
                <p>
                  <strong>
                    Waiting for
                    <a href="">
                      {" "}
                      @{this.props.product.buyerData[0].username}
                    </a>{" "}
                    to receive the item..
                  </strong>
                  <br />
                  The buyer has 15 days to mark the transaction as received
                  <br />{" "}
                  <Clock
                    deadline={this.props.product.updated_at}
                    expires={15}
                  />
                </p>
                <ul className="list-group">
                  <li className="list-group-item">
                    Tracking number:
                    <strong> {this.props.product.tracking}</strong>
                  </li>
                  <li className="list-group-item">
                    For more detailed information, visit{" "}
                    <a
                      className="link-primary"
                      href={this.addProtocol(this.props.product.tracking_url)}
                      target={"_blank"}
                    >
                      {" "}
                      shipping company's tracking page
                    </a>
                    {}
                  </li>
                </ul>
              </>
            )}
            {this.props.product.status == "CONTESTED" && (
              <>
                <p>
                  <strong>
                    <a href=""> @{this.props.product.buyerData[0].username}</a>{" "}
                    </strong> 
                    contested the order with following reason. <br/>
                    {this.props.product.reason}
              <br />{" "}
              <div className="alert alert-warning mt-2" role="alert">
           The customer service will check the order details and contact you back.
          </div>

                
               
                </p>
              </>
            )}
            {this.props.product.status == "PROCESSED" && (
              <>
                <p>
                  <strong>
                    You successfully sold the item to{" "}
                    <a href={"/user/" + this.props.product.buyer}>
                      {" @"}

                      {this.props.product.buyerData?.length
                        ? this.props.product.buyerData[0].username
                        : "0x.." +
                          this.props.product.buyerData[0].eth_wallet_address.slice(
                            -6
                          )}
                    </a>{" "}
                  </strong>
                  <br />
                  You will receive <price>
                  {this.props.product.productData?.length
                    ? this.props.product.productData[0].price
                    : "price"}{" MATIC "}</price>
                  to your wallet within 1 day after order is marked as complete.
                   <br/> 
                
                </p>
              </>
            )}
            {(this.props.product.status == "" ||
              this.props.product.status == null ||
              this.props.product.status == "PENDING") && (
              <>
                <p>
                  <strong>
                    <a href={"/user/" + this.props.product.buyer}>
                      {" @"}

                      {this.props.product.buyerData?.length
                        ? this.props.product.buyerData[0].username
                        : "0x.." +
                          this.props.product.buyerData[0].eth_wallet_address.slice(
                            -6
                          )}
                    </a>{" "}
                    bought your item
                  </strong>
                  <br />
                  You have 5 days to ship the order
                  <br />{" "}
                  <Clock deadline={this.props.product.created_at} expires={5} />
                </p>
                <TrackingForm SetTrackInfo={this.SetTrackInfo.bind(this)} />
              </>
            )}
          </>
        );
    }
}
export default SellerLayout;