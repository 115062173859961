import moment from "moment";
import React, { useEffect, useState } from "react";
import Utils from "../../../utils";

const socketurl = process.env.REACT_APP_WSURI;

const Chat = ({ messagehead, sender, receiver, hidebox }) => {
  const [messages, setMessages] = useState({});
  const [message, setMessagehead] = useState({});
  const [newMessage, setNewMessage] = useState("");

  useEffect(async () => {
    GetAllMessage();
    joinchat();
    setMessagehead(messagehead);
  }, [messagehead._id]);

  const joinchat = () => {
    const ws = new WebSocket(socketurl);
    let message_id = messagehead._id;
    ws.onopen = () => {
      console.log("WebSocket connection opened");
      ws.send(JSON.stringify({ type: "join", data: { message_id } }));
    };
    ws.onmessage = (event) => {
      const { type, data } = JSON.parse(event.data);
      if (type === "message") {
        setMessages((messages) => [...messages, data]);
      }
    };
    return () => {
      ws.close();
    };
  };
  const GetAllMessage = async () => {
    Utils.constant.handleOverlayLoader(true);
    let endPoint = "message/" + messagehead._id;
    Utils.api.getApiCall(
      endPoint,
      "",
      "",
      function (response) {
        if (response.status) {
          let data = response.data.data;
          setMessages(data);
        }
      },
      function (error) {
        if (error.status === 501) {
          Utils.constant.apiErrorMessage(error.data.message);
        }
      }
    );
  };

  const hidechatbox = () => {
    hidebox();
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    let message_id = messagehead._id;
    let payload = {
      sender: sender,
      receiver: receiver,
      message_id: messagehead._id,
      product_id: messagehead.product_id,
      message: newMessage,
    };
    console.log(payload);
    const ws = new WebSocket(socketurl);
    ws.onopen = () => {
     
      ws.send(
        JSON.stringify({ type: "sendMessage", data: { message_id, payload } })
      );
      ws.close();
    };
    setNewMessage("");
   
  };
  return (
    <>
      <div className="msg-head">
        <div className="row">
          <div className="col-12">
            <div className="d-flex align-items-center">
              <div className="flex-grow-1 ms-3">
                <span className="chat-icon">
                  <img
                    className="img-fluid"
                    src={require("../../../assets/images/ui/arroleftt.svg")}
                    alt="back to inbox"
                    onClick={(e) => {
                      hidechatbox();
                    }}
                  />
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      {messagehead.productData && messagehead.productData.length > 0 && (
        <div className="msg-head">
          <div className="row">
            <div className="col-12">
              <div className="d-flex align-items-center">
                <div className="flex-shrink-0">
                  <a
                    href={
                      "/item-details/" + messagehead.productData[0].product_id
                    }
                  >
                    <img
                      className="img-fluid rounded"
                      src={messagehead.productData[0].thumbnailImageBuffer[0]}
                      alt={messagehead.productData[0].title}
                      height={"45px"}
                      width={"45px"}
                    />
                  </a>
                </div>
                <div className="flex-grow-1 ms-3">
                  <a
                    href={
                      "/item-details/" + messagehead.productData[0].product_id
                    }
                  >
                    <h3>{messagehead.productData[0].title}</h3>
                  </a>
                  <span>{messagehead.productData[0].price} MATIC</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="modal-body">
        <div className="msg-body">
          {messages && messages.length > 0 ? (
            <ul>
              {messages.map((message) => (
                <li
                  className={message.receiver == sender ? "sender" : "replay"}
                  key={message._id}
                >
                  <p> {message.message} </p>
                  <span className="time">
                    {moment(message.timestamp).format("MMMM Do YYYY, HH:mm")}
                  </span>
                </li>
              ))}
            </ul>
          ) : (
            ""
          )}
        </div>
      </div>
      <div className="send-box">
        <form onSubmit={handleSubmit}>
          <input
            type="text"
            value={newMessage}
            className="form-control"
            onChange={(event) => setNewMessage(event.target.value)}
          />
          <button type="submit">
            <i className="fa fa-paper-plane" aria-hidden="true"></i> Send
          </button>
        </form>
      </div>
    </>
  );
};
export default Chat;
