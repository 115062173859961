import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { GetMessages } from "..";
import AppLayout from "../../../applayout/applayout";
import web3Utills from "../../../utils/web3Utills";
import Chat from "./chat";

interface Props {
  MessageList: any;
  GetMessages: any;
}
interface State {
  userId: any;
  messages: any;
  selectedmessage: any;
  showbox: boolean;
}
export class InboxScreen extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      messages: [],
      selectedmessage: null,
      userId: window.localStorage.getItem("userId"),
      showbox: false,
    };
  }
  async componentDidMount() {
    let user_id = window.localStorage.getItem("userId");
    const userToken = await localStorage.getItem("accessToken");
    let connected = await web3Utills.isEtheriumConnected();

    if (userToken && connected) {
      try {
        this.props.GetMessages(user_id, () => {
          if (
            this.props.MessageList.data &&
            this.props.MessageList.data.length > 0
          ) {
            this.setState({
              messages: this.props.MessageList.data,
              selectedmessage: this.props.MessageList.data[0],
            });
          }
        });
      } catch (ex) {
        console.log(ex);
      }
    } else {
      window.location.href = "/connect-wallet";
    }
  }

  hidebox = () => {
    this.setState({ showbox: false });
  };
  rendermessage = (message: any) => {
    let receiver =
      message.receiver == this.state.userId ? message.sender : message.receiver;

    return (
      <Chat
        messagehead={message}
        sender={this.state.userId}
        receiver={receiver}
        hidebox={this.hidebox.bind(this)}
      />
    );
  };
  render() {
    const d = new Date();
    return (
      <>
        <section className="message-area">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="chat-area">
                  <div className="chatlist">
                    <div className="modal-dialog-scrollable">
                      <div className="modal-content">
                        <div className="chat-header">
                          <div className="msg-search">
                            <h3>Messages</h3>
                            <hr />
                          </div>
                        </div>
                        <div className="modal-body">
                          {/* <!-- chat-list --> */}
                          <div className="chat-lists">
                            <div className="tab-content" id="myTabContent">
                              {/* <!-- chat-list --> */}
                              <div className="chat-list">
                                {this.state.messages &&
                                this.state.messages.length > 0
                                  ? this.state.messages.map((message: any) => {
                                      return (
                                        <a
                                          href="#"
                                          onClick={(e) => {
                                            this.setState({
                                              selectedmessage: message,
                                              showbox: true,
                                            });
                                          }}
                                          id="chat-list-item"
                                          className="d-flex align-items-center"
                                          key={message._id}
                                        >
                                          <div
                                            className="flex-shrink-0"
                                            key={message._id}
                                          >
                                            <img
                                              className="rounded-circle click_profile_img"
                                              height={"45px"}
                                              width={"45px"}
                                              src={
                                                message.productData[0] &&
                                                message.productData[0]
                                                  .previewImagePath
                                                  ? message.productData[0]
                                                      .previewImagePath[0]
                                                  : require("../../../assets/images/author_single/profile-placeholder.png")
                                              }
                                              alt="user img"
                                            />
                                            {/* <span className="active"></span> */}
                                          </div>
                                          <div className="flex-grow-1 ms-3">
                                            <h3>
                                              { (message.productData[0] && message.productData[0].title)
                                               && message.productData[0].title.slice(
                                                    0,
                                                    30
                                                  )
                                               }
                                            </h3>
                                            <p>
                                              {message.message.slice(0, 30)}...
                                            </p>
                                          </div>
                                        </a>
                                      );
                                    })
                                  : "No Messages"}
                              </div>
                              {/* <!-- chat-list --> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <!-- chatlist --> */}
                  {/* <!-- chatbox --> */}
                  <div
                    className={
                      this.state.showbox ? "chatbox showbox" : "chatbox"
                    }
                  >
                    <div className="modal-dialog-scrollable">
                      <div className="modal-content">
                        {this.state.messages
                          ? this.state.messages.length > 0 &&
                            this.rendermessage(this.state.selectedmessage)
                          : "Empty"}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}
function mapStateToProps(state: any) {
  return {
    MessageList: state.InboxReducer || {},
  };
}
function mapDispatchToProps(dispatch: Function) {
  return {
    GetMessages: (user_id: Number, callback: Function) =>
      dispatch(GetMessages(user_id, callback)),
  };
}
export const InboxModule = connect(
  mapStateToProps,
  mapDispatchToProps
)(InboxScreen);
export const InboxDetail = AppLayout(InboxModule);
