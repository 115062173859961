
export function ItemEditReducer(state: any = {}, action: any) {
  
  switch (action.type) {
    case "EDIT-USER-ITEM":
      return { ...action.payload.data };
    case "UPDATE-ITEM":
      return { ...action.payload.data };
    case "DELETE-ITEM":
      return { ...action.payload.data };
    default:
      return state;
  }
};

