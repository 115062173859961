import React, { PureComponent } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { UpdateProductApi, GetProductApi, DeleteProductApi } from "..";
import AppLayout from "../../../applayout/applayout";
import ImageUploader from "./ImageUploader";
import web3Utills from "../../../utils/web3Utills";
interface Props {
  t?: any;
  UpdateProductApi?: any;
  GetProductApi?: any;
  DeleteProductApi?: any;
  ItemResult?: any;
  UpdateResult?: any;
  DeleteResult?: any;
}
interface State {
  _id: any;
  product_images: any;
  price: number;
  category: string;
  description: string;
  title: string;
  condition: string;
  status: string;
  categoryList?: any;
  conditionList?: any;
  ItemEditReduce?: any;
  isLoading: boolean;
  accountAddress: string;
  tags: string;
  product_id: string;
  navigateTo: string;
  loading: boolean;
  error: {
    conditionError?: string;
    fileError?: string;
    titleError?: string;
    categoryError?: string;
    descriptionError?: string;
    priceError?: string;
  };
}
export class ItemEditScreen extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      product_images: [],
      price: 0,
      _id: "",
      category: "",
      description: "",
      title: "",
      condition: "",
      accountAddress: "",
      ItemEditReduce: {},
      isLoading: false,
      tags: "",
      status: "",
      navigateTo: "",
      //@ts-ignore
      product_id: String(props.match.params.product_id),
      loading: false,
      error: {
        conditionError: "",
        fileError: "",
        titleError: "",
        categoryError: "",
        descriptionError: "",
        priceError: "",
      },
    };
  }
  async componentDidMount() {
    try {
      let connected = await web3Utills.isEtheriumConnected();
      if (!connected) {
        window.location.href = "/connect-wallet";
        return;
      }
      let userId = localStorage.getItem("userId");
      let payload = {
        product_id: this.state.product_id,
        user_id: userId,
      };
      await this.props.GetProductApi(payload, () => {
        this.setState({
          _id: this.props.ItemResult._id,
          title: this.props.ItemResult.title,
          description: this.props.ItemResult.description,
          condition: this.props.ItemResult.condition,
          category: this.props.ItemResult.category,
          price: this.props.ItemResult.price,
          product_images: this.props.ItemResult.previewImagePath,
          loading: true,
        });
      });
      this.setState({
        categoryList: [
          "Clothing & Accessories",
          "Electronics",
          "Home & Garden",
          "Collectible & Art",
        ],
        conditionList: [
          "New with tags",
          "New without tags",
          "Very good",
          "Good",
          "Satisfactory",
        ],
      });
    } catch (ex) {
      console.log(ex);
    }
  }
  async multiiImageUpload(images: any) {
    this.setState({ product_images: images });
    console.log(images);
  }
  handleChange(e: any) {
    try {
      let selectedCategory = e.currentTarget.value;
      this.setState({ category: selectedCategory });
    } catch (ex) {
      console.log(ex);
    }
  }
  handleConditionChange(e: any) {
    try {
      let selected = e.currentTarget.value;
      this.setState({ condition: selected });
    } catch (ex) {
      console.log(ex);
    }
  }
  handleFiledUpdate = (key: string, e: any) => {
    try {
      //@ts-ignore
      this.setState({ [key]: e });
    } catch (ex) {
      console.log(ex);
    }
  };
  DeleteItem = async (event: any) => {
    event.currentTarget.disabled = true;
    event.currentTarget.innerHTML = "Deleting...";
    let userId = localStorage.getItem("userId");
    let token = await localStorage.getItem("accessToken");
    let payload = {
      product_id: this.state._id,
      creatorId: userId,
    };
    await this.props.DeleteProductApi(payload, token, async () => {
      console.error("DeleteProductApi");
      console.error(this.props.DeleteResult);
      window.location.href = "/author/";
    });
  };
  handleSubmit = async (event: any) => {
    try {
      event.currentTarget.disabled = true;
      event.currentTarget.innerHTML = "Updating...";
      event.preventDefault();
      console.log("going in");
      //console.log(this.state.description);
      console.log("setting states");
      console.log(this.state.price);
      await this.setState(
        {
          error: {
            fileError:
              !this.state.product_images ||
              this.state.product_images.length == 0
                ? "Please select the file."
                : "",
            titleError: !this.state.title ? "Please enter the title." : "",
            categoryError: !this.state.category
              ? "Please select the category."
              : "",
            conditionError: !this.state.category
              ? "Please select the product condition."
              : "",
            priceError:
              Number(this.state.price) <= 0 ? "Please enter the price." : "",
            descriptionError: !this.state.description
              ? "Please add the description."
              : "",
          },
        },
        async () => {
          if (
            this.state.error?.fileError == "" &&
            //this.state.error?.previewFileError == "" &&
            this.state.error?.categoryError == "" &&
            this.state.error?.conditionError == "" &&
            this.state.error?.titleError == "" &&
            this.state.error?.priceError == "" &&
            this.state.error?.descriptionError == ""
          ) {
            console.log("inside if");
            //Call Create NFT Function.
            let userId = await localStorage.getItem("userId");
            let token = await localStorage.getItem("accessToken");
            console.log("making form data");
            let price = parseFloat(this.state.price.toString()).toFixed(2);
            price = price.toString();
            let formData = new FormData();

            for (let i = 0; i < this.state.product_images.length; i++) {
              formData.append("updated_images", this.state.product_images[i]);
            }
            formData.append("category", this.state.category);
            formData.append("_id", this.state._id);
            formData.append("product_id", this.state.product_id);
            formData.append("description", this.state.description);
            formData.append("condition", this.state.condition);
            formData.append("title", this.state.title);
            formData.append("creatorId", String(userId));

            formData.append("price", String(this.state.price));

            await this.props.UpdateProductApi(formData, token, async () => {
              console.log(this.props.UpdateResult);
              window.location.href = "/author/";
            });
          } else if (this.state.error?.titleError != "") {
            this.setState({ navigateTo: "#titleError" });
          } else if (this.state.error?.descriptionError != "") {
            this.setState({ navigateTo: "#descriptionError" });
          }
        }
      );
    } catch (ex) {
      console.log(ex);
    }
  };
  handlePriceChange = (key: string, e: React.ChangeEvent<HTMLInputElement>) => {
    try {
      const value = e.target.value;
      if (value !== "") {
        const numValue = Number(value);
     
          this.setState({ price: numValue });
          console.log("price " + numValue);
        
      }
      else {
        this.setState({ price: 0 });
      }
    } catch (ex) {
      console.log(ex);
    }
  };

  render() {
    let categoryOptions: any = null;
    let conditionOptions: any = null;
    if (
      this.state.categoryList &&
      Array.isArray(this.state.categoryList) &&
      this.state.categoryList.length > 0
    ) {
      categoryOptions = this.state.categoryList.map((category: any) => {
        return (
          <>
            <option
              className="textBlack"
              style={{ color: "black" }}
              value={category}
              key={category}
              selected={this.state.category == category}
            >
              {category}
            </option>
          </>
        );
      });
    }
    if (
      this.state.conditionList &&
      Array.isArray(this.state.conditionList) &&
      this.state.conditionList.length > 0
    ) {
      conditionOptions = this.state.conditionList.map((condition: any) => {
        return (
          <option
            className="textBlack"
            style={{ color: "black" }}
            value={condition}
            key={condition}
            selected={this.state.condition == condition}
          >
            {condition}
          </option>
        );
      });
    }
    return (
      <>
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-8">
              <h1>Edit the offer</h1>
              <div className="col-12 border d-none d-sm-block"></div>
            </div>
            <div className="mt-1"></div>
            <div className="col-12 col-lg-8">
              <div className="alert alert-primary text-center" role="alert">
                Your transaction is secured by the <strong>Web3</strong>{" "}
                technology.{" "}
                <a href="/help" className="alert-link">
                  Learn more
                </a>
              </div>
              <div className="mt-1"></div>
              {this.state.loading && (
                <ImageUploader
                  multiiImageUpload={this.multiiImageUpload.bind(this)}
                  images_existant={this.props.ItemResult.previewImagePath}
                />
              )}
              <span className="text-danger" id="fileError">
                {this.state.error.fileError}
              </span>
            </div>
            <div className="mt-3"></div>
            <div className="col-12 col-lg-8">
              <div className="mb-3">
                <label
                  htmlFor="exampleFormControlInput1"
                  className="form-label"
                >
                  Title*{" "}
                  <span className="text-danger" id="titleError">
                    {this.state.error.titleError}
                  </span>
                </label>
                <input
                  value={this.state.title}
                  onChange={(e: any) => {
                    this.handleFiledUpdate("title", e.currentTarget.value);
                  }}
                  maxLength={50}
                  type="text"
                  name="item_title"
                  id="item_title"
                  className="form-control"
                  placeholder="e.g. White COS Jumper"
                />
              </div>
              <div className="mb-3">
                <label
                  htmlFor="exampleFormControlTextarea1"
                  className="form-label"
                >
                  Description*{" "}
                  <span className="text-danger" id="descriptionError">
                    {this.state.error.descriptionError}
                  </span>
                </label>
                <textarea
                  className="form-control"
                  value={this.state.description}
                  onChange={(e: any) => {
                    this.handleFiledUpdate(
                      "description",
                      e.currentTarget.value
                    );
                  }}
                  data-autoresize
                  name="item_desc"
                  id="item_desc"
                  placeholder="e.g. only worn a few times, true to size"
                ></textarea>
              </div>
              <div className="mb-3">
                <label
                  htmlFor="exampleFormControlInput1"
                  className="form-label"
                >
                  Category{" "}
                  <span className="text-danger" id="categoryError">
                    {this.state.error.categoryError}
                  </span>
                </label>
                <div className="dropdown">
                  <select
                    className="form-control col-md-12 dropdown textBlack"
                    onChange={(e: any) => {
                      this.handleFiledUpdate("category", e.currentTarget.value);
                    }}
                  >
                    <option style={{ color: "black" }} value="">
                      Choose your category
                    </option>
                    {categoryOptions}
                  </select>
                </div>
              </div>
              <div className="mb-3">
                <label
                  htmlFor="exampleFormControlInput1"
                  className="form-label"
                >
                  Condition{" "}
                  <span className="text-danger" id="categoryError">
                    {this.state.error.conditionError}
                  </span>
                </label>
                <div className="dropdown">
                  <select
                    className="form-control col-md-12 dropdown textBlack"
                    onChange={(e: any) => {
                      this.handleFiledUpdate(
                        "condition",
                        e.currentTarget.value
                      );
                    }}
                  >
                    <option style={{ color: "black" }} value="">
                      {" "}
                      Condition of your item
                    </option>
                    {conditionOptions}
                  </select>
                </div>
              </div>
              <div className="mb-3">
                <label
                  htmlFor="exampleFormControlTextarea1"
                  className="form-label"
                >
                  Price in MATIC{" "}
                  <span className="text-danger" id="categoryError">
                    {this.state.error.priceError}
                  </span>
                </label>
                <input
                  
                  min={0}
                  onChange={(e: any) => {
                    this.handlePriceChange("price", e);
                  }}
                  type="number"
                  name="item_price"
                  id="item_price"
                  value={this.state.price}
                  className="form-control"
                  placeholder="100"
                />
              </div>
              <div className="mt-1"></div>
              <div className="d-flex justify-content-center">
                <div className="col-12 col-lg-4">
                  <button
                    type="button"
                    onClick={(evt) => {
                      this.handleSubmit(evt);
                    }}
                    className="btn btn-dark w-100"
                    style={{ height: "60px" }}
                  >
                    Update the informations
                  </button>
                  <div style={{ height: "10px" }}></div>
                  <button
                    type="button"
                    onClick={(evt) => {
                      this.DeleteItem(evt);
                    }}
                    className="btn btn-danger w-100 col-lg-4"
                  >
                    Delete the Item
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
function mapStateToProps(state: any) {
  return {
    ItemResult: state.ItemEditReducer || {},
    UpdateResult: state.ItemEditReducer || {},
    DeleteResult: state.ItemEditReducer || {},
  };
}
function mapDispatchToProps(dispatch: Function) {
  return {
    UpdateProductApi: (payload: FormData, token: string, callback: Function) =>
      dispatch(UpdateProductApi(payload, token, callback)),
    GetProductApi: (payload: FormData, callback: Function) =>
      dispatch(GetProductApi(payload, callback)),
    DeleteProductApi: (payload: any, token: string, callback: Function) =>
      dispatch(DeleteProductApi(payload, token, callback)),
  };
}
export const ItemEditModule = withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(ItemEditScreen)
);
export const ItemEdit = AppLayout(ItemEditModule);
