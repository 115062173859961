import React, { PureComponent } from "react";
import AppLayout from "../../../applayout/applayout";

interface Props {}

interface State {}

export class PrivacyPageScreen extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
  }

  render() {
    return (
      <>
        <>
          <div className="container-fluid">
            <div className="row border bg-light" style={{ height: "200px" }}>
              <div className="d-flex flex-column justify-content-center align-items-center text-center">
                <div className="p-2">
                  <h1>Privacy Policy for Web3 Marketplace Platform</h1>
                  <p>Last updated April 25, 2023</p>
                </div>
              </div>
            </div>
          </div>
          <div style={{ height: "10px" }}></div>
          <div style={{ height: "10px" }}></div>
        </>
        <section>
          <div className="container-lg">
          <p>Sabuy is a web3 marketplace platform that connects buyers and sellers of various goods, including clothing and accessories, electronics, home and garden items, and collectibles and art.</p>
<p>Sabuy is committed to protecting the privacy of our users. This privacy policy outlines how we collect, use, and protect your personal information.</p>
<p>&nbsp;</p>
<p><strong>Information We Collect</strong></p>
<p>We do not collect any personal information from our users. We do not require users to sign up or sign in, and we do not track user activity.</p>
<p>&nbsp;</p>
<p><strong>How We Use Your Information</strong></p>
<p>Since we do not collect any personal information from our users, we do not use your information for any purpose.</p>
<p>&nbsp;</p>
<p><strong>Cookies and Tracking Technologies</strong></p>
<p>We do not use cookies or any other tracking technologies on our platform.</p>
<p>&nbsp;</p>
<p><strong>Third-Party Services</strong></p>
<p>We use Transak services on our platform to enable users to buy MATIC cryptocurrency to be spent on our platform if they choose to do so. However, users are free to choose any exchange platform to buy MATIC. Please note that Transak may collect personal information in accordance with their own privacy policy, which you can review here: https://transak.com/privacy-policy. Please review their privacy policy carefully before using their services on our platform.</p>
<p><strong>&nbsp;</strong></p>
<p><strong>Data Security</strong></p>
<p>We take data security very seriously and have implemented technical and organizational measures to protect the data we do not collect from our users.</p>
<p>&nbsp;</p>
<p><strong>Changes to This Privacy Policy</strong></p>
<p>We reserve the right to update or modify this privacy policy at any time without prior notice. Your continued use of our platform after any changes or modifications to this policy constitutes your acceptance of the revised policy.</p>
<p><strong>&nbsp;</strong></p>
<p><strong>Contact Us</strong></p>
<p>If you have any question <a href="/contact">contact us here.</a> </p>
          </div>
        </section>
      </>
    );
  }
}

export const PRIVACY_PAGE = AppLayout(PrivacyPageScreen);
