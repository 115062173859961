import React, { useState } from 'react';

import 'react-image-lightbox/style.css';

import Lightbox from 'react-image-lightbox';

function ImageUploader ({multiiImageUpload}) {
  const [files, setFiles] = useState([]);
  const [previewUrls, setPreviewUrls] = useState([]);
  const [error, setError] = useState(null);
  const [zoomedImageIndex, setZoomedImageIndex] = useState(-1);
  const [isOpen, setOpen] = useState(false);


  const handleFileChange = (event) => {
    const selectedFiles = event.target.files;
    
    
    const imageFiles = files;

    if (selectedFiles.length + imageFiles.length> 5) {
      setError("You can only select up to 5 images.");
      return;
    } else {
      setError(null);
    }

    for (let i = 0; i < selectedFiles.length; i++) {
     
    
        if (selectedFiles[i].type.startsWith('image/') && Number(selectedFiles[i].size) <= 10485760) {
          imageFiles.push(selectedFiles[i]);
        }
        else setError('Some selected files are not images or are too large.');
      }

    setFiles(imageFiles);
    multiiImageUpload(imageFiles)
    
  // create preview URLs for the selected image files
  const previewUrls = [];
  for (let i = 0; i < imageFiles.length; i++) {
    previewUrls.push(URL.createObjectURL(imageFiles[i]));
  }
  setPreviewUrls(previewUrls);
  }


  const handleDelete = (index) => {
    // update the files and previewUrls state variables
    const newFiles = [...files];
    newFiles.splice(index, 1);
    setFiles(newFiles);
    
    if (newFiles.length == 0) multiiImageUpload(null)
    else multiiImageUpload(newFiles)

    

    const newPreviewUrls = [...previewUrls];
    newPreviewUrls.splice(index, 1);
    setPreviewUrls(newPreviewUrls);
    
  }


  const handleImageClick = (index) => {
    setZoomedImageIndex(index);
    setOpen(true);

  }


  return (
    <div>
      
      <div className="input-group mb-3">
                    <input type="file" multiple className="form-control" id="inputGroupFile01"  
                     onChange={handleFileChange}  />
                                                   
                </div>
                <span className="text-danger" id="fileError">{(error) && error}</span>                          
               
                
      <div className="d-flex flex-wrap imageupload">
      {previewUrls.map((url, index) => (
        <>
              <div className="p-2 bg-light image-preview" key={'image'+index}>
                <img key={index}  alt="Preview" 
                src={url}
                
                onClick={() => handleImageClick(index)}
                />
                <i className="fa fa-times-circle delete-icon" onClick={() => handleDelete(index)}></i>
                </div>
              
          </>
      ))}
      </div>

      {isOpen && (
      <Lightbox
        mainSrc={previewUrls[zoomedImageIndex]}
        onCloseRequest={() => setOpen(false)}
        
      />
    )}

    </div>
  );
}

export default ImageUploader;