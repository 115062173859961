import React from "react";
import Utils from "../utils";
import { Route, Redirect } from "react-router-dom";

//@ts-ignore
export function AuthRoute({ component: Component, ...rest }) {
  function validateRoute() {
    if (!Utils.constant.getUserIdToken()) {
      return true;
    }
  }
  function otpNotVerified() {
    if (!Utils.constant.getOtpVerification()) {
      return true;
    }
  }
  function profileNotAdded() {
    if (!Utils.constant.getProfileVerification()) {
      return true;
    }
  }
  return (
    <Route
      {...rest}
      render={(props) =>
        validateRoute() && otpNotVerified() && profileNotAdded() ? (
          <Component {...props} />
        ) : (
          <Redirect to="/home" />
        )
      }
    />
  );
}

//@ts-ignore
export const PrivateRoute = ({ component: Component, ...rest }) => {
  function validateRoute() {
   
    if (Utils.constant.getUserIdToken()) {
      return true;
    }
  }


  return (
    <Route
      {...rest}
      render={(props) =>
        validateRoute() ? (<Component {...props} />) :   ( <Redirect to="/connect-wallet" />
        )
      }
    />
  );
};

//@ts-ignore
export function AuthRouteRecruiter({ component: Component, ...rest }) {
  function validateRoute() {
    if (Utils.constant.getUserIdToken()) {
      return true;
    }
  }
  return (
    <Route
      {...rest}
      render={(props) =>
        validateRoute() ? <Component {...props} /> : <Redirect to="/signup" />
      }
    />
  );
}
