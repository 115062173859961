import { SelectCategoryModel, SelectCollectionModel, NFTModal } from "../../modal";

import Utils from "../../utils";

export function CreateNFTReducer(
  state: NFTModal = new NFTModal(),
  action: any
) {

  switch (action.type) {
    case Utils.actionName.CREATE_NFT:
      return { ...action.payload.data };
    default:
      return state;
  }
};




export function SelectCategoryReducer(
  state: SelectCategoryModel = new SelectCategoryModel(),
  action: any
) {
  switch (action.type) {
    case Utils.actionName.SELECT_CATEGORY:
      return { ...state, ...action.payload, loading: "compleated" };
    default:
      return state;
  }
};



export function SelectCollectionReducer(
  state: SelectCollectionModel = new SelectCollectionModel(),
  action: any
) {
  switch (action.type) {
    case Utils.actionName.SELECT_COLLECTION:
      return { ...state, ...action.payload, loading: "Collection completed" };
    default:
      return state;
  }
};

