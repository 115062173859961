import Utils from "./";
import {  toast } from 'react-toastify';
import { NFTModal } from "../modal";

const notify_error = (msg: any) => toast.error(msg, {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            });
const notify_success = (msg: any) => toast.success(msg, {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            });
const notify_info = (msg: any) => toast.info(msg, {
              position: "bottom-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              });
  






export const report_handler = () => { 
  notify_info('Will report backend'); 
}
export const message_notification = (msg:any, type?:any) => { 
  
  if(type =="error") notify_error(msg); 
  if(type =="success") notify_success(msg); 
  else notify_info(msg); 
}

export function notification_handler(nft: NFTModal) {
  
  try {
    let user_id = localStorage.getItem("userId");
    let tokenA = String(localStorage.getItem("accessToken"));

    let payload = { 'user_id': user_id, 'NFT_ID': nft._id };

    if (user_id ==null || tokenA==null) { window.location.href = '/connect-wallet'; }
    else {


      let hasConnection = Utils.constant.hasConnection();

      if (!hasConnection) {
        return false;
      }
      Utils.constant.handleOverlayLoader(true);
      let endPoint = Utils.endpoints.UpdateLikesCounts;

      Utils.api.postApiCallWithoutWebInUrl(
        endPoint,
        payload,
        tokenA,
        function (response: any) {
          if (response.status) {

            let alreadyliked: Boolean = response.data.data;

            if (alreadyliked) {

             /* //console.log('alreadyliked')
              //@ts-ignore 
              const nfts_liked = JSON.parse(window.localStorage.getItem('liked_nfts'));
              //@ts-ignore 
              var filtered_nfts_liked = nfts_liked.filter(function (value, index, arr) {
                return value != nft._id;
              });


              //localStorage.setItem("liked_nfts", JSON.stringify(filtered_nfts_liked));
*/
              // console.log('Removed from your Favorite')
              notify_info("Removed from your Favorite");
              return response.data.data;;
            }
            else {
              console.log(response.data.data);

             /* if (window.localStorage.getItem('liked_nfts') != 'undefined') {
                //@ts-ignore 
                const nfts_liked = JSON.parse(window.localStorage.getItem('liked_nfts'));

                nfts_liked.push(nft._id);
                // console.log('NOT alreadyliked-->undefined');
                localStorage.setItem("liked_nfts", JSON.stringify(nfts_liked));

              }

              else {
                //console.log('NOT alreadyliked====undefined')
                localStorage.setItem("liked_nfts", JSON.stringify(nft._id));

              }
*/

              notify_success("Added to your Favorite");
            
            }
            return response.data.data;
          }
        },
        function (error: any) {
          if (error.status === 501) {
            Utils.constant.apiErrorMessage(error.data.message);
          }
        }
      );

    }

  } catch (error) {
    console.log(error);
  }

}




export function getDiscount(payload: any, callback: Function = function () {}) {
    return (dispatch: Function, getState: Function) => {
      let hasConnection = Utils.constant.hasConnection();
      console.log('in1')
      if (!hasConnection) {
        return false;
      }
      Utils.constant.handleOverlayLoader(true);
      let endPoint = Utils.endpoints.discounts;
      payload.device_id = "device123";
      Utils.api.postApiCall(
        endPoint,
        payload,
        function (response: any) {
          if (
            response.status &&
            response.data &&
            response.data.code &&
            response.data.code === 200
          ) {
            let data: any = response.data.data;
            console.log('in2')
            callback(data);
          } else if (response.status && response.data) {
            let data: any = response.data;
            let error = data.code && data.code != 200 ? data.message : "";
            error = error ? error : "Something went wrong";
            Utils.constant.apiErrorMessage(error);
            console.log('in3')
            callback({ loginerror: true });
          }
        },
        function (error: any) {
          console.log('in4')
          Utils.constant.apiErrorMessage(error.data.message);
          callback({ loginerror: true });
        }
      );
    };
  }