import Utils from "../../utils";
export function TransactionReducer(
  state: any = {},
  action: any
) {
 //console.log(action);
  switch (action.type) {
    case Utils.actionName.GET_Transaction_BY_USER:
        return action.payload;
    case Utils.actionName.UPDATE_ORDER:
        return action.payload;
    default:
      return state;
  }
}