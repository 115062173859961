import React, { Component } from "react";

class Clock extends Component {
  constructor(props) {
    super(props);
    this.state = {
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0,
      ended: false
    };
  }
  componentDidMount() {
    this.getTimeUntil(this.props.deadline);
    const time = Date.parse(new Date(this.props.deadline * 1000)) - Date.parse(new Date());
  
    const days = Math.floor(time / (1000 * 60 * 60 * 24));
    const hours = Math.floor((time / (1000 * 60 * 60)) % 24);

  

    if(days <= 0 )  setInterval(() => this.getTimeUntil(this.props.deadline), 1000);      
    
  }
  leading0(num) {
    return num < 10 ? "" + num : num;
  }
  getTimeUntil(deadline) {
    const time = Date.parse(new Date(deadline * 1000)) - Date.parse(new Date());
  
    if (time < 0) {
      this.setState({ days: 0, hours: 0, minutes: 0,  seconds: 0, ended:true });
      
    } else {
      const seconds = Math.floor((time / 1000) % 60);
      const minutes = Math.floor((time / 1000 / 60) % 60);
      const hours = Math.floor((time / (1000 * 60 * 60)) % 24);
      const days = Math.floor(time / (1000 * 60 * 60 * 24));
      this.setState({ days, hours, minutes, seconds });
    }
  }
  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = (state,callback)=>{
        return;
    };
  }
  render() {
    
    return (
      

      <div className={(this.props.page_details==false) ? "de_countdown de_countdown_item bg-color-secondary" : 'de_countdown bg-color-secondary' }>
      <div>
      {(this.state.ended)? <div className="Clock-days">Ended</div>
     : 
      
        (this.state.days > 0) ?  <div className="Clock-days">{this.props.msg + ' ' +this.leading0(this.state.days)} days</div> 
        
        :   
        <>{this.props.msg + ' ' }<div className="Clock-hours">
              {this.leading0(this.state.hours)}h
            </div><div className="Clock-minutes">
                {this.leading0(this.state.minutes)}m
              </div>
              <div className="Clock-seconds">
          {this.leading0(this.state.seconds)}s
        </div></>
        }
      
      </div>
      </div>
    );
  }
}
export default Clock;
