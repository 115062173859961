import { createMedia } from "@artsy/fresnel";
import React, { useEffect } from "react";
import { BrowserRouter, Route, Switch, useLocation } from "react-router-dom";
import { Seller } from "../modules/seller";
import { Signin } from "../modules/auth/screen";
import { Author } from "../modules/author/screen";
import { ContactUs } from "../modules/contactus/screen";
import { CreateSingle } from "../modules/create-single";
import { Explore3 } from "../modules/explore3";
import { Home } from "../modules/home/screen";
import { InboxDetail } from "../modules/inbox/screen/inbox.screen";
import { ItemDetail } from "../modules/item-detail";
import { ItemEdit } from "../modules/item-edit";
import { Liked_items } from "../modules/liked_items/screen/liked_items.screen";
import { NOTFOUND } from "../modules/notfound";
import { Profile } from "../modules/profile/screen/profile.screen";
import { RequestItem } from "../modules/request-item";
import { AuthorTransaction } from "../modules/transaction/screen";
import { Wallet } from "../modules/wallet/screen/wallet.screen";
import { HELP_PAGE } from "../modules/help-page/screen/help-page.screen";
import { TERMS_PAGE } from "../modules/help-page/screen/terms.screen";
import { PRIVACY_PAGE } from "../modules/help-page/screen/privacy.screen";
import { PrivateRoute } from "./route";

function ScrollToTop() {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return null;
}
const { MediaContextProvider, Media } = createMedia({
  // breakpoints values can be either strings or integers
  breakpoints: {
    sm: 0,
    md: 768,
    lg: 1024,
    xl: 1192,
  },
});
function Router() {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <Switch>
        <Route exact path="/contact" component={ContactUs} />
        <Route exact path="/home" component={Home} />
        <Route exact path="/" component={Home} />
        <Route exact path="/help" component={HELP_PAGE} />
        <Route exact path="/terms" component={TERMS_PAGE} />
        <Route exact path="/privacy" component={PRIVACY_PAGE} />

        <Route exact path="/inbox" component={InboxDetail} />
        <Route exact path="/user/:id?" component={Seller} />

        <Route exact path="/explore/:category?" component={Explore3} />
        {/* <Route exact path="/explore3/:category?" component={Explore3} /> */}
        <PrivateRoute exact path="/author/:tab?" component={Author} />
        <PrivateRoute
          exact
          path="/my-transactions"
          component={AuthorTransaction}
        />

        <PrivateRoute exact path="/sell-now" component={CreateSingle} />
        <PrivateRoute
          exact
          path="/edit-item/:product_id"
          component={ItemEdit}
        />
        <PrivateRoute exact path="/request-item" component={RequestItem} />
        <PrivateRoute exact path="/profile" component={Profile} />
        <PrivateRoute exact path="/liked-items" component={Liked_items} />
        {/* <Route exact path="/explore-connected" component={ExploreConnected} /> */}
        <Route exact path="/connect-wallet/:returnUrl?" component={Wallet} />
        <Route exact path="/login" component={Signin} />

        <Route exact path="/item-details/:id" component={ItemDetail} />

        <Route component={NOTFOUND} />
      </Switch>
    </BrowserRouter>
  );
}
export default Router;
