import Utils from "../utils";
import web3Utills from "./web3Utills";

/**
 * set access token in axios instance header
 */
export const setAuthorizationToken = (token: string) => {
  if (token) {
    Utils.constant.axios.defaults.headers.common["authToken"] = `${token}`;
  } else {
    delete Utils.constant.axios.defaults.headers.common["authToken"];
  }
};
/**
 * set user session in browser
 */
export const setSession = (payload: any) => {
  let expiresIn = JSON.stringify(
    payload.expiresIn * 5000 + new Date().getTime()
  );
  let recruiterPic = payload.recruiterPic ? payload.recruiterPic : "";

  console.log(JSON.stringify(payload.connectedAccount));

  localStorage.setItem("accessToken", payload.accessToken);
  localStorage.setItem("userId", payload.user_id);
  localStorage.setItem("username", payload.username);
  localStorage.setItem("liked_nfts", JSON.stringify(payload.liked_nfts));
  localStorage.setItem("expiresIn", expiresIn);
  localStorage.setItem("isOtpVerified", payload.isOtpVerified);
  localStorage.setItem("is_profile_added", payload.is_profile_added);
  localStorage.setItem("connectedAccount", payload.connectedAccount);
  localStorage.setItem("recruiterPic", recruiterPic);
  localStorage.setItem("tourenable", JSON.stringify(payload.tourEnable));
  setAuthorizationToken(payload.accessToken);
};
/**
 * remove session on user logout
 */
export const removeSession = () => {
  localStorage.removeItem("accessToken");
  localStorage.removeItem("userId");
  localStorage.removeItem("expiresIn");
  localStorage.removeItem("connectedAccount");
  setAuthorizationToken("");
};

/**
 * get user access token form loclastorage
 */
export const getAccessToken = () => {
  let accessToken = localStorage.getItem("accessToken");
  if (
    accessToken &&
    !Utils.constant.axios.defaults.headers.common["authToken"]
  ) {
    setAuthorizationToken(accessToken);
  }

  return accessToken == null ? false : true;
};
/**
 * get user id form localStorage
 */
export const getUserIdToken = () => {
  let userId = localStorage.getItem("userId") || "";
  return userId;
};

/*
 * Check if the user wallet is connected & signed in.
 */

export const getuserAuthentification = async () => {
  let walletconnected = await web3Utills.isEtheriumConnected();
  let isAuthenticated = getAccessToken();

  return walletconnected && isAuthenticated;
};

/**
 * get user id form localStorage
 */
export const getOtpVerification = () => {
  let otpVerified: any =
    localStorage.getItem("isOtpVerified") &&
    localStorage.getItem("isOtpVerified") != "null"
      ? localStorage.getItem("isOtpVerified")
      : "";
  otpVerified = otpVerified ? Number(otpVerified) : 0;
  return otpVerified;
};

/**
 * get user id form localStorage
 */
export const getProfileVerification = () => {
  let profileAdded: any =
    localStorage.getItem("is_profile_added") &&
    localStorage.getItem("is_profile_added") != "null"
      ? localStorage.getItem("is_profile_added")
      : "";
  profileAdded = profileAdded ? Number(profileAdded) : 0;
  return profileAdded;
};
