import Utils from "../../utils";
import { SelectCategoryModel, SelectCollectionModel, NFTModal } from "../../modal";

/***************************************Select Collection*********************************************/

export function CreateNFT(payload: FormData, token: string, callback: Function) {

  return (dispatch: Function, getState: Function) => {

    let hasConnection = Utils.constant.hasConnection();

    if (!hasConnection) {
      return false;
    }

    Utils.constant.handleOverlayLoader(true);

    let endPoint = Utils.endpoints.CreateItem;

    Utils.api.postMultipartFormData(
      endPoint,
      payload,
      token,
      function (response: any) {
        if (response.status) {

          let data: NFTModal = response.data.data;

          console.log(data);

          dispatch({
            type: Utils.actionName.CREATE_NFT,
            payload: { data },
          });

          callback();
        }
      },
      function (error: any) {
        if (error.status === 501) {
          Utils.constant.apiErrorMessage(error.data.message);
        }
      }
    );
  };
}
