import React from "react";
import Store from "./store";
import Router from "./router";
import { Provider } from "react-redux";
import { ToastContainer } from 'react-toastify';

function App() {


  //const { connect, disconnect, isActive, account } = useMetaMask()

  return (

    
    <Provider store={Store}>
    
      <Router />
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover />
    
  </Provider>
  );
}

export default App;
